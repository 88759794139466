import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import Tracking from '~/models/Tracking';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  GET_TRACKING_DATA = 'GET_TRACKING_DATA',
  SET_TRACKING_DATA = 'SET_TRACKING_DATA',
  SET_GET_TRACKING_DATA_LOADING = 'SET_GET_TRACKING_DATA_LOADING',
}

export interface OnGetTrackingData extends Action<TypesNames.GET_TRACKING_DATA> {
  sessionId: string;
}

export interface OnSetTrackingData extends Action<TypesNames.SET_TRACKING_DATA> {
  trackingData: Tracking;
}

export interface OnSetGetTrackingDataLoading
  extends Action<TypesNames.SET_GET_TRACKING_DATA_LOADING> {
  getTrackingDataLoading: boolean;
}

/* ============== ACTION CREATORS AND TYPES ============== */

export const { Types, Creators } = createActions<
  {
    [TypesNames.GET_TRACKING_DATA]: string;
    [TypesNames.SET_TRACKING_DATA]: string;
  },
  {
    getTrackingData: (sessionId: string) => OnGetTrackingData;
    setTrackingData: (trackingData: Tracking) => OnSetTrackingData;
    setGetTrackingDataLoading: (getTrackingDataLoading: boolean) => OnSetGetTrackingDataLoading;
  }
>({
  getTrackingData: ['sessionId'],
  setTrackingData: ['trackingData'],
  setGetTrackingDataLoading: ['getTrackingDataLoading'],
});

export const TrackingTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */

export interface TrackingType {
  trackingData?: Tracking;
  getTrackingDataLoading: boolean;
}

export const INITIAL_STATE: TrackingType = {
  trackingData: null,
  getTrackingDataLoading: false,
};

/* ============== REDUCERS ============== */

export const getTrackingDataReducer = (state = INITIAL_STATE, { sessionId }: OnGetTrackingData) =>
  produce(state, (draft) => {
    draft.getTrackingDataLoading = true;
    draft.trackingData = null;
  });

export const setTrackingDataReducer = (
  state = INITIAL_STATE,
  { trackingData }: OnSetTrackingData,
) =>
  produce(state, (draft) => {
    draft.trackingData = trackingData;
    draft.getTrackingDataLoading = false;
  });

export const setGetTrackingDataLoadingReducer = (
  state = INITIAL_STATE,
  { getTrackingDataLoading }: OnSetGetTrackingDataLoading,
) =>
  produce(state, (draft) => {
    draft.getTrackingDataLoading = getTrackingDataLoading;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.GET_TRACKING_DATA]: getTrackingDataReducer,
  [Types.SET_TRACKING_DATA]: setTrackingDataReducer,
  [Types.SET_TRACKING_DATA]: setTrackingDataReducer,
});
