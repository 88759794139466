import { addMinutes } from 'date-fns';
import { useEffect, useState } from 'react';

const getHoursCountDownValues = (countDown: any) => {
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [minutes, seconds];
};

const useHoursCountDown = (startDate: any) => {
  const countDownDate = startDate.getTime();
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const newCountDown = countDownDate - new Date().getTime();
      setCountDown(newCountDown);
      if (newCountDown <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getHoursCountDownValues(countDown);
};
export default useHoursCountDown;
