import { Stack } from '@chakra-ui/core';
import { Image } from '@chakra-ui/core';
import { Box } from '@chakra-ui/core';
import { Text } from '@chakra-ui/core';
import { Divider } from '@chakra-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import BagDeliveryFeeDescription from '~/components/BagDeliveryFeeDescription';
import BagPriceDescription from '~/components/BagPriceDescription';
import ProBadge from '~/components/ProBadge';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import { formatCurrency } from '~/utils/currency';

export interface TrackingOrderTotalsProps {
  totalAmount: number;
  deliveryFee?: number;
  serviceFee?: number;
}

const TrackingOrderTotals: React.FC<TrackingOrderTotalsProps> = ({
  totalAmount,
  deliveryFee = 0,
  serviceFee = 0,
}) => {
  const globalState = useSelector((state: RootState) => state);
  const trackingData = globalState?.tracking?.trackingData;
  const translate = useTranslate(trackingData?.client?.language);
  const orderTotal = totalAmount + serviceFee;
  const deliveryFeeCalc = trackingData?.client?.pro ? 0 : deliveryFee;

  return (
    <Stack as="section" spacing={3} mb={8}>
      <Divider my={6} />
      <BagPriceDescription
        mt={5}
        descriptionTitle={translate.getText('sub_total')}
        descriptionPrice={formatCurrency(totalAmount / 100, trackingData?.client?.language)}
        color="gray.300"
        fontSize="1.7rem"
      />
      <BagDeliveryFeeDescription
        descriptionTitle={translate.getText('delivery_fee')}
        descriptionPrice={deliveryFee / 100}
        pro={trackingData?.client?.pro}
        language={trackingData?.client?.language}
      />
      {serviceFee > 0 && (
        <BagPriceDescription
          descriptionTitle={translate.getText('service_fee')}
          descriptionPrice={
            serviceFee > 0
              ? formatCurrency(serviceFee / 100, trackingData?.client?.language)
              : 'Grátis'
          }
          color="gray.300"
          fontSize="1.7rem"
        />
      )}
      <BagPriceDescription
        my={6}
        descriptionTitle={translate.getText('total')}
        descriptionPrice={formatCurrency(
          (orderTotal + deliveryFeeCalc) / 100,
          trackingData?.client?.language,
        )}
        fontWeight="700"
        fontSize="2rem"
      />
      {trackingData?.client?.pro && deliveryFee / 100 > 0 && (
        <ProBadge
          mt="10px"
          w="115%"
          ml="-30px"
          mb="0px"
          discountTitle={translate.getText('pro_discount')}
          discountPrice={deliveryFee / 100}
          language={trackingData?.client?.language}
        />
      )}
    </Stack>
  );
};

export default TrackingOrderTotals;
