import { Box, BoxProps, Spinner, Text } from '@chakra-ui/core';
import isNumber from 'lodash/isNumber';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { formatCurrency } from '~/utils/currency';

export interface BagPriceDescriptionProps extends BoxProps {
  descriptionTitle: string;
  descriptionPrice?: number | string;
  loading?: boolean;
}

const BagPriceDescription: React.FC<BagPriceDescriptionProps> = ({
  descriptionPrice,
  descriptionTitle,
  color,
  fontWeight = '400',
  fontSize = 'sm',
  loading = false,
  ...rest
}) => {
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;

  return (
    <Box d="flex" alignItems="center" justifyContent="space-between" {...rest}>
      <Text color={color} as="span" fontSize={fontSize} fontWeight={fontWeight}>
        {descriptionTitle}
      </Text>
      {loading ? (
        <Spinner size="md" color="gray.300" />
      ) : (
        <Text color={color} as="span" fontSize={fontSize} fontWeight={fontWeight}>
          {isNumber(descriptionPrice)
            ? formatCurrency(descriptionPrice, rappiTurbo?.client?.language)
            : descriptionPrice}
        </Text>
      )}
    </Box>
  );
};

export default BagPriceDescription;
