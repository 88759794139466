import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from '~/components/Button';
import Input from '~/components/Form/Input';
import GoBackButton from '~/components/GoBackButton';
import useTranslate from '~/hooks/useTranslate';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import registerUserSchema from '~/models/schemas/registerUserSchema';
import { RootState } from '~/store';
import { cpfMask } from '~/utils/masks';

interface ConfirmUserDataFormProps {
  isVisible: boolean;
  onClickBack?: () => void;
  onFormSubmit: (data: any) => void;
}

const ConfirmUserDataForm: React.FC<ConfirmUserDataFormProps> = ({
  isVisible,
  onFormSubmit,
  onClickBack,
}) => {
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const initializateCheckoutLoading = rappiTurbo?.initializateCheckoutLoading;
  const client = rappiTurbo?.client;
  const translate = useTranslate(client?.language);
  const sholdValidateCPF = client?.language === 'pt-BR';

  const { register, handleSubmit, setValue, errors } = useForm<any>({
    resolver: useYupValidationResolver(registerUserSchema(sholdValidateCPF, translate)),
    defaultValues: {
      first_name: client?.first_name,
      surname: client?.surname,
      email: client?.email,
      identification: sholdValidateCPF ? cpfMask(client?.identification) : client?.identification,
    },
  });

  const handleOnClickConfirm: any = useCallback(() => {
    if (isVisible) {
      handleSubmit(onFormSubmit)();
    }
  }, [handleSubmit, onFormSubmit, isVisible]);

  return (
    <Box d="flex" w="100%" py={4} flexDirection="column">
      <Box as="form" d={isVisible ? 'flex' : 'none'} flexDir="column" pb={8}>
        <Box d="flex" flexDir="row" pb="20px" pl={3} borderBottom={'1px solid #ECEFF3'} mb={5}>
          <GoBackButton onClick={onClickBack} />
          <Text fontSize="1.8rem" fontWeight="700" alignSelf="center" pt={1} pl="3.5rem">
            {translate.getText('conf_user_data_title')}
          </Text>
        </Box>
        <Text fontSize="1.5rem" fontWeight="500" color="gray.300" py={2} pb={5} alignSelf="center">
          {translate.getText('conf_user_data_subtitle')}
        </Text>
        <Box d="flex" flexDir={'row'} mt={5}>
          <FormControl
            mb={6}
            w="50%"
            mr={5}
            isInvalid={!!errors.first_name}
            isDisabled={initializateCheckoutLoading}
          >
            <FormLabel htmlFor="first_name" fontWeight="700" fontSize="1.5rem">
              {translate.getText('name')}
            </FormLabel>
            <InputGroup>
              <Input placeholder="José" name="first_name" type="text" register={register()} />
              {!!errors.first_name && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.first_name?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl
            mb={6}
            w="50%"
            isInvalid={!!errors.surname}
            isDisabled={initializateCheckoutLoading}
          >
            <FormLabel htmlFor="surname" fontWeight="700" fontSize="1.5rem">
              {translate.getText('surname')}
            </FormLabel>
            <InputGroup>
              <Input placeholder="Silva" name="surname" type="text" register={register()} />
              {!!errors.surname && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.surname?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>
        <FormControl
          mb={6}
          w="100%"
          isInvalid={!!errors.email}
          isDisabled={initializateCheckoutLoading}
        >
          <FormLabel htmlFor="email" fontWeight="700" fontSize="1.5rem">
            {translate.getText('email')}
          </FormLabel>
          <InputGroup>
            <Input
              placeholder="josesilva@email.com"
              name="email"
              type="text"
              register={register()}
            />
            {!!errors.email && (
              <InputRightElement h="50px" w="50px">
                <Tooltip
                  aria-label="Erro."
                  placement="top"
                  hasArrow
                  label={errors.email?.message}
                  bg="red.400"
                  zIndex={1500}
                >
                  <IconButton
                    aria-label="Erro."
                    icon="warning"
                    color="red.300"
                    _focus={undefined}
                  />
                </Tooltip>
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
        <FormControl
          mb={6}
          w="100%"
          isInvalid={!!errors.identification}
          isDisabled={initializateCheckoutLoading}
        >
          <FormLabel htmlFor="identification" fontWeight="700" fontSize="1.5rem">
            {translate.getText('identification_number')}
          </FormLabel>
          <InputGroup>
            <Input
              placeholder={
                rappiTurbo?.client?.language === 'pt-BR' ? '111.222.333-44' : '11122233344'
              }
              name="identification"
              type="text"
              register={register()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (rappiTurbo?.client?.language === 'pt-BR') {
                  setValue('identification', cpfMask(e.target.value), { shouldValidate: true });
                }
              }}
            />
            {!!errors.identification && (
              <InputRightElement h="50px" w="50px">
                <Tooltip
                  aria-label="Erro."
                  placement="top"
                  hasArrow
                  label={errors.identification?.message}
                  bg="red.400"
                  zIndex={1500}
                >
                  <IconButton
                    aria-label="Erro."
                    icon="warning"
                    color="red.300"
                    _focus={undefined}
                  />
                </Tooltip>
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
      </Box>
      <Button
        px="3rem"
        borderRadius="50px"
        bg="green.300"
        type="button"
        w="100%"
        py={8}
        onClick={handleOnClickConfirm}
        isDisabled={initializateCheckoutLoading}
        isLoading={initializateCheckoutLoading}
      >
        <Text color="white" fontSize="lg">
          {translate.getText('confirm_button')}
        </Text>
      </Button>
    </Box>
  );
};

export default ConfirmUserDataForm;
