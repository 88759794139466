import { Box, Image, Spinner, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import NumberCounter from '~/components/NumberCounter';
import TopRoundedBox from '~/components/TopRoundedBox';
import useTranslate from '~/hooks/useTranslate';
import SelectedTopping from '~/models/SelectedTopping';
import { bag, invalidSession, rappiStoreMenu } from '~/routes/routeMap';
import { RootState } from '~/store';
import Creators from '~/store/ducks/bag';
import RappiTurboActions from '~/store/ducks/rappiTurbo';
import { getProductFromCatalog } from '~/utils/bag';
import { formatCurrency } from '~/utils/currency';

import { LineClampedText } from './styles';
import ToppingsMenu from './ToppingsMenu';
const { getProductToppings } = RappiTurboActions;
const { addToBag, edditingToBag, removingFromTheBag } = Creators;

interface UseLocationProps {
  mode?: string;
  product: any;
}

const ProductDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { product_id: productId } = useParams<any>();
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const rappiStore = rappiTurbo?.store;
  const { itemsInBag } = globalState.bag;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const location = useLocation<UseLocationProps>();
  const { state } = location;
  const mode = state?.mode;
  const [productFromState, setProductFromState] = useState(state?.product);
  const [productFromCatalog, setProductFromCatalog] = useState(null);
  const [canAddToCart, setCanAddToCart] = useState<boolean>(false);
  const [productToBag, setProductToBag] = useState<any>();
  const [productCount, setProductCount] = useState<number>(1);
  const [price, setPrice] = useState<number>(productFromState?.price);
  const [selectedToppings, setSelectedToppings] = useState<SelectedTopping[] | null>(null);

  useEffect(() => {
    if (!state) {
      if (rappiTurbo?.sessionId) {
        dispatch(push(rappiStoreMenu));
      } else {
        dispatch(push(invalidSession));
      }
    } else {
      if (mode !== 'edditing') {
        setProductFromCatalog(productFromState);
      } else {
        setProductFromCatalog(
          getProductFromCatalog(
            rappiStore?.corridors,
            productFromState.corridor_id,
            productFromState.product_id,
          ),
        );
      }
    }
  }, []);

  useEffect(() => {
    let isRequiredToppingsFullfiled = true;

    if (productFromCatalog?.has_toppings && productFromCatalog?.topping_list?.length > 0) {
      isRequiredToppingsFullfiled = selectedToppings
        ?.filter((tc) => tc.min_toppings_for_categories > 0)
        .every((tc) => {
          let toppingsSum = 0;
          tc.toppings?.forEach((t) => {
            toppingsSum += t.quantity;
          });
          return tc.toppings.some((t) => toppingsSum >= tc.min_toppings_for_categories);
        });
    }

    setCanAddToCart(productCount > 0 && isRequiredToppingsFullfiled);
  }, [productCount]);

  useEffect(() => {
    if (productFromCatalog) {
      window.scrollTo(0, 0);

      if (productFromCatalog?.has_toppings && !productFromCatalog?.topping_list?.length) {
        dispatch(getProductToppings(productFromCatalog.corridor_id, productId));
      }
    }
  }, [dispatch, productFromCatalog, productId]);

  useEffect(() => {
    const productWithToppings = getProductFromCatalog(
      rappiStore?.corridors,
      productFromCatalog?.corridor_id,
      productFromCatalog?.product_id,
    );
    if (productWithToppings?.has_toppings && productWithToppings?.topping_list?.length > 0) {
      setProductFromCatalog(productWithToppings);
    }
  }, [productFromCatalog, rappiStore?.corridors]);

  useEffect(() => {
    const productWithSelectedToppings = { ...productFromCatalog };

    productWithSelectedToppings.selectedToppings = selectedToppings;
    productWithSelectedToppings.productQuantity = productCount;
    productWithSelectedToppings.priceWithToppings = price;
    setProductToBag({ bagId: productFromState.bagId, ...productWithSelectedToppings });
  }, [productFromCatalog, productCount, selectedToppings, price]);

  const handleOnChangeToppingOptions = useCallback(
    (toppingCategories: SelectedTopping[]) => {
      // Check if all required toppings are fullfiled
      const isRequiredToppingsFullfiled = toppingCategories
        ?.filter((tc) => tc.min_toppings_for_categories > 0)
        .every((tc) => {
          let toppingsSum = 0;
          tc.toppings?.forEach((t) => {
            toppingsSum += t.quantity;
          });
          return tc.toppings.some((t) => toppingsSum >= tc.min_toppings_for_categories);
        });

      // Calc toppings price
      let toppingsTotalPrice = 0;
      toppingCategories?.forEach((tc) => {
        tc.toppings.forEach((t) => {
          if (t.quantity === 1 && t.price > 0) {
            toppingsTotalPrice += t.price;
          } else if (t.quantity > 0 && t.price > 0) {
            toppingsTotalPrice += t.price * t.quantity;
          }
        });
      });

      setCanAddToCart(isRequiredToppingsFullfiled);

      setPrice((_) => {
        return productFromState?.price + toppingsTotalPrice;
      });

      if (isRequiredToppingsFullfiled) {
        setSelectedToppings(() => toppingCategories);
      }
    },
    [productFromState, setSelectedToppings],
  );

  const handleOnChangeProductCount = useCallback((count: number) => {
    setProductCount(count);
  }, []);

  const handleAddToBag = useCallback(() => {
    dispatch(addToBag({ bagId: itemsInBag.length, ...productToBag }));
    // Redirect the user to bag if the bag hasn't products yet, otherwise go to the menu
    if (itemsInBag.length === 0) {
      dispatch(push(bag));
    } else {
      dispatch(push(rappiStoreMenu));
    }
  }, [itemsInBag.length, productToBag, dispatch]);

  const handleEdittingToBag = useCallback(() => {
    dispatch(edditingToBag(productToBag));
    dispatch(push(bag));
  }, [productToBag, dispatch]);

  const handleRemovingItemQuantity = useCallback(() => {
    dispatch(removingFromTheBag(productFromState?.bagId));
    dispatch(push(bag));
  }, [productFromState, dispatch]);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{rappiStore?.name ? rappiStore?.name : 'Rappi Turbo'}</title>
      </Helmet>
      <Box as="main" flexDir="column" w="100%" mb={90}>
        <HeaderWithBackButton
          headerTitle={translate.getText('product_detail_title')}
          fontSize={'xl'}
          px="3rem"
          to={rappiStoreMenu}
        />

        <TopRoundedBox as="section" pl={10} pr={10} pt={0}>
          {productFromCatalog?.image && (
            <Image
              rounded="1rem"
              src={`https://${rappiTurbo?.endpoint
                .replace('microservices', 'images')
                .replace('services', 'images')}/products/${
                productFromCatalog?.image
              }?d=100x100&e=webp&q=100`}
              m="0 auto 2rem"
              maxH="30rem"
              objectFit="cover"
            />
          )}

          {/* Details */}
          <Stack alignItems="flex-start">
            <LineClampedText as="h3" fontSize="lg" fontWeight="700" lineHeight="1.5" color="black">
              {productFromCatalog?.name}
            </LineClampedText>
            <LineClampedText as="p" fontSize="1.5rem" color={'black'}>
              {productFromCatalog?.description}
            </LineClampedText>
            {productFromCatalog?.price > 0 && (
              <Text as="p" fontSize="md" fontWeight="700" color={'black'}>
                {formatCurrency(productFromCatalog?.price, rappiTurbo?.client?.language)}
              </Text>
            )}
          </Stack>

          {/* Toppings */}
          {rappiTurbo?.loading ? (
            <Box h="100%" w="100%" d="flex" alignItems="center" justifyContent="center" mb={12}>
              <Spinner size="xl" color="green.300" />
            </Box>
          ) : productFromCatalog?.has_toppings && productFromCatalog?.topping_list?.length > 0 ? (
            <ToppingsMenu
              mt={8}
              mb={4}
              toppingCategories={productFromCatalog?.topping_list}
              selectedToppings={productFromState?.selectedToppings}
              onChangeToppingOptions={handleOnChangeToppingOptions}
            />
          ) : null}
        </TopRoundedBox>
      </Box>

      {/* Quantity Bar */}
      <BottomBar py={5}>
        <Stack isInline align="center" ml={5} spacing={4} w="95%">
          <NumberCounter
            onChangeCount={handleOnChangeProductCount}
            initialCount={mode === 'edditing' ? productFromState?.productQuantity : 1}
            canReduceUpTo={mode === 'edditing' ? 0 : 1}
            py={8}
            flex="2"
          />
          <Button
            bg="green.300"
            d="flex"
            flexDir="column"
            alignItems="center"
            color="white"
            px="3rem"
            w="55%"
            borderRadius="50px"
            fontSize="lg"
            justifyContent="space-between"
            isDisabled={mode === 'edditing' ? false : !canAddToCart || rappiTurbo?.loading}
            onClick={
              mode === 'edditing' && productCount === 0
                ? handleRemovingItemQuantity
                : mode === 'edditing'
                ? handleEdittingToBag
                : handleAddToBag
            }
          >
            <span>
              {mode === 'edditing' && productCount === 0
                ? translate.getText('delete_item')
                : mode === 'edditing'
                ? translate.getText('update_item')
                : translate.getText('add_item')}
            </span>{' '}
            <span>{formatCurrency(price * productCount, rappiTurbo?.client?.language)}</span>
          </Button>
        </Stack>
      </BottomBar>
    </>
  );
};

export default ProductDetail;
