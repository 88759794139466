import { BoxProps, DrawerBody, DrawerHeader } from '@chakra-ui/core';
import React, { useEffect } from 'react';

import { Container, Overlay } from './styles';

export interface BottomBarProps extends BoxProps {
  isOverlayVisible?: boolean;
  onClose?: VoidFunction;
  bodyStyle?: BoxProps;
  zIndex?: number;
}

const BottomBar: React.FC<BottomBarProps> = ({
  title,
  isOverlayVisible,
  onClose,
  children,
  zIndex = 999,
  bodyStyle,
  ...rest
}) => {
  const overlayZIndex = (zIndex as number) - 1;
  return (
    <>
      {isOverlayVisible && (
        <Overlay
          zIndex={overlayZIndex}
          w="100%"
          h="100%"
          position="fixed"
          top="0"
          left="0"
          bg="rgba(0, 0, 0, 0.4)"
          onClick={onClose}
        />
      )}
      <Container
        position="fixed"
        bottom="0"
        left="50%"
        transform="translateX(-50%)"
        boxShadow="0px -2px 6px rgba(0, 0, 0, 0.3)"
        right="0"
        bg="white"
        zIndex={zIndex}
        w="100%"
        d="flex"
        justifyContent="center"
        {...rest}
      >
        {title && <DrawerHeader>{title}</DrawerHeader>}
        <DrawerBody py={3} w="min(900px, 100%)" flex={undefined} {...bodyStyle}>
          {children}
        </DrawerBody>
      </Container>
    </>
  );
};

export default BottomBar;
