import React from 'react';
import { Switch } from 'react-router-dom';

import ConfirmAddress from '~/pages/Addresses/ConfirmAddress';
import SearchAutocompleteAddress from '~/pages/Addresses/SearchAutocompleteAddress';
import Bag from '~/pages/Bag';
import InvalidSession from '~/pages/InvalidSession';
import Loading from '~/pages/Loading';
import OrderReview from '~/pages/OrderReview';
import ProductDetail from '~/pages/ProductDetail';
import RappiStoreMenu from '~/pages/RappiStoreMenu';
import RappiStoreMenuSearch from '~/pages/RappiStoreMenuSearch';
import SimulateConfirmCreditCardPaymentPage from '~/pages/SimulateConfirmCreditCardPaymentPage';
import SimulateConfirmPixPaymentPage from '~/pages/SimulateConfirmPixPaymentPage';
import SimulateConfirmSuccessPaymentPage from '~/pages/SimulateConfirmSuccessPaymentPage';
import Tracking from '~/pages/Tracking';

import {
  bag,
  confirmAddress,
  invalidSession,
  orderReview,
  productDetail,
  rappiStoreBase,
  rappiStoreMenu,
  rappiStoreMenuSearch,
  rappiStoreSessionId,
  searchAutoCompleteAddress,
  simulateConfirmCreditCardPaymentLink,
  simulateConfirmPixPaymentLink,
  simulateConfirmSuccessPaymentLink,
  trackingOrder,
} from './routeMap';
import Route from './RouteWrapper';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path={rappiStoreMenu} exact component={RappiStoreMenu} />
      <Route path={rappiStoreMenuSearch} exact component={RappiStoreMenuSearch} />
      <Route path={productDetail} exact component={ProductDetail} />
      <Route path={bag} exact component={Bag} />
      <Route path={searchAutoCompleteAddress} exact component={SearchAutocompleteAddress} />
      <Route path={confirmAddress} exact component={ConfirmAddress} />
      <Route path={orderReview} exact component={OrderReview} />
      <Route
        path={simulateConfirmCreditCardPaymentLink}
        exact
        component={SimulateConfirmCreditCardPaymentPage}
      />
      <Route path={simulateConfirmPixPaymentLink} exact component={SimulateConfirmPixPaymentPage} />
      <Route
        path={simulateConfirmSuccessPaymentLink}
        exact
        component={SimulateConfirmSuccessPaymentPage}
      />
      <Route path={trackingOrder} exact component={Tracking} />
      <Route path={invalidSession} exact component={InvalidSession} />
      <Route path={rappiStoreSessionId} exact component={Loading} />
      <Route path={rappiStoreBase} component={Loading} />
    </Switch>
  );
};

export default Routes;
