import { Button, Divider, Spinner } from '@chakra-ui/core';
import { Box, Image, Text } from '@chakra-ui/core';
import { differenceInMinutes, format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import GoBackButton from '~/components/GoBackButton';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import TrackingActions from '~/store/ducks/tracking';
import RealTimeDimensions from '~/utils/realTimeDimensions';

import useHoursCountDown from './Counter/useCountdown';
import TrackingOrderItems from './TrackingOrderItems';
import TrackingOrderTotals from './TrackingOrderTotals';
const { getTrackingData } = TrackingActions;

interface URLParamTypes {
  session_id: string;
}

const Tracking: React.FC = () => {
  const dispatch = useDispatch();
  const { session_id: sessionIdURL } = useParams<URLParamTypes>();
  const globalState = useSelector((state: RootState) => state);
  const trackingDataLoading = globalState?.tracking?.getTrackingDataLoading;
  const trackingData = globalState?.tracking?.trackingData;
  const translate = useTranslate(trackingData?.client?.language);
  const [selectedOption, setSelectedOption] = useState('status');
  const [chatOpenned, setChatOpenned] = useState(false);
  const [counter, setCounter] = useState('00:00');
  const [minutes, seconds] = useHoursCountDown(
    trackingData?.timer?.eta_upper ? new Date(trackingData?.timer?.eta_upper) : new Date(),
  );
  const [orderStep, setOrderStep] = useState('step1');
  const [minutesGetData, setMinutesGetData] = useState(0);
  const [intervalID, setIntervalID] = useState(null);
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;

  function extractHourFromDate(date: string) {
    return format(new Date(date), 'HH:mm');
  }

  // Force get new order status
  useEffect(() => {
    setOrderStep('step1');
    dispatch(getTrackingData(sessionIdURL));

    const id = setInterval(() => {
      if (minutesGetData <= 10) {
        dispatch(getTrackingData(sessionIdURL));
        setMinutesGetData(minutesGetData + 1);
      } else {
        clearInterval(intervalID);
      }
    }, 20000);

    setIntervalID(id);
  }, []);

  useEffect(() => {
    if (trackingData?.statuses?.close_order != null) {
      setOrderStep('step6');
      return;
    }
    if (trackingData?.statuses?.hand_to_domiciliary != null) {
      setOrderStep('step5');
      return;
    }
    if (trackingData?.statuses?.domiciliary_in_store != null) {
      setOrderStep('step4');
      return;
    }
    if (trackingData?.statuses?.taken_visible_order != null) {
      setOrderStep('step3');
      return;
    }
    if (trackingData?.statuses?.assign_to_partner != null) {
      setOrderStep('step2');
      return;
    }
    setOrderStep('step1');
  }, [trackingData]);

  const leftPad = (value: any) => `${value}`.padStart(2, '0');

  useEffect(() => {
    if (minutes + seconds >= 0) {
      setCounter(`${leftPad(minutes)}:${leftPad(seconds)}`);
    } else {
      setCounter('00:00');
    }
  }, [minutes, seconds]);

  const goBackWhatsapp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?phone=${trackingData?.store?.phone}`,
      isMobile ? '_top' : '_blank',
    );
  }, [trackingData?.store?.phone]);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{`${translate.getText('tracking_title')}${
          trackingData?.store?.name ? ` - ${trackingData?.store?.name}` : ''
        }`}</title>
      </Helmet>
      {trackingDataLoading && (
        <Box h="100%" w="100%" d="flex" alignItems="center" justifyContent="center">
          <Spinner size="xl" color="#01F43A" />
        </Box>
      )}
      {!trackingDataLoading && !trackingData && (
        <Box
          as="main"
          d="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          pt={20}
          px={10}
        >
          <Box d="flex" flexDir="row" mb={20}>
            <Image
              src={'/images/logo.jpg'}
              backgroundColor="white"
              h={50}
              w={50}
              borderRadius="50%"
              objectFit="cover"
            />
            <Text fontSize="2xl" textAlign="center" fontWeight={700} mt={3} ml={5}>
              {trackingData?.store?.name}
            </Text>
          </Box>
          <Image
            src="/images/tracking_error.png"
            objectFit="cover"
            alignSelf="center"
            w="55%"
            mr={5}
          />
          <Text fontSize="2xl" textAlign="center" fontWeight={700} mt={10} mb={5}>
            {translate.getText('tracking_loading_error_title')}
          </Text>
          <Text fontSize="lg" textAlign="center">
            {translate.getText('tracking_loading_error_text')}
          </Text>
          {trackingData?.store?.phone && (
            <Box d="flex" w="100%" py={4} mt={10} alignSelf={'center'}>
              <Button
                px="3rem"
                borderRadius="50px"
                bg="green.300"
                variantColor="green.300"
                type="button"
                w="100%"
                py="30px"
                onClick={goBackWhatsapp}
              >
                <Text color="white" fontSize="lg">
                  {translate.getText('go_back_to_whatsapp')}
                </Text>
              </Button>
            </Box>
          )}
        </Box>
      )}
      {!trackingDataLoading && trackingData && (
        <Box d="flex" flexDir="column" alignItems="center">
          <Box d="flex" flexDir="row" w="100%" justifyContent={'center'} pb="14px">
            <Image
              mt="16px"
              alignSelf="center"
              src={translate.getText('selled_delivery_rappi_img')}
              w="50%"
              objectFit="cover"
            />
            <Button
              ml={20}
              alignSelf={'center'}
              borderRadius="50px"
              type="button"
              w="27%"
              fontSize="md"
              variant="ghost"
              py={8}
              boxShadow="5px 5px 16px rgba(0, 0, 0, 0.15)"
              mt="15px"
              onClick={() => setChatOpenned(!chatOpenned)}
            >
              <Box d="flex" flexDir="row">
                <Image src={'/images/support_icon.png'} h={8} w={8} objectFit="contain" mr={4} />
                {translate.getText('turbo_help')}
              </Box>
            </Button>
          </Box>
          {!chatOpenned && (
            <>
              <Box
                d="flex"
                flexDir="column"
                bg="green.400"
                w="100%"
                pt={5}
                pb={8}
                justifyContent="space-between"
                alignContent="center"
              >
                {trackingData?.timer?.turbo && (
                  <Text fontSize="5rem" textAlign="center" fontWeight={700} color="white" as="i">
                    Turbo
                  </Text>
                )}
                <Text
                  fontSize="2rem"
                  textAlign="center"
                  fontWeight={700}
                  color="white"
                  mt={trackingData?.timer?.turbo ? '-10px' : '10px'}
                >
                  {trackingData?.statuses?.close_order
                    ? translate.getText('turbo_delivery_time_delivered_info')
                    : translate.getText('turbo_delivery_time_info')}
                </Text>
                <Text fontSize="7rem" textAlign="center" fontWeight={700} color="white" mt="-15px">
                  {trackingData?.statuses?.close_order
                    ? `${leftPad(
                        differenceInMinutes(
                          new Date(trackingData?.statuses?.close_order),
                          new Date(trackingData?.statuses?.confirmed_payment),
                        ),
                      )}:00`
                    : counter}
                </Text>
                <Box d="flex" flexDir="row" justifyContent="center" mt="-25px">
                  <Text fontSize="2rem" textAlign="center" fontWeight={400} color="white">
                    {translate.getText('turbo_delivery_time_min')}
                  </Text>
                  <Text fontSize="2rem" textAlign="center" fontWeight={400} color="white" ml={10}>
                    {translate.getText('turbo_delivery_time_sec')}
                  </Text>
                </Box>
              </Box>
              <Box
                d="flex"
                flexDir="row"
                bg="gray.100"
                w="87%"
                mt={10}
                p={3}
                borderRadius="50px"
                justifyContent="space-between"
                alignContent="center"
              >
                <Button
                  alignSelf={'center'}
                  borderRadius="50px"
                  type="button"
                  w="auto"
                  fontSize="md"
                  bg={selectedOption === 'status' ? 'white' : 'gray.100'}
                  variantColor={selectedOption === 'status' ? 'white' : 'gray.100'}
                  py={8}
                  px={8}
                  color={selectedOption === 'status' ? 'green.300' : 'black'}
                  onClick={() => setSelectedOption('status')}
                >
                  {translate.getText('turbo_status_tab')}
                </Button>
                <Button
                  alignSelf={'center'}
                  borderRadius="50px"
                  type="button"
                  w="auto"
                  fontSize="md"
                  bg={selectedOption === 'details' ? 'white' : 'gray.100'}
                  variantColor={selectedOption === 'status' ? 'white' : 'gray.100'}
                  py={8}
                  px={8}
                  color={selectedOption === 'details' ? 'green.300' : 'black'}
                  onClick={() => setSelectedOption('details')}
                >
                  {translate.getText('turbo_details_tab')}
                </Button>
                <Button
                  alignSelf={'center'}
                  borderRadius="50px"
                  type="button"
                  w="auto"
                  fontSize="md"
                  bg={selectedOption === 'delivery_man' ? 'white' : 'gray.100'}
                  variantColor={selectedOption === 'status' ? 'white' : 'gray.100'}
                  py={8}
                  px={8}
                  color={selectedOption === 'delivery_man' ? 'green.300' : 'black'}
                  onClick={() => setSelectedOption('delivery_man')}
                >
                  {translate.getText('turbo_delivery_man_tab')}
                </Button>
              </Box>
              <Divider w="90%" mt={10} mb={10} />
              {selectedOption === 'status' && (
                <Box mb={10}>
                  <Box d="flex" flexDir="row" alignItems="flex-start" w="100%" px="30px">
                    <Box d="flex" flexDir="column" alignItems="flex-start" w="100%">
                      <Text fontSize="1.7rem" fontWeight={400} color="gray.700">
                        {trackingData?.statuses?.close_order
                          ? translate
                              .getText('turbo_delivered_at')
                              .replace(
                                '@deliveredHour@',
                                extractHourFromDate(trackingData?.statuses?.close_order),
                              )
                          : translate
                              .getText('turbo_confirmed_at')
                              .replace(
                                '@confirmedHour@',
                                extractHourFromDate(trackingData?.statuses?.confirmed_payment),
                              )}
                      </Text>
                      <Text fontSize="2rem" fontWeight={700}>
                        {trackingData?.statuses?.close_order
                          ? translate.getText('turbo_order_close_order')
                          : translate.getText('turbo_order_active')}
                      </Text>
                      <Text fontSize="2rem" fontWeight={400} color="gray.700">
                        {`#${trackingData?.rappi_order_id}`}
                      </Text>
                    </Box>
                    <Image
                      alignSelf="center"
                      src={`/images/tracking_${orderStep}.png?nocache=${new Date().getTime()}`}
                      w="29%"
                      objectFit="cover"
                    />
                  </Box>
                  <Divider w="90%" mt={10} mb={10} />
                  <Box d="flex" flexDir="row" justifyContent="center">
                    <Image
                      alignSelf="center"
                      src={`/images/timeline_${orderStep}.png?nocache=${new Date().getTime()}`}
                      w="55px"
                      objectFit="cover"
                    />
                    <Box d="flex" flexDir="column" ml={5}>
                      <Box
                        d="flex"
                        flexDir="column"
                        h="58px"
                        color={trackingData?.statuses?.assign_to_partner ? 'black' : 'gray.700'}
                      >
                        <Text fontSize="1.7rem" fontWeight={700}>
                          {translate.getText('turbo_order_assigned_to_partner')}
                        </Text>
                        <Text fontSize="2rem" fontWeight={400} color="gray.700">
                          {trackingData?.statuses?.assign_to_partner &&
                            extractHourFromDate(trackingData?.statuses?.assign_to_partner)}
                        </Text>
                      </Box>
                      <Box
                        d="flex"
                        flexDir="column"
                        mt="30px"
                        h="58px"
                        color={trackingData?.statuses?.taken_visible_order ? 'black' : 'gray.700'}
                      >
                        <Text fontSize="1.7rem" fontWeight={700}>
                          {translate.getText('turbo_order_taken_visible_order')}
                        </Text>
                        <Text fontSize="2rem" fontWeight={400} color="gray.700">
                          {trackingData?.statuses?.taken_visible_order &&
                            extractHourFromDate(trackingData?.statuses?.taken_visible_order)}
                        </Text>
                      </Box>
                      <Box
                        d="flex"
                        flexDir="column"
                        mt="30px"
                        h="58px"
                        color={trackingData?.statuses?.domiciliary_in_store ? 'black' : 'gray.700'}
                      >
                        <Text fontSize="1.7rem" fontWeight={700}>
                          {translate.getText('turbo_order_domiciliary_in_store')}
                        </Text>
                        <Text fontSize="2rem" fontWeight={400} color="gray.700">
                          {trackingData?.statuses?.domiciliary_in_store &&
                            extractHourFromDate(trackingData?.statuses?.domiciliary_in_store)}
                        </Text>
                      </Box>
                      <Box
                        d="flex"
                        flexDir="column"
                        mt="30px"
                        h="58px"
                        color={trackingData?.statuses?.hand_to_domiciliary ? 'black' : 'gray.700'}
                      >
                        <Text fontSize="1.7rem" fontWeight={700}>
                          {translate.getText('turbo_order_hand_to_domiciliary')}
                        </Text>
                        <Text fontSize="2rem" fontWeight={400} color="gray.700">
                          {trackingData?.statuses?.hand_to_domiciliary &&
                            extractHourFromDate(trackingData?.statuses?.hand_to_domiciliary)}
                        </Text>
                      </Box>
                      <Box
                        d="flex"
                        flexDir="column"
                        mt="30px"
                        h="58px"
                        color={trackingData?.statuses?.close_order ? 'black' : 'gray.700'}
                      >
                        <Text fontSize="1.7rem" fontWeight={700}>
                          {translate.getText('turbo_order_close_order')}
                        </Text>
                        <Text fontSize="2rem" fontWeight={400} color="gray.700">
                          {trackingData?.statuses?.close_order &&
                            extractHourFromDate(trackingData?.statuses?.close_order)}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              {selectedOption === 'details' && (
                <Box d="flex" flexDir="column" w="100%" alignContent={'center'} px={10}>
                  <Text fontSize="2rem" fontWeight={700}>
                    {translate.getText('turbo_order_summary')}
                  </Text>

                  <TrackingOrderItems />

                  <TrackingOrderTotals
                    totalAmount={trackingData?.bag?.priceWithoutDiscount}
                    deliveryFee={trackingData?.bag?.deliveryFee}
                    serviceFee={trackingData?.bag?.serviceFee}
                  />
                </Box>
              )}
              {selectedOption === 'delivery_man' && (
                <Box mt={-10}>
                  <Image
                    alignSelf="center"
                    src={`/images/delivery_man_map_${orderStep}.png?nocache=${new Date().getTime()}`}
                    w="100%"
                    objectFit="cover"
                  />
                </Box>
              )}
            </>
          )}
          {chatOpenned && (
            <Box
              position="fixed"
              bottom="0"
              left="50%"
              transform="translateX(-50%)"
              boxShadow="0px -2px 6px rgba(0, 0, 0, 0.3)"
              right="0"
              bg="white"
              w="100%"
              d="flex"
              h="90%"
              alignContent="center"
              flexDir="column"
            >
              <Box d="flex" flexDir="row" p={10} w="100%">
                <GoBackButton onClick={() => setChatOpenned(!chatOpenned)} />
                <Text fontSize="1.8rem" fontWeight="700" pt={1} pl="2.5rem" w="100%">
                  {translate.getText('turbo_help_title')}
                </Text>
              </Box>
              <Image
                mt="auto"
                p={10}
                alignSelf="flex-end"
                src={'/images/help_chat_bar.png'}
                w="100%"
                objectFit="cover"
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Tracking;
