import { Image } from '@chakra-ui/core';
import { Box, BoxProps, Text } from '@chakra-ui/core';
import React from 'react';

import { formatCurrency } from '~/utils/currency';

export interface BagDeliveryFeeDescriptionProps extends BoxProps {
  descriptionTitle: string;
  descriptionPrice: number;
  pro: boolean;
  language: string;
}

const BagDeliveryFeeDescription: React.FC<BagDeliveryFeeDescriptionProps> = ({
  descriptionTitle,
  descriptionPrice,
  pro,
  language,
}) => {
  return (
    <Box d="flex" alignItems="center" justifyContent="space-between">
      <Text color="gray.300" as="span" fontSize="1.7rem" fontWeight="400">
        {descriptionTitle}
      </Text>
      {pro ? (
        <Box d="flex" flexDir="row" justifyContent="center">
          <Image src={'/images/pro_icon.png'} h="15px" objectFit="contain" />
          {descriptionPrice > 0 && (
            <Text color="gray.300" as="s" fontSize="1.7rem" fontWeight="400" ml={3}>
              {formatCurrency(descriptionPrice, language)}
            </Text>
          )}
          <Text as="span" color={'#1662E5'} fontSize="1.7rem" fontWeight="400" ml={4}>
            {formatCurrency(0, language)}
          </Text>
        </Box>
      ) : (
        <Text color="gray.300" as="span" fontSize="1.7rem" fontWeight="400">
          {descriptionPrice > 0 ? formatCurrency(descriptionPrice, language) : 'Grátis'}
        </Text>
      )}
    </Box>
  );
};

export default BagDeliveryFeeDescription;
