import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import Address, { ConfirmAddressInputs } from '~/models/Address';
import Checkout from '~/models/CheckoutData';
import CheckoutData from '~/models/CheckoutData';
import Client, { ConfirmUserInputs } from '~/models/Client';
import LastRappiData from '~/models/LastRappiData';
import PaymentData from '~/models/PaymentData';
import RappiAuthResponse from '~/models/RappiAuthResponse';
import RappiNewAuthResponse from '~/models/RappiNewAuthResponse';
import RappiStore, { RappiStoreInvalidSession } from '~/models/RappiStore';
import { getCategoryIdx, getProductIdx } from '~/utils/bag';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  GET_AUTH_DATA = 'GET_AUTH_DATA',
  SET_AUTH_DATA = 'SET_AUTH_DATA',
  SET_AUTH_DATA_CHANGED_STORE = 'SET_AUTH_DATA_CHANGED_STORE',
  GET_STORE_DATA = 'GET_STORE_DATA',
  SET_STORE_DATA = 'SET_STORE_DATA',
  CLEAR_RAPPI_TURBO_DATA = 'CLEAR_RAPPI_TURBO_DATA',
  GET_PRODUCT_TOPPINGS = 'GET_PRODUCT_TOPPINGS',
  SET_PRODUCT_TOPPINGS = 'SET_PRODUCT_TOPPINGS',
  SET_LOADING = 'SET_LOADING',
  SET_INITIALIZATE_CHECKOUT_LOADING = 'SET_INITIALIZATE_CHECKOUT_LOADING',
  SET_INVALID_SESSION = 'SET_INVALID_SESSION',

  CLEAR_SUCCESS = 'CLEAR_SUCCESS',
  UPDATE_CLIENT_ADDRESS = 'UPDATE_CLIENT_ADDRESS',
  UPDATE_CLIENT_ADDRESS_SUCCESS = 'UPDATE_CLIENT_ADDRESS_SUCCESS',
  UPDATE_CLIENT_ADDRESS_FAILURE = 'UPDATE_CLIENT_ADDRESS_FAILURE',
  INITIALIZATE_CHECKOUT = 'INITIALIZATE_CHECKOUT',
  INITIALIZATE_CHECKOUT_SUCCESS = 'INITIALIZATE_CHECKOUT_SUCCESS',
  INITIALIZATE_CHECKOUT_FAILURE = 'INITIALIZATE_CHECKOUT_FAILURE',
  GENERATE_PAYMENT = 'GENERATE_PAYMENT',
  GENERATE_PAYMENT_SUCCESS = 'GENERATE_PAYMENT_SUCCESS',
  GENERATE_PAYMENT_FAILURE = 'GENERATE_PAYMENT_FAILURE',
}

export interface OnGetAuthData extends Action<TypesNames.GET_AUTH_DATA> {
  sessionId: string;
}

export interface OnSetAuthData extends Action<TypesNames.SET_AUTH_DATA> {
  sessionId: string;
  rappiAuthResponse: RappiAuthResponse;
}

export interface OnSetAuthDataChangedStore extends Action<TypesNames.SET_AUTH_DATA_CHANGED_STORE> {
  rappiNewAuthResponse: RappiNewAuthResponse;
}

export interface OnGetStoreData extends Action<TypesNames.GET_STORE_DATA> {
  redirectToMenu: boolean;
  rappiStoreId?: string;
}

export interface OnSetStoreData extends Action<TypesNames.SET_STORE_DATA> {
  storeData: any;
}

export interface OnClearRappiTurboData extends Action<TypesNames.CLEAR_RAPPI_TURBO_DATA> {}

export interface OnGetProductToppings extends Action<TypesNames.GET_PRODUCT_TOPPINGS> {
  categoryId: number;
  productId: number;
}

export interface OnSetProductToppings extends Action<TypesNames.SET_PRODUCT_TOPPINGS> {
  categoryId: number;
  productId: number;
  productToppings: any;
}

export interface OnSetLoading extends Action<TypesNames.SET_LOADING> {
  loading: boolean;
}

export interface OnSetInitializateCheckoutLoading
  extends Action<TypesNames.SET_INITIALIZATE_CHECKOUT_LOADING> {
  initializateCheckoutLoading: boolean;
}

export interface OnSetInvalidSession extends Action<TypesNames.SET_INVALID_SESSION> {
  rappiStore: RappiStoreInvalidSession;
}

export interface OnClearSuccess extends Action<TypesNames.CLEAR_SUCCESS> {}

export interface OnUpdateClientAddress extends Action<TypesNames.UPDATE_CLIENT_ADDRESS> {
  confirmedAddress: ConfirmAddressInputs;
}

export interface OnUpdateClientAddressSuccess
  extends Action<TypesNames.UPDATE_CLIENT_ADDRESS_SUCCESS> {
  address: Address;
}

export interface OnUpdateClientAddressFailure
  extends Action<TypesNames.UPDATE_CLIENT_ADDRESS_FAILURE> {}

export interface OnInitializateCheckout extends Action<TypesNames.INITIALIZATE_CHECKOUT> {
  checkoutData: Checkout;
}

export interface OnInitializateCheckoutSuccess
  extends Action<TypesNames.INITIALIZATE_CHECKOUT_SUCCESS> {
  confirmedUserData: ConfirmUserInputs;
}

export interface OnInitializateCheckoutFailure
  extends Action<TypesNames.INITIALIZATE_CHECKOUT_FAILURE> {}

export interface OnGeneratePayment extends Action<TypesNames.GENERATE_PAYMENT> {
  paymentData: PaymentData;
}

export interface OnGeneratePaymentSuccess extends Action<TypesNames.GENERATE_PAYMENT_SUCCESS> {}

export interface OnGeneratePaymentFailure extends Action<TypesNames.GENERATE_PAYMENT_FAILURE> {}

/* ============== ACTION CREATORS AND TYPES ============== */

export const { Types, Creators } = createActions<
  {
    [TypesNames.GET_AUTH_DATA]: string;
    [TypesNames.SET_AUTH_DATA]: string;
    [TypesNames.SET_AUTH_DATA_CHANGED_STORE]: string;
    [TypesNames.GET_STORE_DATA]: string;
    [TypesNames.SET_STORE_DATA]: string;
    [TypesNames.CLEAR_RAPPI_TURBO_DATA]: string;
    [TypesNames.GET_PRODUCT_TOPPINGS]: string;
    [TypesNames.SET_PRODUCT_TOPPINGS]: string;
    [TypesNames.SET_LOADING]: string;
    [TypesNames.SET_INITIALIZATE_CHECKOUT_LOADING]: string;
    [TypesNames.SET_INVALID_SESSION]: string;

    [TypesNames.CLEAR_SUCCESS]: string;
    [TypesNames.UPDATE_CLIENT_ADDRESS]: string;
    [TypesNames.UPDATE_CLIENT_ADDRESS_SUCCESS]: string;
    [TypesNames.UPDATE_CLIENT_ADDRESS_FAILURE]: string;
    [TypesNames.INITIALIZATE_CHECKOUT]: string;
    [TypesNames.INITIALIZATE_CHECKOUT_SUCCESS]: string;
    [TypesNames.INITIALIZATE_CHECKOUT_FAILURE]: string;
    [TypesNames.GENERATE_PAYMENT]: string;
    [TypesNames.GENERATE_PAYMENT_SUCCESS]: string;
    [TypesNames.GENERATE_PAYMENT_FAILURE]: string;
  },
  {
    getAuthData: (sessionId: string) => OnGetAuthData;
    setAuthData: (sessionId: string, rappiAuthResponse: RappiAuthResponse) => OnSetAuthData;
    setAuthDataChangedStore: (
      rappiNewAuthResponse: RappiNewAuthResponse,
    ) => OnSetAuthDataChangedStore;
    getStoreData: (redirectToMenu: boolean, rappiStoreId?: string) => OnGetStoreData;
    setStoreData: (storeData: any) => OnSetStoreData;
    clearRappiTurboData: () => OnClearRappiTurboData;
    getProductToppings: (categoryId: number, productId: number) => OnGetProductToppings;
    setProductToppings: (
      categoryId: number,
      productId: number,
      productToppings: any,
    ) => OnSetProductToppings;
    setLoading: (loading: boolean) => OnSetLoading;
    setInvalidSession: (rappiStore: RappiStore) => OnSetInvalidSession;

    clearSuccess: () => OnClearSuccess;
    updateClientAddress: (confirmedAddress: ConfirmAddressInputs) => OnUpdateClientAddress;
    updateClientAddressSuccess: (address: Address) => OnUpdateClientAddressSuccess;
    updateClientAddressFailure: () => OnUpdateClientAddressFailure;
    initializateCheckout: (checkoutData: CheckoutData) => OnInitializateCheckout;
    initializateCheckoutSuccess: (
      confirmedUserData: ConfirmUserInputs,
    ) => OnInitializateCheckoutSuccess;
    initializateCheckoutFailure: () => OnInitializateCheckoutFailure;
    generatePayment: (paymentData: PaymentData) => OnGeneratePayment;
    generatePaymentSuccess: () => OnGeneratePaymentSuccess;
    generatePaymentFailure: () => OnGeneratePaymentFailure;
  }
>({
  getAuthData: ['sessionId'],
  setAuthData: ['sessionId', 'rappiAuthResponse'],
  setAuthDataChangedStore: ['rappiNewAuthResponse'],
  getStoreData: ['redirectToMenu', 'rappiStoreId'],
  setStoreData: ['storeData'],
  clearRappiTurboData: [],
  getProductToppings: ['categoryId', 'productId'],
  setProductToppings: ['categoryId', 'productId', 'productToppings'],
  setLoading: ['loading'],
  setInvalidSession: ['rappiStore'],

  clearSuccess: [],
  updateClientAddress: ['confirmedAddress'],
  updateClientAddressSuccess: ['address'],
  updateClientAddressFailure: [],
  initializateCheckout: ['checkoutData'],
  initializateCheckoutSuccess: ['confirmedUserData'],
  initializateCheckoutFailure: [],
  generatePayment: ['paymentData'],
  generatePaymentSuccess: [],
  generatePaymentFailure: [],
});

export const RappiTurboTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */

export interface RappiTurboStateType {
  sessionId?: string;
  endpoint?: string;
  token?: string;
  client?: Client;
  store?: RappiStore;
  lastRappiData: LastRappiData;
  loading: boolean;

  updateAddressLoading: boolean;
  updateAddressSuccess: boolean;
  initializateCheckoutLoading: boolean;
  initializateCheckoutSuccess: boolean;
  generatePaymentLoading: boolean;
  generatePaymentSuccess: boolean;
}

export const INITIAL_STATE: RappiTurboStateType = {
  sessionId: null,
  endpoint: null,
  token: null,
  client: null,
  store: null,
  lastRappiData: null,
  loading: false,

  updateAddressLoading: false,
  updateAddressSuccess: false,
  initializateCheckoutLoading: false,
  initializateCheckoutSuccess: false,
  generatePaymentLoading: false,
  generatePaymentSuccess: false,
};

/* ============== REDUCERS ============== */

export const setAuthDataReducer = (
  state = INITIAL_STATE,
  { sessionId, rappiAuthResponse }: OnSetAuthData,
) =>
  produce(state, (draft) => {
    draft.sessionId = sessionId;
    draft.endpoint = rappiAuthResponse?.rappi_endpoint;
    draft.token = rappiAuthResponse?.token;
    draft.store = {
      id: Number(rappiAuthResponse?.rappi_store_id),
      name: rappiAuthResponse?.rappi_store_name,
      phone: rappiAuthResponse?.store_phone,
      logo: null,
      background: null,
      address: null,
      open: null,
      eta: null,
      delivery_price: null,
      corridors: [],
    };
    draft.client = rappiAuthResponse?.client;
  });

export const setAuthDataChangedStoreReducer = (
  state = INITIAL_STATE,
  { rappiNewAuthResponse }: OnSetAuthDataChangedStore,
) =>
  produce(state, (draft) => {
    draft.endpoint = rappiNewAuthResponse?.rappi_endpoint;
    draft.token = rappiNewAuthResponse?.token;
  });

export const setStoreDataReducer = (state = INITIAL_STATE, { storeData }: OnSetStoreData) =>
  produce(state, (draft) => {
    draft.store.logo = `https://${state?.endpoint
      .replace('microservices', 'images')
      .replace('services', 'images')}/restaurants_logo/${storeData?.logo}?e=webp&d=50x50&q=50`;
    draft.store.background = `https://${state?.endpoint
      .replace('microservices', 'images')
      .replace('services', 'images')}/restaurants_background/${
      storeData?.background
    }?e=webp&d=500x500&q=50`;
    draft.store.address = storeData?.address;
    draft.store.open = storeData?.open;
    draft.store.eta = storeData?.eta;
    draft.store.delivery_price = storeData?.delivery_price;
    draft.store.corridors = storeData?.corridors;
    draft.loading = false;

    draft.updateAddressLoading = false;
    draft.updateAddressSuccess = false;
    draft.initializateCheckoutLoading = false;
    draft.initializateCheckoutSuccess = false;
    draft.generatePaymentLoading = false;
    draft.generatePaymentSuccess = false;
  });

export const clearRappiTurboDataReducer = (state = INITIAL_STATE, {}: OnClearRappiTurboData) =>
  produce(state, (draft) => {
    draft = INITIAL_STATE;
  });

export const setProductToppingsReducer = (
  state = INITIAL_STATE,
  { categoryId, productId, productToppings }: OnSetProductToppings,
) =>
  produce(state, (draft) => {
    const categoryIdx = getCategoryIdx(state.store.corridors, categoryId);
    if (categoryIdx === -1) {
      return;
    }

    const productIdx = getProductIdx(state.store.corridors[categoryIdx]?.products, productId);
    if (productIdx === -1) {
      return;
    }

    draft.store.corridors[categoryIdx].products[productIdx].topping_list = productToppings;
  });

export const setLoadingReducer = (state = INITIAL_STATE, { loading }: OnSetLoading) =>
  produce(state, (draft) => {
    draft.loading = loading;
  });

export const setInvalidSessionReducer = (
  state = INITIAL_STATE,
  { rappiStore }: OnSetInvalidSession,
) =>
  produce(state, (draft) => {
    draft.lastRappiData = {
      store: {
        name: rappiStore?.rappi_store_name,
        phone: rappiStore?.store_phone,
      },
      client: {
        language: rappiStore?.client_language,
      },
    };
    draft.sessionId = null;
    draft.endpoint = null;
    draft.token = null;
    draft.client = null;
    draft.store = {
      id: null,
      name: null,
      phone: null,
      logo: null,
      background: null,
      address: null,
      open: null,
      eta: null,
      delivery_price: null,
      corridors: [],
    };
    draft.loading = false;
    draft.updateAddressLoading = false;
    draft.updateAddressSuccess = false;
    draft.generatePaymentLoading = false;
    draft.generatePaymentSuccess = false;
  });

export const clearSuccessReducer = (state = INITIAL_STATE, {}: OnClearSuccess) =>
  produce(state, (draft) => {
    draft.updateAddressSuccess = false;
    draft.initializateCheckoutSuccess = false;
    draft.generatePaymentSuccess = false;
  });

export const updateClientAddressReducer = (
  state = INITIAL_STATE,
  { confirmedAddress }: OnUpdateClientAddress,
) =>
  produce(state, (draft) => {
    draft.updateAddressLoading = true;
    draft.updateAddressSuccess = false;
  });

export const updateClientAddressSuccessReducer = (
  state = INITIAL_STATE,
  { address }: OnUpdateClientAddressSuccess,
) =>
  produce(state, (draft) => {
    draft.client.address = address;
    draft.updateAddressLoading = false;
    draft.updateAddressSuccess = true;
  });

export const updateClientAddressFailureReducer = (
  state = INITIAL_STATE,
  {}: OnUpdateClientAddressFailure,
) =>
  produce(state, (draft) => {
    draft.updateAddressLoading = false;
    draft.updateAddressSuccess = false;
  });

export const initializateCheckoutReducer = (
  state = INITIAL_STATE,
  { checkoutData }: OnInitializateCheckout,
) =>
  produce(state, (draft) => {
    draft.initializateCheckoutLoading = true;
    draft.initializateCheckoutSuccess = false;
  });

export const initializateCheckoutSuccessReducer = (
  state = INITIAL_STATE,
  { confirmedUserData }: OnInitializateCheckoutSuccess,
) =>
  produce(state, (draft) => {
    draft.client.first_name = confirmedUserData?.first_name;
    draft.client.surname = confirmedUserData?.surname;
    draft.client.identification = confirmedUserData?.identification;
    draft.client.email = confirmedUserData?.email;
    draft.initializateCheckoutLoading = false;
    draft.initializateCheckoutSuccess = true;
  });

export const initializateCheckoutFailureReducer = (
  state = INITIAL_STATE,
  {}: OnInitializateCheckoutFailure,
) =>
  produce(state, (draft) => {
    draft.initializateCheckoutLoading = false;
    draft.initializateCheckoutSuccess = false;
  });

export const generatePaymentReducer = (state = INITIAL_STATE, { paymentData }: OnGeneratePayment) =>
  produce(state, (draft) => {
    draft.generatePaymentLoading = true;
    draft.generatePaymentSuccess = false;
  });

export const generatePaymentSuccessReducer = (
  state = INITIAL_STATE,
  {}: OnGeneratePaymentSuccess,
) =>
  produce(state, (draft) => {
    draft.generatePaymentLoading = false;
  });

export const generatePaymentFailureReducer = (
  state = INITIAL_STATE,
  {}: OnGeneratePaymentFailure,
) =>
  produce(state, (draft) => {
    draft.generatePaymentLoading = false;
    draft.generatePaymentSuccess = false;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.SET_AUTH_DATA]: setAuthDataReducer,
  [Types.SET_AUTH_DATA_CHANGED_STORE]: setAuthDataChangedStoreReducer,
  [Types.SET_STORE_DATA]: setStoreDataReducer,
  [Types.CLEAR_RAPPI_TURBO_DATA]: clearRappiTurboDataReducer,
  [Types.SET_PRODUCT_TOPPINGS]: setProductToppingsReducer,
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_INVALID_SESSION]: setInvalidSessionReducer,
  [Types.CLEAR_SUCCESS]: clearSuccessReducer,
  [Types.UPDATE_CLIENT_ADDRESS]: updateClientAddressReducer,
  [Types.UPDATE_CLIENT_ADDRESS_SUCCESS]: updateClientAddressSuccessReducer,
  [Types.UPDATE_CLIENT_ADDRESS_FAILURE]: updateClientAddressFailureReducer,
  [Types.INITIALIZATE_CHECKOUT]: initializateCheckoutReducer,
  [Types.INITIALIZATE_CHECKOUT_SUCCESS]: initializateCheckoutSuccessReducer,
  [Types.INITIALIZATE_CHECKOUT_FAILURE]: initializateCheckoutFailureReducer,
  [Types.GENERATE_PAYMENT]: generatePaymentReducer,
  [Types.GENERATE_PAYMENT_SUCCESS]: generatePaymentSuccessReducer,
  [Types.GENERATE_PAYMENT_FAILURE]: generatePaymentFailureReducer,
});
