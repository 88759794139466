const arr = (x: any) => Array.from(x);
const num = (x: any) => Number(x) || 0;
// const str = (x: any) => String(x);
const isEmpty = (xs: any) => xs.length === 0;
const take = (n: any) => (xs: any) => xs.slice(0, n);
const drop = (n: any) => (xs: any) => xs.slice(n);
const reverse = (xs: any) => xs.slice(0).reverse();
const comp = (f: any) => (g: any) => (x: any) => f(g(x));
const not = (x: any) => !x;
const chunk =
  (n: any) =>
  (xs: any): any =>
    isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

export default function numToWords(n: number | string, language: string = 'pt-BR'): string {
  let a: any = {
    'pt-BR': [
      '',
      'um',
      'dois',
      'três',
      'quatro',
      'cinco',
      'seis',
      'sete',
      'oito',
      'nove',
      'dez',
      'onze',
      'doze',
      'treze',
      'quatorze',
      'quinze',
      'dezesseis',
      'dezessete',
      'dezoito',
      'dezenove',
    ],
    es: [
      '',
      'uno',
      'dos',
      'tres',
      'cuatro',
      'cinco',
      'seis',
      'siete',
      'ocho',
      'nueve',
      'diez',
      'once',
      'doce',
      'trece',
      'catorce',
      'quince',
      'dieciséis',
      'diecisiete',
      'dieciocho',
      'diecinueve',
    ],
    en: [
      '',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
      'seventeen',
      'eighteen',
      'nineteen',
    ],
  };
  let b: any = {
    'pt-BR': [
      '',
      '',
      'vinte',
      'trinta',
      'quarenta',
      'cinquenta',
      'sessenta',
      'setenta',
      'oitenta',
      'noventa',
    ],
    es: [
      '',
      '',
      'veinte',
      'treinta',
      'cuarenta',
      'cinquenta',
      'sesenta',
      'setenta',
      'ochenta',
      'noventa',
    ],
    en: ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'],
  };
  let g: any = {
    'pt-BR': [
      '',
      'mil',
      'milhão',
      'bilhão',
      'trilhão',
      'quatrilhão',
      'quintilhão',
      'sextilhão',
      'septilhão',
      'octilhão',
      'nonilhão',
    ],
    es: [
      '',
      'mil',
      'millón',
      'mil millones',
      'billón',
      'cuatrillón',
      'trillón',
      'sextillón',
      'septillón',
      'octillón',
      'noilion',
    ],
    en: [
      '',
      'thousand',
      'million',
      'billion',
      'trillion',
      'quadrillion',
      'quintillion',
      'sextillion',
      'septillion',
      'octillion',
      'nonilion',
    ],
  };

  let makeGroup = ([ones, tens, huns]: any) => {
    return [
      num(huns) === 0
        ? ''
        : a[language][huns] +
          (language === 'es' ? ' centenar' : language === 'en' ? ' hundred' : ' cem '),
      num(ones) === 0 ? b[language][tens] : (b[language][tens] && b[language][tens] + '-') || '',
      a[language][tens + ones] || a[language][ones],
    ].join('');
  };

  let thousand = (group: any, i: any) => (group === '' ? group : `${group} ${g[language][i]}`);

  if (typeof n === 'number') return numToWords(String(n), language);
  if (n === '0') return language === 'es' ? ' cero' : 'zero';
  return comp(chunk(3))(reverse)(arr(n))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(' ');
}
