// @ts-nocheck
import axios, { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  bag,
  invalidSession,
  rappiStoreMenu,
  simulateConfirmCreditCardPaymentLink,
  simulateConfirmPixPaymentLink,
} from '~/routes/routeMap';
import { apiRappiTurbo } from '~/services/api';
import { onlyNumbers } from '~/utils/masks';
import webVersion from '~/web-version';

import { BagStateType, Creators as BagCreators } from '../ducks/bag';
import {
  Creators as RappiTurboCreators,
  OnGetAuthData,
  OnGetProductToppings,
  OnInitializateCheckout,
  OnUpdateClientAddress,
  RappiTurboStateType,
  RappiTurboTypes,
} from '../ducks/rappiTurbo';

const {
  setAuthData,
  setStoreData,
  setProductToppings,
  setLoading,
  setInvalidSession,

  updateClientAddressSuccess,
  updateClientAddressFailure,
  initializateCheckoutSuccess,
  initializateCheckoutFailure,
  generatePaymentSuccess,
  generatePaymentFailure,
} = RappiTurboCreators;

const { cleanBag, setSummaryData, setAssembledBag } = BagCreators;

const rappiTurbo = (state) => state.rappiTurbo;

export function* getAuthData({ sessionId }: OnGetAuthData) {
  try {
    const response: AxiosResponse = yield call(apiRappiTurbo.get, `/rappi/${sessionId}/auth`);

    if (webVersion !== response.headers['web-version']) {
      setTimeout(() => {
        document.location.reload();
      }, 200);
    }
    const rappiAuthResponse = response.data as RappiAuthResponse;
    yield put(setAuthData(sessionId, rappiAuthResponse));

    if (rappiAuthResponse?.bag && rappiAuthResponse?.bag?.length > 0) {
      yield put(setAssembledBag(rappiAuthResponse.bag));
      yield call(getStoreData, { redirectToMenu: true });
      yield put(push(bag));
    } else {
      yield call(getStoreData, { redirectToMenu: true });
    }
  } catch (err: any) {
    yield put(cleanBag());
    yield put(setInvalidSession(err?.response?.data));
    yield put(push(invalidSession));
  }
}

export function* updateClientAddress({ confirmedAddress }: OnUpdateClientAddress) {
  const rappiTurboData: RappiTurboStateType = yield select(rappiTurbo);
  try {
    const clientAddress = rappiTurboData?.client?.address;

    const response: AxiosResponse = yield call(
      apiRappiTurbo.put,
      `/rappi/${rappiTurboData?.sessionId}/address`,
      {
        id: clientAddress?.id,
        street: clientAddress?.street,
        number: confirmedAddress?.number,
        complement: confirmedAddress?.complement,
        neighborhood: clientAddress?.neighborhood,
        city: clientAddress?.city,
        state: clientAddress?.state,
        zip_code: onlyNumbers(clientAddress?.zip_code),
        latitude: clientAddress?.latitude,
        longitude: clientAddress?.longitude,
      },
    );
    const rappiUpdateAddressResponse = response.data as Address;

    yield put(updateClientAddressSuccess(rappiUpdateAddressResponse));
  } catch (err: any) {
    if ([401, 404].includes(err.response?.status)) {
      yield put(cleanBag());
      yield put(setInvalidSession(err.response?.data));
      yield put(push(invalidSession));
    } else {
      yield put(updateClientAddressFailure());
      toast.error(translate.getText('error_saving_address'));
    }
  }
}

export function* initializateCheckout({ checkoutData }: OnInitializateCheckout) {
  try {
    const rappiTurboData: RappiTurboStateType = yield select(rappiTurbo);
    const bag = (state) => state.bag;
    const bagData: BagStateType = yield select(bag);
    const client = rappiTurboData?.client;

    const response: AxiosResponse = yield call(
      apiRappiTurbo.post,
      `/rappi/${rappiTurboData?.sessionId}/checkout`,
      {
        client: {
          id: client?.id,
          first_name: checkoutData?.client?.first_name,
          surname: checkoutData?.client?.surname,
          identification: onlyNumbers(checkoutData?.client?.identification),
          email: checkoutData?.client?.email,
        },
        products: checkoutData?.products,
        bag: {
          sizeBag: bagData.sizeBag,
          itemsInBag: bagData.itemsInBag,
          priceWithoutDiscount: Math.round(bagData.priceWithoutDiscount * 100),
          deliveryFee: Math.round(bagData.deliveryFee * 100),
          serviceFee: Math.round(bagData.serviceFee * 100),
        },
      },
    );
    const rappiInitializateCheckoutResponse = response.data;

    let deliveryFee = rappiInitializateCheckoutResponse?.delivery_fee;
    let serviceFee = rappiInitializateCheckoutResponse?.service_fee;

    yield put(setSummaryData(deliveryFee, serviceFee));
    yield put(
      initializateCheckoutSuccess({
        first_name: checkoutData?.client?.first_name,
        surname: checkoutData?.client?.surname,
        identification: onlyNumbers(checkoutData?.client?.identification),
        email: checkoutData?.client?.email,
      }),
    );
  } catch (err: any) {
    if ([401, 404].includes(err.response?.status)) {
      yield put(cleanBag());
      yield put(setInvalidSession(err.response?.data));
      yield put(push(invalidSession));
    } else {
      yield put(initializateCheckoutFailure());
      toast.error(translate.getText('error_saving_user_data'));
    }
  }
}

export function* generatePayment({ paymentData }: OnGeneratePayment) {
  try {
    const rappiTurboData: RappiTurboStateType = yield select(rappiTurbo);

    const response: AxiosResponse = yield call(
      apiRappiTurbo.post,
      `/rappi/${rappiTurboData?.sessionId}/payment`,
      {
        payment_method: paymentData?.payment_method,
        products: paymentData?.products,
      },
    );
    const rappiGeneratePaymentResponse = response.data;

    if (rappiGeneratePaymentResponse?.payment_link === 'NOT_IMPLEMENTED_PAYMENT_LINK') {
      if (paymentData?.payment_method === 'pix') {
        yield put(push(simulateConfirmPixPaymentLink));
      } else {
        yield put(push(simulateConfirmCreditCardPaymentLink));
      }
    } else {
      window.open(rappiGeneratePaymentResponse?.payment_link, '_self');
    }

    yield put(generatePaymentSuccess());
  } catch (err: any) {
    if ([401, 404].includes(err.response?.status)) {
      yield put(cleanBag());
      yield put(setInvalidSession(err.response?.data));
      yield put(push(invalidSession));
    } else {
      yield put(generatePaymentFailure());
      toast.error(translate.getText('error_generating_payment'));
    }
  }
}

export function* createRappiAPI() {
  const rappiTurboData: RappiTurboStateType = yield select(rappiTurbo);

  // Create Axios for Rappi API
  return axios.create({
    baseURL: `https://${rappiTurboData?.endpoint}/api/web-gateway/web/restaurants-bus`,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      common: {
        Authorization: `${rappiTurboData?.token}`,
      },
    },
  });
}

export function* getStoreData({ redirectToMenu, rappiStoreId }): OnGetStoreData {
  const rappiTurboData: RappiTurboStateType = yield select(rappiTurbo);
  try {
    // Create Axios for Rappi API
    const apiRappi = yield call(createRappiAPI, {});

    // TODO: REMOVE THIS VALIDATION WHEN TEST IS DONE
    let latitude = -23.5996675;
    let longitude = -46.6880061;
    if (
      rappiTurboData?.client?.language === 'es' ||
      (rappiStoreId && Number(rappiStoreId) === Number(900006508))
    ) {
      latitude = 4.699515432948519;
      longitude = -74.0809499233872;
    }

    const response: AxiosResponse = yield call(
      apiRappi.post,
      `/store/id/${rappiStoreId ? rappiStoreId : rappiTurboData?.store?.id}/`,
      {
        lat: latitude,
        lng: longitude,
        store_type: 'restaurant',
        is_prime: false,
        prime_config: {
          unlimited_shipping: false,
        },
      },
    );

    const rappiCatalogData = response.data;
    yield put(setStoreData(rappiCatalogData));

    if (redirectToMenu) {
      const bag = (state) => state.bag;
      const bagData: BagStateType = yield select(bag);
      if (bagData.itemsInBag.length === 0) {
        yield put(push(rappiStoreMenu));
      }
    }
  } catch (err: any) {
    yield put(cleanBag());
    yield put(setInvalidSession(rappiTurboData?.store));
    yield put(push(invalidSession));
  }
}

export function* getProductToppings({ categoryId, productId }: OnGetProductToppings) {
  const rappiTurboData: RappiTurboStateType = yield select(rappiTurbo);

  try {
    yield put(setLoading(true));

    // Create Axios for Rappi API
    const apiRappi = yield call(createRappiAPI, {});

    const response: AxiosResponse = yield call(
      apiRappi.get,
      `/products/toppings/${rappiTurboData?.store?.id}/${productId}/`,
    );
    const rappiProductToppingsData = response.data?.categories;
    yield put(setProductToppings(Number(categoryId), Number(productId), rappiProductToppingsData));
  } catch (err: any) {
    yield put(cleanBag());
    yield put(setInvalidSession(rappiTurboData?.store));
    yield put(push(invalidSession));
  } finally {
    yield put(setLoading(false));
  }
}

export default all([
  takeLatest(RappiTurboTypes.GET_AUTH_DATA, getAuthData),
  takeLatest(RappiTurboTypes.GET_STORE_DATA, getStoreData),
  takeLatest(RappiTurboTypes.UPDATE_CLIENT_ADDRESS, updateClientAddress),
  takeLatest(RappiTurboTypes.INITIALIZATE_CHECKOUT, initializateCheckout),
  takeLatest(RappiTurboTypes.GENERATE_PAYMENT, generatePayment),
  takeLatest(RappiTurboTypes.GET_PRODUCT_TOPPINGS, getProductToppings),
]);
