export const en: any = {
  store_name: 'from Rappi',
  delivery_time: 'Delivery',
  estimated_delivery_fee: 'Fee',
  selled_delivery_rappi_img: '/images/selled_delivery_text_en.png',
  closed_delivery: 'Closed Delivery',
  empty_catalog: 'Empty catalog',
  not_created_catalog: 'The catalog has not been created or there are no active categories.',
  pro_free: 'Free',
  pro_discount: 'Discount with Pro',
  bag_size: 'Product',
  product_detail_title: 'Product Details',
  topping_required_max_more_than_one: '@currQty@ of @maxQty@ options',
  topping_required_max_more_than_one_min_obs_one: 'Minimum one option',
  topping_required_max_more_than_one_min_obs_two: 'Minimum two options',
  topping_required_max_more_than_one_min_obs_more: 'Minimal @option@ options',
  topping_not_required_max_more_than_one: 'Up to @maxQty@ options',
  topping_required_max_one: 'Select an option',
  topping_not_required_max_one: 'Even an option',
  required: 'Required',
  add_item: 'Add',
  update_item: 'Update',
  delete_item: 'Delete',
  see_details: 'View item details',
  go_to_bag_button: 'View bag',
  product_search_title: 'Products search',
  product_search_input: 'Enter the product to be searched',
  product_search_not_found: 'There are no products for this search.',
  bag_title: 'Your Bag',
  bag_add_more_items: 'Add more items',
  bag_clean: 'Clear',
  required_complements_info: 'Click to view add-ons',
  sub_total: 'Subtotal',
  total: 'Total',
  continue_button: 'Continue',
  conf_address_title: 'Confirm your address',
  conf_address_subtitle: 'Your order will be delivery to:',
  number: 'Number',
  complement: 'Apartment/etc.',
  not_your_address: 'Not your address? Click here',
  confirm_address_button: 'Confirm address',
  address_title: 'Delivery Address',
  address_not_found: 'No addresses were found with the data provided',
  address_with_number: 'Enter your address with number',
  address_you_are_here: 'You are here',
  save_address_button: 'Save address',
  not_deliver_at_address: 'Unfortunately, we do not yet deliver to this address',
  select_another_address_button: 'Please select another address',
  changed_deliver_store:
    'We`ve changed the store that will handle your delivery due to your change of address. Please pack your bag again',
  assemble_bag_button: 'Prepare the order',
  order_summary_title: 'Order Summary',
  delivery_to: 'Deliver to',
  your_order: 'Your order',
  delivery_fee: 'Delivery fee',
  service_fee: 'Service fee',
  discount: 'Discount',
  conf_user_data_title: 'Confirm your information',
  conf_user_data_subtitle: 'Please confirm the data to finalize the order',
  name: 'First Name',
  surname: 'Last Name',
  email: 'Email',
  identification_number: 'Social Security Number (SSN)',
  confirm_button: 'Confirm',
  payment_title: 'How would you like to pay?',
  payment_subtitle: 'You will be redirected for payment',
  credit_card: 'Credit Card',
  payment_page_title: 'Payment',
  expired_title: 'Expired session',
  expired_session_title: 'Your session has expired',
  expired_session_text:
    'Don`t worry, if you want to place a new order just send a message to our number on WhatsApp',
  go_back_to_whatsapp: 'Return to WhatsApp',
  tracking_title: 'Tracking',
  tracking_loading_error_title: 'Error loading tracking information',
  tracking_loading_error_text:
    'Don`t worry, reload the page to try again, or click the button below to return to WhatsApp.',
  turbo_delivery_time_info: 'Your order will arrive in',
  turbo_delivery_time_delivered_info: 'Your order was delivered in',
  turbo_delivery_time_min: 'Minutes',
  turbo_delivery_time_sec: 'Seconds',
  turbo_status_tab: 'Status',
  turbo_details_tab: 'Details',
  turbo_delivery_man_tab: 'Courier',
  turbo_confirmed_at: 'Confirmed at @confirmedHour@',
  turbo_delivered_at: 'Delivered at @deliveredHour@',
  turbo_order_active: 'Your order is active',
  turbo_order_assigned_to_partner: 'Order in preparation',
  turbo_order_taken_visible_order: 'Courier on his way to the store',
  turbo_order_domiciliary_in_store: 'Courier has arrived at McDonald`s',
  turbo_order_hand_to_domiciliary: 'Order has left for delivery',
  turbo_order_close_order: 'Your order has been delivered',
  turbo_order_summary: 'Order Summary',
  turbo_help: 'Help',
  turbo_help_title: 'Chat with Support',
  // Errors
  error_selected_address_gps: 'The selected location is too far from the address provided',
  error_searching_address: 'An error occurred while retrieving your address',
  error_selected_address: 'An error occurred when selecting this address',
  error_saving_address: 'Unable to save your address',
  error_saving_user_data: 'Unable to save your data',
  error_generating_payment: 'Unable to generate payment',
  error_confirming_payment: 'Unable to confirm payment',
  // Validations
  validation_user_first_name_invalid: 'Your first name must have a maximum of 40 characters.',
  validation_user_first_name_empty: 'Please provide your first name.',
  validation_user_surname_name_invalid: 'Your last name must have a maximum of 60 characters.',
  validation_user_surname_name_empty: 'Please provide your last name.',
  validation_user_email_invalid: 'The email provided is invalid.',
  validation_user_email_empty: 'Please provide your email.',
  validation_user_identification_invalid: 'The SSN provided is invalid.',
  validation_user_identification_empty: 'Please provide your SSN.',
  validation_address_number_invalid: 'The number must have up to 10 characters.',
  validation_address_number_empty: 'Please provide the address number.',
  validation_address_complement_invalid:
    'The additional information must have up to 60 characters.',
  validation_address_neighborhood_invalid: 'The neighborhood must have up to 60 characters.',
  validation_address_neighborhood_empty: 'Please provide your neighborhood.',
  validation_address_zipcode_invalid: 'Provide a valid ZIP code.',
  validation_address_zipcode_empty: 'Please provide your ZIP code.',
  validation_payment_cardholder_name_invalid:
    'The cardholder`s name must have a maximum of 40 characters.',
  validation_payment_cardholder_name_empty: 'Please enter the cardholder`s name.',
  validation_payment_card_number_invalid_min:
    'The card number must have a minimum of 14 characters.',
  validation_payment_card_number_invalid_max:
    'The card number must have a maximum of 16 characters.',
  validation_payment_card_number_empty: 'Please provide the card number.',
  validation_payment_card_cvv_invalid_min: 'The card`s CVV must have a minimum of 3 characters.',
  validation_payment_card_cvv_invalid_max: 'The card`s CVV must have a maximum of 4 characters.',
  validation_payment_card_cvv_empty: 'Please enter the card`s CVV.',
  validation_payment_card_validity_invalid: 'The card`s validity must be in the format: MM/YY.',
  validation_payment_card_validity_empty: 'Please enter the card`s validity.',
  validation_payment_card_validity_expired: 'Card`s expiration date expired.',
  // Simulations
  simulate_cc_payment_client_name: 'Hello, @name@!',
  simulate_cc_payment_info: 'Please check the value of your order.',
  simulate_cc_payment_obs: 'Fill your details in the fields below to make the payment.',
  simulate_cc_payment_card_number: 'Card number',
  simulate_cc_payment_card_owner_name: 'Cardholder`s name',
  simulate_cc_payment_card_validity: 'Expiration date',
  simulate_cc_payment_card_cvv: 'CVV',
  simulate_cc_payment_make_payment: 'Confirm payment',
  simulate_cc_payment_success: 'Payment successful!',
  simulate_cc_payment_success_obs: 'Thank you, we have received your payment.',
  simulate_pix_payment_obs: 'Click the button below to simulate making a payment via Pix.',
};
