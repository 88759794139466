import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/core';
import { Text } from '@chakra-ui/core';
import React from 'react';

import Button from '../../../../components/Button';

interface UnsupportedAddressModalProps {
  headingText: string;
  buttonText: string;
  isOpen: boolean;
  onClose: () => void;
  onClickAction?: () => void;
}

const UnsupportedAddressModal: React.FC<UnsupportedAddressModalProps> = ({
  headingText,
  buttonText,
  isOpen,
  onClose,
  onClickAction,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent p={8} borderRadius="2rem">
        <ModalBody mb={3}>
          <Heading as="h4" fontWeight="700" fontSize="lg">
            {headingText}
          </Heading>
        </ModalBody>
        <ModalFooter justifyContent="flex-start">
          <Button
            mt="1rem"
            px="3rem"
            borderRadius="50px"
            bg="green.300"
            type="submit"
            w="100%"
            py={8}
            onClick={onClickAction ? onClickAction : onClose}
          >
            <Text color="white" fontSize="lg">
              {buttonText}
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UnsupportedAddressModal;
