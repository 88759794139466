import { Button } from '@chakra-ui/core';
import { Box, Image, Text } from '@chakra-ui/core';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import useTranslate from '~/hooks/useTranslate';
import DeliveryGuy from '~/pages/InvalidSession/DeliveryGuy';
import { RootState } from '~/store';
import RealTimeDimensions from '~/utils/realTimeDimensions';

const InvalidSession: React.FC = () => {
  const globalState = useSelector((state: RootState) => state);
  const lastRappiData = globalState?.rappiTurbo?.lastRappiData;
  const translate = useTranslate(lastRappiData?.client?.language);
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;

  const goBackWhatsapp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?phone=${lastRappiData?.store?.phone}`,
      isMobile ? '_top' : '_blank',
    );
  }, [lastRappiData?.store?.phone]);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{`${translate.getText('expired_title')} - ${
          lastRappiData?.store?.name ? lastRappiData?.store?.name : 'Rappi Turbo'
        }`}</title>
      </Helmet>
      <Box
        as="main"
        d="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        pt={20}
        px={10}
      >
        <Box d="flex" flexDir="row" mb={20}>
          <Image
            src={'/images/logo.jpg'}
            backgroundColor="white"
            h={50}
            w={50}
            borderRadius="50%"
            objectFit="cover"
          />
          <Text fontSize="2xl" textAlign="center" fontWeight={700} mt={3} ml={5}>
            {lastRappiData?.store?.name ? lastRappiData?.store?.name : 'Rappi Turbo'}
          </Text>
        </Box>
        <DeliveryGuy />
        <Text fontSize="2xl" textAlign="center" fontWeight={700} mt={10} mb={5}>
          {translate.getText('expired_session_title')}
        </Text>
        <Text fontSize="lg" textAlign="center">
          {translate.getText('expired_session_text')}
        </Text>
        {lastRappiData?.store?.phone && (
          <Box d="flex" w="100%" py={4} mt={10} alignSelf={'center'}>
            <Button
              px="3rem"
              borderRadius="50px"
              bg="green.300"
              variantColor="green.300"
              type="button"
              w="100%"
              py="30px"
              onClick={goBackWhatsapp}
            >
              <Text color="white" fontSize="lg">
                {translate.getText('go_back_to_whatsapp')}
              </Text>
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default InvalidSession;
