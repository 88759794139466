export const es: any = {
  store_name: 'de Rappi',
  delivery_time: 'Entrega',
  estimated_delivery_fee: 'Costo de Envío',
  selled_delivery_rappi_img: '/images/selled_delivery_text_es.png',
  closed_delivery: 'Entrega Cerrada',
  empty_catalog: 'Catálogo vacío',
  not_created_catalog: 'El catálogo no ha sido creado o no existen categorías activas.',
  pro_free: 'Gratis',
  pro_discount: 'Descuento con Pro',
  bag_size: 'Producto',
  product_detail_title: 'Detalles del Producto',
  required: 'Obligatorio',
  topping_required_max_more_than_one: '@currQty@ de @maxQty@ opciones',
  topping_required_max_more_than_one_min_obs_one: 'Mínimo una opción',
  topping_required_max_more_than_one_min_obs_two: 'Mínimo dos opciones',
  topping_required_max_more_than_one_min_obs_more: 'Mínimo @option@ opciones',
  topping_not_required_max_more_than_one: 'Hasta @maxQty@ opciones',
  topping_required_max_one: 'Seleccione una opción',
  topping_not_required_max_one: 'Incluso una opción',
  add_item: 'Agregar',
  update_item: 'Actualizar',
  delete_item: 'Eliminar',
  see_details: 'Ver detalles del ítem',
  go_to_bag_button: 'Ir a cesta',
  product_search_title: 'Búsqueda de productos',
  product_search_input: 'Ingresa el producto a buscar',
  product_search_not_found: 'No existen productos para esta búsqueda.',
  bag_title: 'Su Cesta',
  bag_add_more_items: 'Agregar más ítems',
  bag_clean: 'Limpiar',
  required_complements_info: 'Haz clic para ver complementos',
  sub_total: 'Subtotal',
  total: 'Total',
  continue_button: 'Continuar',
  conf_address_title: 'Confirme tu dirección',
  conf_address_subtitle: 'Tu pedido será entregado en:',
  number: 'Número',
  complement: 'Complemento',
  not_your_address: '¿Esta no es tu dirección? Haz clic aquí',
  confirm_address_button: 'Confirmar dirección',
  address_title: 'Dirección de Entrega',
  address_not_found: 'No se encontraron direcciones con los datos informados',
  address_with_number: 'Ingrese su dirección con número',
  address_you_are_here: 'Usted está aquí',
  save_address_button: 'Guardar dirección',
  not_deliver_at_address: 'Lamentablemente aún no realizamos entregas en esta dirección',
  select_another_address_button: 'Selecciona otra dirección',
  changed_deliver_store:
    'Cambiamos la tienda que hará la entrega debido a su cambio de dirección. Por favor, arme de nuevo su bolsa',
  assemble_bag_button: 'Armar el pedido',
  order_summary_title: 'Resumen del pedido',
  delivery_to: 'Entregar en',
  your_order: 'Tu pedido',
  delivery_fee: 'Tarifa de entrega',
  service_fee: 'Tarifa de servicio',
  discount: 'Descuento',
  conf_user_data_title: 'Confirme tus datos',
  conf_user_data_subtitle: 'Confirme los datos para finalizar el pedido',
  name: 'Nombre',
  surname: 'Apellido',
  email: 'Correo electrónico',
  identification_number: 'Número de Identificación',
  confirm_button: 'Confirmar',
  payment_title: '¿Cómo desea realizar el pago?',
  payment_subtitle: 'Serás redirigido para el pago',
  credit_card: 'Tarjeta de Crédito',
  payment_page_title: 'Pago',
  expired_title: 'Sesión expirada',
  expired_session_title: 'Tu sesión ha expirado',
  expired_session_text:
    'No te preocupes, si deseas hacer un nuevo pedido solo envía un mensaje a nuestro número en WhatsApp',
  go_back_to_whatsapp: 'Volver a WhatsApp',
  tracking_title: 'Rastreo',
  tracking_loading_error_title: 'Error al cargar la información de seguimiento',
  tracking_loading_error_text:
    'No te preocupes, recarga la página para intentar de nuevo, o haz clic en el botón abajo para volver a WhatsApp.',
  turbo_delivery_time_info: 'Su pedido llegará en',
  turbo_delivery_time_delivered_info: 'Su pedido fue entregado en',
  turbo_delivery_time_min: 'Minutos',
  turbo_delivery_time_sec: 'Segundos',
  turbo_status_tab: 'Estado',
  turbo_details_tab: 'Detalles',
  turbo_delivery_man_tab: 'Entregador',
  turbo_confirmed_at: 'Confirmado a las @confirmedHour@',
  turbo_delivered_at: 'Entregado a las @deliveredHour@',
  turbo_order_active: 'Su pedido está activo',
  turbo_order_assigned_to_partner: 'Pedido en preparación',
  turbo_order_taken_visible_order: 'Entregador yendo a buscar el pedido',
  turbo_order_domiciliary_in_store: 'Entregador llegó al McDonalds',
  turbo_order_hand_to_domiciliary: 'Pedido salió para la entrega',
  turbo_order_close_order: 'Tu pedido ha sido entregado',
  turbo_order_summary: 'Resumen del pedido',
  turbo_help: 'Ayuda',
  turbo_help_title: 'Chat con el Soporte',
  // Errors
  error_selected_address_gps: 'El lugar seleccionado está muy lejos de la dirección informada',
  error_searching_address: 'Ocurrió un error al buscar su dirección',
  error_selected_address: 'Ocurrió un error al seleccionar esta dirección',
  error_saving_address: 'No fue posible guardar su dirección',
  error_saving_user_data: 'No fue posible guardar sus datos',
  error_generating_payment: 'No fue posible generar el pago',
  error_confirming_payment: 'No fue posible confirmar el pago',
  // Validations
  validation_user_first_name_invalid: 'Su primer nombre debe tener como máximo 40 caracteres.',
  validation_user_first_name_empty: 'Por favor, informe su primer nombre.',
  validation_user_surname_name_invalid: 'Su apellido debe tener como máximo 60 caracteres.',
  validation_user_surname_name_empty: 'Por favor, informe su apellido.',
  validation_user_email_invalid: 'El correo electrónico informado es inválido.',
  validation_user_email_empty: 'Por favor, informe su correo electrónico.',
  validation_user_identification_invalid: 'El identificación informado es inválido.',
  validation_user_identification_empty: 'Por favor, informe su identificación.',
  validation_address_number_invalid: 'El número debe tener hasta 10 caracteres.',
  validation_address_number_empty: 'Por favor, informe el número de la dirección.',
  validation_address_complement_invalid: 'El complemento debe tener hasta 60 caracteres.',
  validation_address_neighborhood_invalid: 'Por favor, informe su barrio.',
  validation_address_neighborhood_empty: 'El barrio debe tener hasta 60 caracteres.',
  validation_address_zipcode_invalid: 'Por favor, informe su Código Postal.',
  validation_address_zipcode_empty: 'Informe un Código Postal válido.',
  validation_payment_cardholder_name_invalid:
    'El nombre del titular de la tarjeta debe tener máximo 40 caracteres.',
  validation_payment_cardholder_name_empty:
    'Por favor informe el nombre del titular de la tarjeta.',
  validation_payment_card_number_invalid_min:
    'El número de la tarjeta debe tener mínimo 14 caracteres.',
  validation_payment_card_number_invalid_max:
    'El número de la tarjeta debe tener máximo 16 caracteres.',
  validation_payment_card_number_empty: 'Por favor, informe el número de la tarjeta.',
  validation_payment_card_cvv_invalid_min: 'El CVV de la tarjeta debe tener mínimo 3 caracteres.',
  validation_payment_card_cvv_invalid_max: 'El CVV de la tarjeta debe tener máximo 4 caracteres.',
  validation_payment_card_cvv_empty: 'Por favor informe el CVV de la tarjeta.',
  validation_payment_card_validity_invalid:
    'La validez de la tarjeta debe estar en el formato: MM/AA.',
  validation_payment_card_validity_empty: 'Por favor informe la validez de la tarjeta.',
  validation_payment_card_validity_expired: 'Fecha de validez de la tarjeta expirada.',
  // Simulations
  simulate_cc_payment_client_name: 'Hola, @name@!',
  simulate_cc_payment_info: 'Verifique el valor de su pedido y realice el pago.',
  simulate_cc_payment_obs: 'Llene con sus datos los campos a continuación para realizar el pago.',
  simulate_cc_payment_card_number: 'Número de la tarjeta',
  simulate_cc_payment_card_owner_name: 'Nombre del titular',
  simulate_cc_payment_card_validity: 'Vencimiento',
  simulate_cc_payment_card_cvv: 'CVV',
  simulate_cc_payment_make_payment: 'Realizar pago',
  simulate_cc_payment_success: '¡Pago realizado con éxito!',
  simulate_cc_payment_success_obs: 'Gracias, hemos recibido su pago.',
  simulate_pix_payment_obs:
    'Haz clic en el botón de abajo para simular que estás realizando el pago por Pix.',
};
