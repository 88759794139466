import { ButtonProps, Text } from '@chakra-ui/core';
import React from 'react';

import Button from '~/components/Button';

interface CategoryButtonProps extends ButtonProps {
  currentCategory: boolean;
}

const CategoryButton: React.FC<CategoryButtonProps> = ({
  children,
  currentCategory,
  ref,
  ...rest
}) => (
  <Button
    variant="ghost"
    rounded={0}
    py={0}
    flex="0 0 auto"
    _focus={undefined}
    _hover={undefined}
    _active={{ backgroundColor: 'transparent' }}
    {...rest}
  >
    <Text
      as="span"
      fontWeight="700"
      fontSize="lg"
      d="flex"
      alignItems="center"
      justifyContent="center"
      color={!currentCategory && 'black'}
      boxShadow={currentCategory && 'inset 0px -3px 0px 0px #2CFE88'}
      h="50px"
      transition="all 0.2s ease-in-out"
    >
      {children}
    </Text>
  </Button>
);

export default CategoryButton;
