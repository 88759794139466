import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import Input from '~/components/Form/Input';
import GoBackButton from '~/components/GoBackButton';
import useTranslate from '~/hooks/useTranslate';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import { AddressComponent } from '~/models/AutoComplete';
import confirmAddressSchema from '~/models/schemas/confirmAddressSchema';
import { RootState } from '~/store';
import { AddressFields, getFieldFromAddressComponents } from '~/utils/address';
import { zipcodeMask } from '~/utils/masks';

export interface ConfirmPredictionFormProps extends StackProps {
  onCloseBottomBar: () => void;
  loading: boolean;
  onSubmitSelectedPrediction: (data: ConfirmAddressFields) => Promise<void>;
  selectedPrediction?: {
    mainText: string;
    secondaryText: string;
  };
  selectedAddressComponents?: AddressComponent[];
}

interface ConfirmAddressFields {
  number?: string;
  complement?: string;
  neighborhood?: string;
  zip_code?: string;
}

const ConfirmPredictionForm: React.FC<ConfirmPredictionFormProps> = ({
  onCloseBottomBar,
  loading,
  onSubmitSelectedPrediction,
  selectedPrediction,
  selectedAddressComponents,
}) => {
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const [shouldValidateZipcode, setShouldValidateZipcode] = useState<boolean>(true);
  const { register, errors, handleSubmit, setValue } = useForm<ConfirmAddressFields>({
    resolver: useYupValidationResolver(confirmAddressSchema(shouldValidateZipcode, translate)),
  });
  const [hiddenFields, setHiddenFields] = useState<Array<string>>([]);

  const isFieldHidden = useCallback(
    (field: string) => {
      return hiddenFields.includes(field);
    },
    [hiddenFields],
  );

  const toggleField = useCallback((value: string, field: string, hiddenFieldsList: string[]) => {
    if (value.trim() !== '') {
      hiddenFieldsList = hiddenFieldsList.concat([field]);
    } else {
      hiddenFieldsList = hiddenFieldsList.filter((item) => item !== field);
    }
    return hiddenFieldsList;
  }, []);

  useEffect(() => {
    const number = getFieldFromAddressComponents(AddressFields.NUMBER, selectedAddressComponents);
    setValue('number', number);
    const zipcode = getFieldFromAddressComponents(AddressFields.ZIPCODE, selectedAddressComponents);
    setValue('zip_code', zipcode);
    // Google returned zipcode, hide zipcode input and don't validate using RegEx
    if (zipcode.trim() !== '') {
      setShouldValidateZipcode(false);
    }

    const neighborhood = getFieldFromAddressComponents(
      AddressFields.NEIGHBORHOOD,
      selectedAddressComponents,
    );
    setValue('neighborhood', neighborhood);

    setHiddenFields((prevHiddenFields) => {
      prevHiddenFields = toggleField(neighborhood, 'neighborhood', prevHiddenFields);
      prevHiddenFields = toggleField(zipcode, 'zip_code', prevHiddenFields);
      return prevHiddenFields;
    });
    // Hide zipcode field if it is returned from google
  }, [selectedAddressComponents, toggleField, setValue]);

  return (
    <BottomBar isOverlayVisible onClose={onCloseBottomBar} py={5} px={2}>
      <Box d="flex" w="100%" py={4} flexDirection="column">
        <Box
          as="form"
          d="flex"
          flexDir="column"
          onSubmit={handleSubmit(onSubmitSelectedPrediction)}
        >
          <Box d="flex" flexDir="row" pb="20px" pl={3} borderBottom={'1px solid #ECEFF3'} mb={5}>
            <GoBackButton onClick={onCloseBottomBar} />
            <Text fontSize="1.8rem" fontWeight="700" alignSelf="center" pt={1} pl="3.5rem">
              {translate.getText('conf_address_title')}
            </Text>
          </Box>
          <Text
            fontSize="1.5rem"
            fontWeight="500"
            color="gray.300"
            py={2}
            pb={5}
            alignSelf="center"
          >
            {translate.getText('conf_address_subtitle')}
          </Text>
          <Text fontSize="1.8rem" fontWeight="700" pb={1} alignSelf="center">
            {selectedPrediction?.mainText}
          </Text>
          <Text fontSize="1.5rem" fontWeight="500" pb={5} alignSelf="center">
            {selectedPrediction?.secondaryText}
          </Text>
          <Box d="flex" flexDir={'row'} mt={5} justifyContent="center">
            <FormControl mb={6} w="28%" mr={5} isInvalid={!!errors.number}>
              <FormLabel htmlFor="number" fontWeight="700" fontSize="1.5rem">
                {translate.getText('number')}
              </FormLabel>
              <InputGroup>
                <Input
                  placeholder="123"
                  id="number"
                  name="number"
                  type="text"
                  register={register()}
                />
                {!!errors.number && (
                  <InputRightElement h="50px" w="50px">
                    <Tooltip
                      aria-label="Erro."
                      placement="top"
                      hasArrow
                      label={errors.number?.message}
                      bg="red.400"
                      zIndex={1500}
                    >
                      <IconButton
                        aria-label="Erro."
                        icon="warning"
                        color="red.300"
                        _focus={undefined}
                      />
                    </Tooltip>
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
            <FormControl mb={6} w="45%" isInvalid={!!errors.complement}>
              <FormLabel htmlFor="complement" fontWeight="700" fontSize="1.5rem">
                {translate.getText('complement')}
              </FormLabel>
              <InputGroup>
                <Input
                  placeholder="Casa 2"
                  id="complement"
                  name="complement"
                  type="text"
                  register={register()}
                />
                {!!errors.complement && (
                  <InputRightElement h="50px" w="50px">
                    <Tooltip
                      aria-label="Erro."
                      placement="top"
                      hasArrow
                      label={errors.complement?.message}
                      bg="red.400"
                      zIndex={1500}
                    >
                      <IconButton
                        aria-label="Erro."
                        icon="warning"
                        color="red.300"
                        _focus={undefined}
                      />
                    </Tooltip>
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
          </Box>
          <Box d="flex">
            <FormControl
              isInvalid={!!errors.neighborhood}
              d={isFieldHidden('neighborhood') && 'none'}
              mr={!isFieldHidden('zip_code') && 4}
              w="100%"
            >
              <FormLabel fontSize="1.5rem" fontWeight="700" htmlFor="neighborhood">
                Bairro
              </FormLabel>
              <InputGroup>
                <Input
                  register={register}
                  placeholder="Bairro"
                  name="neighborhood"
                  id="neighborhood"
                />
                {!!errors.neighborhood && (
                  <InputRightElement h="50px" w="50px">
                    <Tooltip
                      aria-label="Erro."
                      placement="top"
                      hasArrow
                      label={errors.neighborhood?.message}
                      bg="red.400"
                      zIndex={1500}
                    >
                      <IconButton
                        aria-label="Erro."
                        icon="warning"
                        color="red.300"
                        _focus={undefined}
                      />
                    </Tooltip>
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
            <FormControl
              isInvalid={!!errors.zip_code}
              d={isFieldHidden('zip_code') && 'none'}
              w="100%"
            >
              <FormLabel fontSize="1.5rem" fontWeight="700" htmlFor="zip_code">
                CEP
              </FormLabel>
              <InputGroup>
                <Input
                  register={register}
                  placeholder="CEP"
                  name="zip_code"
                  id="zip_code"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue('zip_code', zipcodeMask(e.target.value, true))
                  }
                />
                {!!errors.zip_code && (
                  <InputRightElement h="50px" w="50px">
                    <Tooltip
                      aria-label="Erro."
                      placement="top"
                      hasArrow
                      label={errors.zip_code?.message}
                      bg="red.400"
                      zIndex={1500}
                    >
                      <IconButton
                        aria-label="Erro."
                        icon="warning"
                        color="red.300"
                        _focus={undefined}
                      />
                    </Tooltip>
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
          </Box>
          <Button
            mt="3rem"
            px="3rem"
            borderRadius="50px"
            bg="green.300"
            type="submit"
            w="100%"
            py={8}
            isDisabled={loading}
            isLoading={loading}
          >
            <Text color="white" fontSize="lg">
              {translate.getText('confirm_address_button')}
            </Text>
          </Button>
        </Box>
      </Box>
    </BottomBar>
  );
};

export default ConfirmPredictionForm;
