import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import useTranslate from '~/hooks/useTranslate';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import { ConfirmAddressInputs } from '~/models/Address';
import confirmAddressBagSchema from '~/models/schemas/confirmAddressBagSchema';
import { searchAutoCompleteAddress } from '~/routes/routeMap';
import { RootState } from '~/store';

import Button from '../../../components/Button';
import Input from '../../../components/Form/Input';
import GoBackButton from '../../../components/GoBackButton';

interface ConfirmAddressFormProps {
  isVisible: boolean;
  canContinueWithOrder: boolean;
  onClickBack?: () => void;
  onClickConfirm?: () => void;
  onFormSubmit: (data: ConfirmAddressInputs) => void;
}

const ConfirmAddressForm: React.FC<ConfirmAddressFormProps> = ({
  isVisible,
  canContinueWithOrder,
  onFormSubmit,
  onClickBack,
  onClickConfirm,
}) => {
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const updateAddressLoading = rappiTurbo?.updateAddressLoading;
  const userAddress = rappiTurbo?.client?.address;
  const translate = useTranslate(rappiTurbo?.client?.language);

  const { register, handleSubmit, errors } = useForm<ConfirmAddressInputs>({
    resolver: useYupValidationResolver(confirmAddressBagSchema(translate)),
    defaultValues: {
      number: userAddress?.number,
      complement: userAddress?.complement,
    },
  });

  const handleOnClickConfirm: any = useCallback(() => {
    onClickConfirm && onClickConfirm();
    if (isVisible) {
      handleSubmit(onFormSubmit)();
    }
  }, [handleSubmit, onClickConfirm, onFormSubmit, isVisible]);

  return (
    <Box d="flex" w="100%" py={4} flexDirection="column">
      <Box as="form" d={isVisible ? 'flex' : 'none'} flexDir="column" pb={8}>
        <Box d="flex" flexDir="row" pb="20px" pl={3} borderBottom={'1px solid #ECEFF3'} mb={5}>
          <GoBackButton onClick={onClickBack} />
          <Text fontSize="1.8rem" fontWeight="700" alignSelf="center" pt={1} pl="3.5rem">
            {translate.getText('conf_address_title')}
          </Text>
        </Box>
        <Text fontSize="1.5rem" fontWeight="500" color="gray.300" py={2} pb={5} alignSelf="center">
          {translate.getText('conf_address_subtitle')}
        </Text>
        <Text fontSize="1.8rem" fontWeight="700" pb={1} alignSelf="center">
          {userAddress?.street}
        </Text>
        <Text fontSize="1.5rem" fontWeight="500" pb={5} alignSelf="center">
          {`${userAddress?.neighborhood ? `${userAddress?.neighborhood}, ` : ''}${
            userAddress?.city
          }`}
        </Text>
        <Box d="flex" flexDir={'row'} mt={5} justifyContent="center">
          <FormControl
            mb={6}
            w="28%"
            mr={5}
            isInvalid={!!errors.number}
            isDisabled={updateAddressLoading}
          >
            <FormLabel htmlFor="number" fontWeight="700" fontSize="1.5rem">
              {translate.getText('number')}
            </FormLabel>
            <InputGroup>
              <Input placeholder="123" name="number" type="text" register={register()} />
              {!!errors.number && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.number?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl
            mb={6}
            w="45%"
            isInvalid={!!errors.complement}
            isDisabled={updateAddressLoading}
          >
            <FormLabel htmlFor="complement" fontWeight="700" fontSize="1.5rem">
              {translate.getText('complement')}
            </FormLabel>
            <InputGroup>
              <Input placeholder="Casa 2" name="complement" type="text" register={register()} />
              {!!errors.complement && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.complement?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>
        <Text
          fontSize="1.2rem"
          color="red.500"
          fontWeight="400"
          mt={5}
          alignSelf="center"
          onClick={() => dispatch(push(searchAutoCompleteAddress))}
          cursor="pointer"
        >
          {translate.getText('not_your_address')}
        </Text>
      </Box>
      <Button
        px="3rem"
        borderRadius="50px"
        bg="green.300"
        type="button"
        w="100%"
        py={8}
        onClick={handleOnClickConfirm}
        isDisabled={updateAddressLoading || !canContinueWithOrder}
        isLoading={updateAddressLoading}
      >
        <Text color="white" fontSize="lg">
          {isVisible
            ? translate.getText('confirm_address_button')
            : translate.getText('continue_button')}
        </Text>
      </Button>
    </Box>
  );
};

export default ConfirmAddressForm;
