import { format } from 'date-fns';
import dateFnsFormat from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
const config = { locale: ptBR };

export const formatDate = (date: number | Date, format: string) =>
  dateFnsFormat(date, format, config);

export function getPaymentDate() {
  return format(new Date(), 'dd LLL yyyy - HH:mm:ss');
}
