import { Box, BoxProps, Divider, Icon, Text } from '@chakra-ui/core';
import React from 'react';

import { Prediction } from '~/models/AutoComplete';

interface AutoCompleteItemProps extends BoxProps {
  item: Prediction;
}

const AutoCompleteItem: React.FC<AutoCompleteItemProps> = ({ item, ...rest }) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="100%"
        py="1rem"
        cursor="pointer"
        {...rest}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          w="auto"
          h="100%"
          pr="1rem"
        >
          <Icon name="menu-pin" size="1.8rem"></Icon>
        </Box>
        <Box
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          w="100%"
        >
          <Box display="flex" alignItems="flex-start" justifyContent="center">
            <Text fontSize="1.5rem" fontWeight="bold" as="span">
              {item.structured_formatting.main_text}
            </Text>
          </Box>
          <Text fontSize="15px" as="span">
            {item.structured_formatting.secondary_text}
          </Text>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default AutoCompleteItem;
