import { Image } from '@chakra-ui/core';
import { Box, BoxProps, Text } from '@chakra-ui/core';
import React from 'react';

import { formatCurrency } from '~/utils/currency';

export interface ProBadgeProps extends BoxProps {
  discountTitle: string;
  discountPrice: number;
  language: string;
  mt?: string;
  w?: string;
  ml?: string;
  mb?: string;
}

const ProBadge: React.FC<ProBadgeProps> = ({
  discountTitle,
  discountPrice,
  language,
  mt = '-15px',
  w = '108%',
  ml = '-15px',
  mb = '10px',
}) => {
  return (
    <Box bg="#1662E5" w={w} ml={ml} mt={mt} alignContent="center" mb={mb} py={5}>
      <Box d="flex" flexDir="row" justifyContent="center">
        <Image src={'/images/pro_icon.png'} h="15px" objectFit="contain" />
        <Text as="span" color="white" fontSize="1.7rem" fontWeight="700" ml={4}>
          {discountTitle}
        </Text>
        <Text as="span" color="white" fontSize="1.7rem" fontWeight="700" ml={2}>
          {formatCurrency(discountPrice, language)}
        </Text>
      </Box>
    </Box>
  );
};

export default ProBadge;
