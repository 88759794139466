export const formatCurrency = (
  value: number,
  lng: string = 'es',
  options?: Intl.NumberFormatOptions,
) => {
  const maximumFractionDigits = options?.maximumFractionDigits || 2;
  let currency = 'BRL';
  let language = 'pt-BR';
  switch (lng) {
    case 'es':
      currency = 'COP';
      language = 'es';
    case 'en':
      currency = 'USD';
      language = 'en';
      break;
    default:
      currency = 'BRL';
      language = 'pt-BR';
      break;
  }
  const formatter = new Intl.NumberFormat(language, {
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    style: 'currency',
    maximumFractionDigits,
    ...options,
  });
  return formatter.format(value);
};

export const hasDecimalPlaces = (value: number) => (value ? value % 1 !== 0 : false);
