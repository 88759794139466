import { PersistConfig } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
// import storageSession from 'redux-persist/lib/storage/session';

export const rappiTurboPersistConfig: PersistConfig<any> = {
  key: '@rappiturbo/rappiTurbo',
  storage: localStorage,
  blacklist: ['loading'],
};

export const bagPersistConfig: PersistConfig<any> = {
  key: '@rappiturbo/bag',
  storage: localStorage,
};

export const simulatePaymentPersistConfig: PersistConfig<any> = {
  key: '@rappiturbo/simulatePayment',
  storage: localStorage,
};

export const trackingPersistConfig: PersistConfig<any> = {
  key: '@rappiturbo/tracking',
  storage: localStorage,
};
