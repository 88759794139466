import { Box, Text } from '@chakra-ui/core';
import { Image } from '@chakra-ui/core';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~/components/Button';
import CircleToggle from '~/components/CircleToggle';
import GoBackButton from '~/components/GoBackButton';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import RappiTurboActions from '~/store/ducks/rappiTurbo';

const { generatePayment } = RappiTurboActions;

interface ConfirmPaymentDataFormProps {
  isVisible: boolean;
  onClickBack?: () => void;
}

const ConfirmPaymentDataForm: React.FC<ConfirmPaymentDataFormProps> = ({
  isVisible,
  onClickBack,
}) => {
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const generatePaymentLoading = rappiTurbo?.generatePaymentLoading;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const { checkoutProducts } = globalState?.bag;
  const [selectedPayment, setSelectedPayment] = useState(null);

  const handleOnClickConfirm: any = useCallback(() => {
    if (isVisible) {
      dispatch(
        generatePayment({
          payment_method: selectedPayment,
          products: checkoutProducts,
        }),
      );
    }
  }, [isVisible, selectedPayment, checkoutProducts]);

  return (
    <Box d="flex" w="100%" py={4} flexDirection="column">
      <Box as="form" d={isVisible ? 'flex' : 'none'} flexDir="column" pb={8}>
        <Box d="flex" flexDir="row" pb="20px" pl={3} borderBottom={'1px solid #ECEFF3'} mb={5}>
          <GoBackButton onClick={onClickBack} />
          <Text fontSize="1.8rem" fontWeight="700" alignSelf="center" pt={1} pl="2.5rem">
            {translate.getText('payment_title')}
          </Text>
        </Box>
        <Text fontSize="1.5rem" fontWeight="500" color="gray.300" py={2} pb={5} alignSelf="center">
          {translate.getText('payment_subtitle')}
        </Text>
        <Button
          key={'paymentMethod-pix'}
          py={8}
          pl={3}
          mb={4}
          border="1px solid #DBDBDB"
          outline={0}
          borderRadius="18px"
          flexDir="row"
          onClick={() => setSelectedPayment('pix')}
          variant="ghost"
          isDisabled={generatePaymentLoading}
        >
          <Image
            h="2rem"
            mr={8}
            src="https://gbc-kuppi-s3-prod.s3.sa-east-1.amazonaws.com/pix.png"
          />
          <Text fontSize="1.3rem" fontWeight="500" color="black">
            PIX
          </Text>

          <CircleToggle ml={'auto'} mr={5} isActive={selectedPayment === 'pix'} />
        </Button>
        <Button
          key={'paymentMethod-creditCard'}
          py={8}
          pl={3}
          mb={4}
          border="1px solid #DBDBDB"
          outline={0}
          borderRadius="18px"
          flexDir="row"
          onClick={() => setSelectedPayment('credit_card')}
          variant="ghost"
          isDisabled={generatePaymentLoading}
        >
          <Image
            h="2rem"
            mr={8}
            src="https://gbc-kuppi-s3-prod.s3.sa-east-1.amazonaws.com/pm-credit_cards.png"
          />
          <Text fontSize="1.3rem" fontWeight="500" color="black">
            {translate.getText('credit_card')}
          </Text>

          <CircleToggle ml={'auto'} mr={5} isActive={selectedPayment === 'credit_card'} />
        </Button>
      </Box>
      <Button
        px="3rem"
        borderRadius="50px"
        bg="green.300"
        type="button"
        w="100%"
        py={8}
        onClick={handleOnClickConfirm}
        isDisabled={generatePaymentLoading || selectedPayment === null}
        isLoading={generatePaymentLoading}
      >
        <Text color="white" fontSize="lg">
          {translate.getText('confirm_button')}
        </Text>
      </Button>
    </Box>
  );
};

export default ConfirmPaymentDataForm;
