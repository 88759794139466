import * as yup from 'yup';

const validityRegex = /\d{2}\/\d{2}/;

function isCardValidityExpired(validity: string) {
  const validityArr = validity.split('/');

  if (validityArr[1] < '24') {
    return false;
  }

  if (validityArr[1] === '24' && Number(validityArr[0]) < Number(new Date().getMonth() + 1)) {
    return false;
  }

  return true;
}

function isCardValidityValid(validity: string) {
  const validityArr = validity.split('/');

  if (Number(validityArr[0]) === 0 || Number(validityArr[1]) === 0) {
    return false;
  }

  if (validityArr[0] > '12') {
    return false;
  }

  return true;
}

export default function paymentSchema(translate: any) {
  return yup.object().shape({
    cardholder_name: yup
      .string()
      .trim()
      .max(40, translate.getText('validation_payment_cardholder_name_invalid'))
      .required(translate.getText('validation_payment_cardholder_name_empty')),
    card_number: yup
      .string()
      .min(14, translate.getText('validation_payment_card_number_invalid_min'))
      .max(16, translate.getText('validation_payment_card_number_invalid_max'))
      .required(translate.getText('validation_payment_card_number_empty')),
    card_cvv: yup
      .string()
      .min(3, translate.getText('validation_payment_card_cvv_invalid_min'))
      .max(4, translate.getText('validation_payment_card_cvv_invalid_max'))
      .required(translate.getText('validation_payment_card_cvv_empty')),
    card_validity: yup
      .string()
      .max(5, translate.getText('validation_payment_card_validity_invalid'))
      .required(translate.getText('validation_payment_card_validity_empty'))
      .test({
        test: (value: string) => isCardValidityValid(value),
        message: translate.getText('validation_payment_card_validity_invalid'),
        name: 'is-card-validity-valid',
      })
      .test({
        test: (value: string) => isCardValidityExpired(value),
        message: translate.getText('validation_payment_card_validity_expired'),
        name: 'is-card-validity-expired',
      })
      .matches(validityRegex, translate.getText('validation_payment_card_validity_invalid')),
  });
}
