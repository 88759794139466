import { Button, IconButton, InputRightElement, Tooltip } from '@chakra-ui/core';
import { FormControl } from '@chakra-ui/core';
import { InputGroup } from '@chakra-ui/core';
import { FormLabel } from '@chakra-ui/core';
import { Box, Image, Text } from '@chakra-ui/core';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Input from '~/components/Form/Input';
import useTranslate from '~/hooks/useTranslate';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import paymentSchema from '~/models/schemas/paymentSchema';
import { RootState } from '~/store';
import SimulatePaymentActions from '~/store/ducks/simulatePayment';
import { formatCurrency } from '~/utils/currency';
import { getPaymentDate } from '~/utils/formatDate';
import { cardValidityMask, onlyNumbers } from '~/utils/masks';
import RealTimeDimensions from '~/utils/realTimeDimensions';

const { simulateConfirmPayment } = SimulatePaymentActions;

const SimulateConfirmCreditCardPaymentPage: React.FC = () => {
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const { priceWithoutDiscount, deliveryFee, serviceFee } = globalState?.bag;
  const { simulateConfirmPaymentLoading } = globalState?.simulatePayment;
  const hasLastStoreData = rappiTurbo?.store?.name && rappiTurbo?.store?.logo;
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;
  const orderTotal = priceWithoutDiscount + serviceFee;
  const deliveryFeeCalc = rappiTurbo?.client?.pro ? 0 : deliveryFee;

  const { register, handleSubmit, setValue, errors } = useForm<any>({
    resolver: useYupValidationResolver(paymentSchema(translate)),
    defaultValues: {
      card_number: '',
      cardholder_name: '',
      card_validity: '',
      card_cvv: '',
    },
  });

  const goBackWhatsapp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?phone=${rappiTurbo?.store?.phone}`,
      isMobile ? '_top' : '_blank',
    );
  }, [rappiTurbo?.store?.phone]);

  const handleConfirmPayment = useCallback(() => {
    dispatch(simulateConfirmPayment());
  }, [dispatch]);

  const handleSimulatePayment = useCallback(() => {
    handleSubmit(handleConfirmPayment)();
  }, [handleSubmit]);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{`${translate.getText('payment_page_title')} - ${
          hasLastStoreData ? rappiTurbo?.store?.name : 'Rappi Turbo'
        }`}</title>
      </Helmet>
      <Box
        d="flex"
        flexDir="row"
        bg="black"
        w="100%"
        p={5}
        justifyContent="space-between"
        alignContent="center"
      >
        <Box d="flex" flexDir="row" pl={5}>
          <Image
            src={hasLastStoreData ? rappiTurbo?.store?.logo : '/images/logo.jpg'}
            backgroundColor="white"
            w={50}
            h={50}
            borderRadius="50%"
            objectFit="cover"
          />
          <Text fontSize="xl" textAlign="center" fontWeight={700} mt={5} ml={5} color="white">
            {hasLastStoreData ? rappiTurbo?.store?.name : 'Rappi Turbo'}
          </Text>
        </Box>
        <Image
          cursor="pointer"
          onClick={goBackWhatsapp}
          src="/images/whatsapp.png"
          objectFit="cover"
          alignSelf="center"
          h="30px"
          w="30px"
          mr={5}
        />
      </Box>
      <Box p={10} mb={5}>
        <Text fontSize="2.8rem" fontWeight={700}>
          {translate
            .getText('simulate_cc_payment_client_name')
            .replace('@name@', rappiTurbo?.client?.first_name)}
        </Text>
        <Text fontSize="lg" fontWeight={400} mt={3}>
          {translate.getText('simulate_cc_payment_info')}
        </Text>
        <Box d="flex" justifyContent="center" mt={10} mb={10}>
          <Box
            w="320px"
            h="180px"
            bgImg="url('/images/simulate_payment_ticket.png')"
            bgSize="320px 180px"
            backgroundRepeat="no-repeat"
          >
            <Box d="flex" flexDir="column" alignItems="center">
              <Text fontSize="1.6rem" fontWeight={700} mt={12} ml="15px">
                {translate.getText('your_order')}
              </Text>
              <Text fontSize="5rem" fontWeight={700} mt={5} ml={9}>
                {formatCurrency(orderTotal + deliveryFeeCalc, rappiTurbo?.client?.language)}
              </Text>
              <Text fontSize="1.3rem" fontWeight={400} mt={1} ml={9}>
                {getPaymentDate()}
              </Text>
            </Box>
          </Box>
        </Box>
        <Text fontSize="1.5rem" fontWeight={400} mt={10}>
          {translate.getText('simulate_cc_payment_obs')}
        </Text>
        <FormControl
          mt={10}
          w="100%"
          isInvalid={!!errors.card_number}
          isDisabled={simulateConfirmPaymentLoading}
        >
          <FormLabel htmlFor="card_number" fontWeight="700" fontSize="1.5rem">
            {translate.getText('simulate_cc_payment_card_number')}
          </FormLabel>
          <InputGroup>
            <Input
              placeholder="4444 3333 2222 1111"
              name="card_number"
              type="text"
              register={register()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('card_number', onlyNumbers(e.target.value), { shouldValidate: true })
              }
            />
            {!!errors.card_number && (
              <InputRightElement h="50px" w="50px">
                <Tooltip
                  aria-label="Erro."
                  placement="top"
                  hasArrow
                  label={errors.card_number?.message}
                  bg="red.400"
                  zIndex={1500}
                >
                  <IconButton
                    aria-label="Erro."
                    icon="warning"
                    color="red.300"
                    _focus={undefined}
                  />
                </Tooltip>
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
        <FormControl
          mt={8}
          w="100%"
          isInvalid={!!errors.cardholder_name}
          isDisabled={simulateConfirmPaymentLoading}
        >
          <FormLabel htmlFor="cardholder_name" fontWeight="700" fontSize="1.5rem">
            {translate.getText('simulate_cc_payment_card_owner_name')}
          </FormLabel>
          <InputGroup>
            <Input
              placeholder="José Silva"
              name="cardholder_name"
              type="text"
              register={register()}
            />
            {!!errors.cardholder_name && (
              <InputRightElement h="50px" w="50px">
                <Tooltip
                  aria-label="Erro."
                  placement="top"
                  hasArrow
                  label={errors.cardholder_name?.message}
                  bg="red.400"
                  zIndex={1500}
                >
                  <IconButton
                    aria-label="Erro."
                    icon="warning"
                    color="red.300"
                    _focus={undefined}
                  />
                </Tooltip>
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
        <Box d="flex" flexDir="row" mt={8}>
          <FormControl
            mb={6}
            w="50%"
            mr={5}
            isInvalid={!!errors.card_validity}
            isDisabled={simulateConfirmPaymentLoading}
          >
            <FormLabel htmlFor="card_validity" fontWeight="700" fontSize="1.5rem">
              {translate.getText('simulate_cc_payment_card_validity')}
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="12/35"
                name="card_validity"
                type="text"
                register={register()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('card_validity', cardValidityMask(e.target.value), {
                    shouldValidate: true,
                  })
                }
              />
              {!!errors.card_validity && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.card_validity?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl
            mb={6}
            w="50%"
            isInvalid={!!errors.card_cvv}
            isDisabled={simulateConfirmPaymentLoading}
          >
            <FormLabel htmlFor="card_cvv" fontWeight="700" fontSize="1.5rem">
              {translate.getText('simulate_cc_payment_card_cvv')}
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="345"
                name="card_cvv"
                type="text"
                register={register()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('card_cvv', onlyNumbers(e.target.value), { shouldValidate: true })
                }
              />
              {!!errors.card_cvv && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.card_cvv?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>
        <Button
          mt={8}
          px="3rem"
          borderRadius="50px"
          bg="green.300"
          variantColor="green.300"
          type="button"
          w="100%"
          py="30px"
          onClick={handleSimulatePayment}
          isLoading={simulateConfirmPaymentLoading}
          isDisabled={simulateConfirmPaymentLoading}
        >
          <Text color="white" fontSize="lg">
            {translate.getText('simulate_cc_payment_make_payment')}
          </Text>
        </Button>
        <Box d="flex" flexDir="column" w="100%" alignContent={'center'}>
          <Image
            mt={16}
            alignSelf="center"
            src={translate.getText('selled_delivery_rappi_img')}
            w="60%"
            objectFit="cover"
          />
        </Box>
      </Box>
    </>
  );
};

export default SimulateConfirmCreditCardPaymentPage;
