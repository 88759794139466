import { Box, Stack, Text } from '@chakra-ui/core';
import { ListItem } from '@chakra-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { formatCurrency } from '~/utils/currency';

import { TextWithMaxChar } from './style';

const TrackingOrderItems: React.FC = () => {
  const globalState = useSelector((state: RootState) => state);
  const trackingData = globalState?.tracking?.trackingData;
  const { itemsInBag } = trackingData?.bag;

  return (
    <Stack spacing={0} mb={10}>
      {itemsInBag.map((item, index) => (
        <ListItem key={`${item.id}-${index}`} mt={10} cursor={'default'} as="div">
          <Box d="flex" alignItems="flex-start" justifyContent="space-between" w="100%" mb="2px">
            <Box d="flex" alignItems="flex-start" flexDir="column">
              <TextWithMaxChar fontSize="1.8rem" fontWeight={'normal'}>
                {item.productQuantity}x {item.name}
              </TextWithMaxChar>
            </Box>
            <Box d="flex" alignItems="flex-start" justifyContent="center">
              <Box d="flex" flexDir="column" alignItems="flex-end">
                <Text as="span" textAlign="center" fontSize="1.9rem" fontWeight="700">
                  {formatCurrency(
                    item.priceWithToppings * item.productQuantity,
                    trackingData?.client?.language,
                  )}
                </Text>
              </Box>
            </Box>
          </Box>
        </ListItem>
      ))}
    </Stack>
  );
};

export default TrackingOrderItems;
