export const pt_BR: any = {
  store_name: 'da Rappi',
  delivery_time: 'Entrega',
  estimated_delivery_fee: 'Frete',
  selled_delivery_rappi_img: '/images/selled_delivery_text_ptBR.png',
  closed_delivery: 'Delivery Fechado',
  empty_catalog: 'Catálogo vazio',
  not_created_catalog: 'O catálogo não foi criado ou não existem categorias ativas.',
  pro_free: 'Grátis',
  pro_discount: 'Desconto com Pro',
  bag_size: 'Produto',
  product_detail_title: 'Detalhes do Produto',
  required: 'Obrigatório',
  topping_required_max_more_than_one: '@currQty@ de @maxQty@ opções',
  topping_required_max_more_than_one_min_obs_one: 'Mínimo uma opção',
  topping_required_max_more_than_one_min_obs_two: 'Mínimo duas opções',
  topping_required_max_more_than_one_min_obs_more: 'Mínimo @option@ opções',
  topping_not_required_max_more_than_one: 'Até @maxQty@ opções',
  topping_required_max_one: 'Selecione uma opção',
  topping_not_required_max_one: 'Até uma opção',
  add_item: 'Adicionar',
  update_item: 'Atualizar',
  delete_item: 'Excluir',
  see_details: 'Visualizar detalhes do item',
  go_to_bag_button: 'Visualizar sacola',
  product_search_title: 'Busca de produtos',
  product_search_input: 'Digite o produto a ser buscado',
  product_search_not_found: 'Não existem produtos para essa busca.',
  bag_title: 'Sua Sacola',
  bag_add_more_items: 'Adicionar mais itens',
  bag_clean: 'Limpar',
  required_complements_info: 'Clique para conferir complementos',
  sub_total: 'Subtotal',
  total: 'Total',
  continue_button: 'Continuar',
  conf_address_title: 'Confirme o seu endereço',
  conf_address_subtitle: 'O seu pedido será entregue em:',
  number: 'Número',
  complement: 'Complemento',
  not_your_address: 'Esse não é o seu endereço? Clique aqui',
  confirm_address_button: 'Confirmar endereço',
  address_title: 'Endereço de Entrega',
  address_not_found: 'Não foram encontrados endereços com os dados informados',
  address_with_number: 'Digite seu endereço com número',
  address_you_are_here: 'Você está aqui',
  save_address_button: 'Salvar endereço',
  not_deliver_at_address: 'Infelizmente ainda não fazemos entregas neste endereço',
  select_another_address_button: 'Selecionar outro endereço',
  changed_deliver_store:
    'Alteramos a loja que fará a entrega por conta da sua mudança de endereço. Monte a sacola novamente',
  assemble_bag_button: 'Montar a sacola',
  order_summary_title: 'Resumo do Pedido',
  delivery_to: 'Entregar em',
  your_order: 'Seu pedido',
  delivery_fee: 'Taxa de delivery',
  service_fee: 'Taxa de serviço',
  discount: 'Desconto',
  conf_user_data_title: 'Confirme suas informações',
  conf_user_data_subtitle: 'Nos confirme os dados para finalizar o pedido',
  name: 'Nome',
  surname: 'Sobrenome',
  email: 'E-mail',
  identification_number: 'CPF',
  confirm_button: 'Confirmar',
  payment_title: 'Como deseja fazer o pagamento?',
  payment_subtitle: 'Você será redirecionado para o pagamento',
  credit_card: 'Cartão de Crédito',
  payment_page_title: 'Pagamento',
  expired_title: 'Sessão expirada',
  expired_session_title: 'Sua sessão expirou',
  expired_session_text:
    'Não se preocupe, caso queira fazer um novo pedido basta mandar uma mensagem pro nosso número no WhatsApp',
  go_back_to_whatsapp: 'Voltar ao WhatsApp',
  tracking_title: 'Rastreio',
  tracking_loading_error_title: 'Erro ao carregar as informações de rastreio',
  tracking_loading_error_text:
    'Não se preocupe, recarregue a página para tentar novamente, ou clique no botão abaixo para voltar ao WhatsApp.',
  turbo_delivery_time_info: 'Seu pedido chegará em',
  turbo_delivery_time_delivered_info: 'Seu pedido foi entregue em',
  turbo_delivery_time_min: 'Minutos',
  turbo_delivery_time_sec: 'Segundos',
  turbo_status_tab: 'Status',
  turbo_details_tab: 'Detalhes',
  turbo_delivery_man_tab: 'Entregador',
  turbo_confirmed_at: 'Confirmado às @confirmedHour@',
  turbo_delivered_at: 'Entregue às @deliveredHour@',
  turbo_order_active: 'Seu pedido está ativo',
  turbo_order_assigned_to_partner: 'Pedido em preparo',
  turbo_order_taken_visible_order: 'Entregador indo buscar o pedido',
  turbo_order_domiciliary_in_store: 'Entregador chegou no Méqui',
  turbo_order_hand_to_domiciliary: 'Pedido saiu para a entrega',
  turbo_order_close_order: 'Seu pedido foi entregue',
  turbo_order_summary: 'Resumo do pedido',
  turbo_help: 'Ajuda',
  turbo_help_title: 'Chat com o Suporte',
  // Errors
  error_selected_address_gps: 'O local selecionado está muito longe do endereço informado',
  error_searching_address: 'Ocorreu um erro ao buscar seu endereço',
  error_selected_address: 'Ocorreu um erro ao selecionar este endereço',
  error_saving_address: 'Não foi possível salvar o seu endereço',
  error_saving_user_data: 'Não foi possível salvar os seus dados',
  error_generating_payment: 'Não foi possível gerar o pagamento',
  error_confirming_payment: 'Não foi possível confirmar o pagamento',
  // Validations
  validation_user_first_name_invalid: 'Seu primeiro nome deve ter no máximo 40 caracteres.',
  validation_user_first_name_empty: 'Por favor informe seu primeiro nome.',
  validation_user_surname_name_invalid: 'Seu sobrenome deve ter no máximo 60 caracteres.',
  validation_user_surname_name_empty: 'Por favor informe seu sobrenome.',
  validation_user_email_invalid: 'O email informado é inválido.',
  validation_user_email_empty: 'Por favor informe seu email.',
  validation_user_identification_invalid: 'O CPF informado é inválido.',
  validation_user_identification_empty: 'Por favor informe seu CPF.',
  validation_address_number_invalid: 'O número deve possuir até 10 caracteres.',
  validation_address_number_empty: 'Por favor informe o número do endereço.',
  validation_address_complement_invalid: 'O complemento deve possuir até 60 caracteres.',
  validation_address_neighborhood_invalid: 'O bairro deve possuir até 60 caracteres.',
  validation_address_neighborhood_empty: 'Por favor informe seu bairro.',
  validation_address_zipcode_invalid: 'Informe um CEP válido',
  validation_address_zipcode_empty: 'Por favor informe seu CEP.',
  validation_payment_cardholder_name_invalid:
    'O  Nome do portador do cartão deve ter no máximo 40 caracteres.',
  validation_payment_cardholder_name_empty: 'Por favor informe o nome do portador do cartão.',
  validation_payment_card_number_invalid_min:
    'O Número do cartão deve ter no mínimo 14 caracteres.',
  validation_payment_card_number_invalid_max:
    'O Número do cartão deve ter no máximo 16 caracteres.',
  validation_payment_card_number_empty: 'Por favor informe o número do cartão.',
  validation_payment_card_cvv_invalid_min: 'O CVV do cartão deve ter no mínimo 3 caracteres.',
  validation_payment_card_cvv_invalid_max: 'O CVV do cartão deve ter no máximo 4 caracteres.',
  validation_payment_card_cvv_empty: 'Por favor informe o CVV do cartão.',
  validation_payment_card_validity_invalid: 'A vaildade do cartão deve estar no formato: MM/AA.',
  validation_payment_card_validity_empty: 'Por favor informe a validade do cartão.',
  validation_payment_card_validity_expired: 'Data de validade do cartão expirada.',
  // Simulations
  simulate_cc_payment_client_name: 'Olá, @name@!',
  simulate_cc_payment_info: 'Verifique o valor do seu pedido e faça o pagamento.',
  simulate_cc_payment_obs: 'Preencha com seus dados os campos abaixo para realizar o pagamento.',
  simulate_cc_payment_card_number: 'Número do cartão',
  simulate_cc_payment_card_owner_name: 'Nome do titular',
  simulate_cc_payment_card_validity: 'Validade',
  simulate_cc_payment_card_cvv: 'CVV',
  simulate_cc_payment_make_payment: 'Realizar pagamento',
  simulate_cc_payment_success: 'Pagamento realizado com sucesso!',
  simulate_cc_payment_success_obs: 'Obrigado, recebemos o seu pagamento.',
  simulate_pix_payment_obs:
    'Clique no botão abaixo para simular que está realizando o pagamento por Pix Copia e Cola.',
};
