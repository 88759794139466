import { Button } from '@chakra-ui/core';
import { Box, Image, Text } from '@chakra-ui/core';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import { formatCurrency } from '~/utils/currency';
import { getPaymentDate } from '~/utils/formatDate';
import RealTimeDimensions from '~/utils/realTimeDimensions';

const SimulateConfirmSuccessPaymentPage: React.FC = () => {
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const { priceWithoutDiscount, deliveryFee, serviceFee } = globalState?.bag;
  const hasStoreData = rappiTurbo?.store?.name && rappiTurbo?.store?.logo;
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;
  const orderTotal = priceWithoutDiscount + serviceFee;
  const deliveryFeeCalc = rappiTurbo?.client?.pro ? 0 : deliveryFee;

  const goBackWhatsapp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?phone=${rappiTurbo?.store?.phone}`,
      isMobile ? '_top' : '_blank',
    );
  }, [rappiTurbo?.store?.phone]);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{`${translate.getText('payment_page_title')} - ${
          hasStoreData ? rappiTurbo?.store?.name : 'Rappi Turbo'
        }`}</title>
      </Helmet>
      <Box
        d="flex"
        flexDir="row"
        bg="black"
        w="100%"
        p={5}
        justifyContent="space-between"
        alignContent="center"
      >
        <Box d="flex" flexDir="row" pl={5}>
          <Image
            src={hasStoreData ? rappiTurbo?.store?.logo : '/images/logo.jpg'}
            backgroundColor="white"
            w={50}
            h={50}
            borderRadius="50%"
            objectFit="cover"
          />
          <Text fontSize="xl" textAlign="center" fontWeight={700} mt={5} ml={5} color="white">
            {hasStoreData ? rappiTurbo?.store?.name : 'Rappi Turbo'}
          </Text>
        </Box>
        <Image
          cursor="pointer"
          onClick={goBackWhatsapp}
          src="/images/whatsapp.png"
          objectFit="cover"
          alignSelf="center"
          h="30px"
          w="30px"
          mr={5}
        />
      </Box>
      <Box p={10} mb={5}>
        <Box d="flex" flexDir="column" w="100%" justifyContent={'center'} mt={10}>
          <Image
            src="/images/simulate_payment_success.png"
            objectFit="cover"
            alignSelf="center"
            w="55%"
            mr={5}
          />
          <Text fontSize="2xl" fontWeight={700} mt={10} textAlign="center">
            {translate.getText('simulate_cc_payment_success')}
          </Text>
          <Text fontSize="1.5rem" fontWeight={400} mt={3} textAlign="center">
            {translate.getText('simulate_cc_payment_success_obs')}
          </Text>
        </Box>
        <Box d="flex" justifyContent="center" mt={10} mb={10}>
          <Box
            w="320px"
            h="180px"
            bgImg="url('/images/simulate_payment_ticket_success.png')"
            bgSize="320px 180px"
            backgroundRepeat="no-repeat"
          >
            <Box d="flex" flexDir="column" alignItems="center">
              <Text fontSize="1.6rem" fontWeight={700} mt={12} ml={9}>
                {translate.getText('your_order')}
              </Text>
              <Text fontSize="5rem" fontWeight={700} mt={5} ml={9}>
                {formatCurrency(orderTotal + deliveryFeeCalc, rappiTurbo?.client?.language)}
              </Text>
              <Text fontSize="1.3rem" fontWeight={400} mt={1} ml={9}>
                {getPaymentDate()}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box d="flex" w="100%" py={4} mt={10} alignSelf={'center'}>
          <Button
            px="3rem"
            borderRadius="50px"
            bg="green.300"
            variantColor="green.300"
            type="button"
            w="100%"
            py="30px"
            onClick={goBackWhatsapp}
          >
            <Text color="white" fontSize="lg">
              {translate.getText('go_back_to_whatsapp')}
            </Text>
          </Button>
        </Box>
        <Box d="flex" flexDir="column" w="100%" alignContent={'center'}>
          <Image
            mt={16}
            alignSelf="center"
            src={translate.getText('selled_delivery_rappi_img')}
            w="60%"
            objectFit="cover"
          />
        </Box>
      </Box>
    </>
  );
};

export default SimulateConfirmSuccessPaymentPage;
