import Address from '~/models/Address';
import { GetAddressDetails, GetAddressList } from '~/models/AutoComplete';
import { onlyNumbers } from '~/utils/masks';

import { apiGlobal, apiRappiTurbo } from './api';

export const getAddressList = async (address: string): Promise<GetAddressList> => {
  const response = await apiGlobal.post('/get-address-list', {
    address,
  });

  return response.data;
};

export const getAddressDetails = async (
  placeId: string,
  callback?: () => void,
): Promise<GetAddressDetails> => {
  const response = await apiGlobal.post('/get-address-details', {
    placeId,
  });

  if (callback) {
    callback();
  }

  return response.data;
};

export const getLatLngByAddressFields = async (fields: Address): Promise<Address> => {
  const formattedAddres = `${fields.street}, ${fields.number} ${fields.neighborhood} ${fields.city} ${fields.state} ${fields.zip_code}`;
  const response = await apiGlobal.post('/get-address', {
    address: formattedAddres,
  });
  return response.data;
};

export const getRappiStoreIdByLatLng = async (
  sessionId: string,
  lat: number,
  lng: number,
): Promise<any> => {
  const response = await apiRappiTurbo.post(`/rappi/${sessionId}/store`, {
    latitude: lat,
    longitude: lng,
  });
  return response.data;
};

export const saveClientAddress = async (
  sessionId: string,
  addressToSave: Address,
): Promise<any> => {
  const response = await apiRappiTurbo.put(`/rappi/${sessionId}/address`, {
    id: addressToSave?.id,
    street: addressToSave?.street,
    number: addressToSave?.number,
    complement: addressToSave?.complement,
    neighborhood: addressToSave?.neighborhood,
    city: addressToSave?.city,
    state: addressToSave?.state,
    zip_code: onlyNumbers(addressToSave?.zip_code),
    latitude: `${addressToSave?.latitude}`,
    longitude: `${addressToSave?.longitude}`,
  });
  return response.data;
};
