import SelectedTopping from '~/models/SelectedTopping';

type RecalcBagAmountsReturn = {
  sizeBag: number;
  priceWithoutDiscount: number;
};

export function recalcBagAmounts(itemsInBag: any[]): RecalcBagAmountsReturn {
  let priceWithoutDiscount = 0;
  let sizeBag = 0;

  itemsInBag.forEach((item) => {
    sizeBag += item.productQuantity;

    let toppingsTotalPrice = 0;
    item.selectedToppings?.forEach((tc: SelectedTopping) => {
      tc.toppings?.forEach((t) => {
        toppingsTotalPrice += t.price * t.quantity;
      });
    });

    priceWithoutDiscount += (item.price + toppingsTotalPrice) * item.productQuantity;
  });

  return {
    sizeBag: sizeBag,
    priceWithoutDiscount: Number(priceWithoutDiscount.toFixed(2)),
  };
}

type Topping = {
  id: number;
  description: string;
  price: number;
  quantity: number;
};

type ToppingsToBag = {
  id: number;
  description: string;
  toppings: Topping[];
};

type ProductToBag = {
  bagId: number;
  corridor_id: number;
  product_id: number;
  name: string;
  price: number;
  productQuantity: number;
  priceWithToppings: number;
  selectedToppings: ToppingsToBag[];
};

export function formatProductToBag(product: any): ProductToBag {
  const selectedToppings: any[] = [];

  product.selectedToppings?.forEach((toppingCategory: any) => {
    const selectedTopping: any = [];

    toppingCategory?.toppings?.map((topping: any) => {
      if (topping?.quantity > 0) {
        selectedTopping.push({
          id: topping?.id,
          description: topping?.description,
          price: topping?.price,
          quantity: topping?.quantity,
        });
      }
    });

    selectedToppings.push({
      id: toppingCategory?.id,
      description: toppingCategory?.description,
      toppings: selectedTopping,
      min_toppings_for_categories: toppingCategory?.min_toppings_for_categories,
    });
  });

  return {
    bagId: product.bagId,
    corridor_id: product.corridor_id,
    product_id: product.product_id,
    name: product.name,
    price: product.price,
    productQuantity: product.productQuantity,
    priceWithToppings: product.priceWithToppings,
    selectedToppings: selectedToppings,
  };
}

export function getCategoryIdx(catalog: any[], categoryId: number): number {
  return catalog.findIndex((category: any) => category.id === Number(categoryId));
}

export function getProductIdx(categoryProducts: any[], productId: number): number {
  return categoryProducts.findIndex((product: any) => product.product_id === Number(productId));
}

export function getProductFromCatalog(catalog: any[], categoryId: number, productId: number): any {
  const categoryIdx = getCategoryIdx(catalog, categoryId);
  if (categoryIdx === -1) {
    return null;
  }

  const productIdx = getProductIdx(catalog[categoryIdx]?.products, productId);
  if (productIdx === -1) {
    return null;
  }

  return catalog[categoryIdx]?.products[productIdx];
}
