import { css, Global } from '@emotion/core';
import React from 'react';

const globalCss = css`
  body {
    -webkit-font-smoothing: antialiased !important;
    touch-action: pan-x pan-y;
    @media screen and (min-width: 56.25em) {
      background: url('/images/background.png'), linear-gradient(to bottom right, #f2f2f2, #fff);
      background-repeat: repeat-y;
      background-attachment: fixed;
      background-position: center;
    }
  }

  html {
    /* 1rem = 16px */
    font-size: 100%;

    /* > 600px = 1rem = 9,6px */
    @media screen and (min-width: 37.5em) {
      font-size: 60%;
    }
    /* < 600px = 1rem = 10,4px */
    @media screen and (max-width: 37.5em) {
      font-size: 65%;
    }
    /* < 450px = 1rem = 10px */
    @media screen and (max-width: 28em) {
      font-size: 62.5%;
    }
  }

  @font-face {
    font-family: 'PPObjectSans';
    src: url('/fonts/PPObjectSans/PPObjectSans-Heavy.otf');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'PPObjectSans';
    src: url('/fonts/PPObjectSans/PPObjectSans-Regular.otf');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }

  /* React-Toastify Styling */
  .Toastify__toast-container {
    font-size: 1.6rem;
    padding: 0 0.8rem;
  }

  .Toastify__toast {
    border-radius: 1rem;
  }

  .Toastify__toast--error {
    background: #f56565;
  }
  /* Leaflet Styling */

  .leaflet-bottom {
    z-index: 5;
  }

  @keyframes shake-animation {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-3px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(3px, 0, 0);
    }
  }

  .shake {
    animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
`;

const GlobalStyles = () => <Global styles={globalCss} />;

export default GlobalStyles;
