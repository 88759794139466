import * as yup from 'yup';

export default function confirmAddressSchema(
  shouldValidateZipcode: boolean = true,
  translate: any,
) {
  return yup.object().shape({
    number: yup
      .string()
      .trim()
      .max(10, translate.getText('validation_address_number_invalid'))
      .required(translate.getText('validation_address_number_empty')),
    complement: yup
      .string()
      .trim()
      .max(60, translate.getText('validation_address_complement_invalid')),
    neighborhood: yup
      .string()
      .trim()
      .required(translate.getText('validation_address_neighborhood_empty'))
      .max(60, translate.getText('validation_address_neighborhood_invalid')),
    zip_code: shouldValidateZipcode
      ? yup
          .string()
          .trim()
          .required(translate.getText('validation_address_zipcode_empty'))
          .matches(RegExp(/\d{5}-\d{3}/), translate.getText('validation_address_zipcode_invalid'))
      : yup.string().trim().max(9).required(translate.getText('validation_address_zipcode_empty')),
  });
}
