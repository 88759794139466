export const invalidSession = '/sessao-invalida';
export const rappiStoreBase = '/';
export const rappiStoreSessionId = '/:session_id';
export const rappiStoreMenu = '/menu';
export const rappiStoreMenuSearch = '/menu/busca';
export const productDetail = '/produto/:product_id';
export const bag = '/sacola';
export const searchAutoCompleteAddress = '/endereco/procurar';
export const confirmAddress = '/endereco/confirmar';
export const orderReview = '/sacola/resumo';
export const simulateConfirmCreditCardPaymentLink = '/pagamento/simula-pagamento-cartao-credito';
export const simulateConfirmPixPaymentLink = '/pagamento/simula-pagamento-pix';
export const simulateConfirmSuccessPaymentLink = '/pagamento/sucesso';
export const trackingOrder = '/rastreio/:session_id';
