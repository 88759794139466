// @ts-nocheck
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { simulateConfirmSuccessPaymentLink } from '~/routes/routeMap';
import { apiRappiTurbo } from '~/services/api';

import {
  Creators as SimulatePaymentCreators,
  SimulatePaymentTypes,
} from '../ducks/simulatePayment';

const { simulateConfirmPaymentEnd } = SimulatePaymentCreators;

const rappiTurbo = (state) => state.rappiTurbo;

export function* simulateConfirmPayment() {
  try {
    const rappiTurboData: RappiTurboStateType = yield select(rappiTurbo);

    yield call(
      apiRappiTurbo.post,
      `/rappi/webhooks/payment/events`,
      {
        event: 'confirmed_payment',
        data: {
          session_id: rappiTurboData?.sessionId,
          simulate: true,
        },
      },
      {
        headers: {
          'access-token':
            'kWcUeUXD2MJc4MdRX6ihxg2DngGyaeFrhNIVGTqsO7jOu35GDSAo5KjYFTSonrRRuTCMwqtXmq0hv8R4inlTWQncuALWg',
        },
      },
    );

    yield put(push(simulateConfirmSuccessPaymentLink));
  } catch (err: any) {
    toast.error(translate.getText('error_confirming_payment'));
  } finally {
    yield put(simulateConfirmPaymentEnd());
  }
}

export default all([
  takeLatest(SimulatePaymentTypes.SIMULATE_CONFIRM_PAYMENT, simulateConfirmPayment),
]);
