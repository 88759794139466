import { Box, Heading } from '@chakra-ui/core';
import React from 'react';

import GoToButton, { GoToButtonProps } from '../GoToButton';

interface HeaderWithBackButtonProps extends GoToButtonProps {
  headerTitle?: React.ReactNode;
  state?: any;
  fontSize?: string;
}

const HeaderWithBackButton: React.FC<HeaderWithBackButtonProps> = ({
  headerTitle = 'Rappi Turbo',
  to,
  state,
  children,
  fontSize = 'lg',
  ...rest
}) => {
  return (
    <>
      <Box
        as="header"
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        {...rest}
        mb={8}
        pt="2rem"
      >
        <GoToButton to={to} state={state} />
        <Box w="100%">
          <Heading as="h2" fontSize={fontSize} textAlign="center" fontWeight="bold">
            {headerTitle}
          </Heading>
        </Box>
      </Box>
      {children && (
        <Box d="flex" justifyContent="center" alignItems="center">
          {children}
        </Box>
      )}
    </>
  );
};

export default HeaderWithBackButton;
