import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { reducer as bag } from './bag';
import {
  bagPersistConfig,
  rappiTurboPersistConfig,
  simulatePaymentPersistConfig,
  trackingPersistConfig,
} from './config/persist';
import { reducer as rappiTurbo } from './rappiTurbo';
import { reducer as simulatePayment } from './simulatePayment';
import { reducer as tracking } from './tracking';

const createRootReducer = (history: History<object>) =>
  combineReducers({
    router: connectRouter(history),
    rappiTurbo: persistReducer(rappiTurboPersistConfig, rappiTurbo),
    bag: persistReducer(bagPersistConfig, bag),
    simulatePayment: persistReducer(simulatePaymentPersistConfig, simulatePayment),
    tracking: persistReducer(trackingPersistConfig, tracking),
  });

export default createRootReducer;
