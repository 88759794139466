import axios from 'axios';
import https from 'https';

const reactAppEnvApi = process.env.REACT_APP_ENV_API;
const reactAppEnvGlobal = process.env.REACT_APP_ENV_GLOBAL_API;
const reactAppEnvRappiTurbo = process.env.REACT_APP_ENV_RAPPITURBO_API;

const baseUrlsRappiTurbo: Record<string, string> = {
  production: 'https://rappi-turbo-api.kuppi.com.br/api',
  development: 'https://dev-rappi-turbo-api.kuppi.com.br/api',
  qa: 'https://qa-rappi-turbo-api.kuppi.com.br/api',
  local: 'http://localhost:3333/api',
};

const baseUrlsGlobal: Record<string, string> = {
  production: 'https://api.kuppi.com.br/global',
  development: 'https://dev-api.kuppi.com.br/global',
  qa: 'https://qa-api.kuppi.com.br/global',
  local: 'http://localhost:3334/global',
};

export const baseUrlGlobal = baseUrlsGlobal[reactAppEnvGlobal || reactAppEnvApi];
export const baseUrlRappiTurbo = baseUrlsRappiTurbo[reactAppEnvRappiTurbo || reactAppEnvApi];

// Global API
export const apiGlobal = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  baseURL: baseUrlGlobal,
  headers: {
    'Content-Type': 'application/json',
  },
});

// RappiTurbo API
export const apiRappiTurbo = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  baseURL: baseUrlRappiTurbo,
  headers: {
    'Content-Type': 'application/json',
  },
});
