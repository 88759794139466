import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Spinner,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/core';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import useTranslate from '~/hooks/useTranslate';
import Address from '~/models/Address';
import UnsupportedAddressModal from '~/pages/Addresses/ConfirmAddress/UnsupportedAddressModal';
import { bag, invalidSession, rappiStoreMenu, searchAutoCompleteAddress } from '~/routes/routeMap';
import { getRappiStoreIdByLatLng, saveClientAddress } from '~/services/address';
import { RootState } from '~/store';
import BagActions from '~/store/ducks/bag';
import RappiTurboActions from '~/store/ducks/rappiTurbo';
import { onlyNumbers } from '~/utils/masks';

import { mapStyleConfig } from './config';
const { getStoreData, setAuthDataChangedStore, updateClientAddressSuccess, setInvalidSession } =
  RappiTurboActions;
const { cleanBag } = BagActions;

interface UseLocationProps {
  addressDetails: Address;
}

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const ConfirmAddress: React.FC = () => {
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

  const circleCenter = useRef<google.maps.LatLngLiteral>(null);
  const mapRef = useRef(null);
  const hoverPinRef = useRef<google.maps.Marker>(null);
  const fixedPinRef = useRef<google.maps.Marker>(null);
  const circleRef = useRef<google.maps.Circle>(null);

  const theme = useTheme();

  const location = useLocation<UseLocationProps>();
  const addressDetails = location?.state?.addressDetails;

  const { isOpen: isOpenUnsupportedAddress, onOpen: onOpenUnsupportedAddress } = useDisclosure();
  const { isOpen: isOpenChangedStore, onOpen: onOpenChangedStore } = useDisclosure();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey,
  });

  useEffect(() => {
    if (!addressDetails) dispatch(push(searchAutoCompleteAddress));
    const lat = addressDetails?.latitude;
    const lng = addressDetails?.longitude;

    circleCenter.current = { lat, lng };
  }, [addressDetails, dispatch]);

  const onSubmit = async () => {
    try {
      setLoadingAddress(true);

      const newRappiData = await getRappiStoreIdByLatLng(
        rappiTurbo?.sessionId,
        fixedPinRef.current?.getPosition().lat(),
        fixedPinRef.current?.getPosition().lng(),
      );

      if (!newRappiData.rappi_store_id) {
        setLoadingAddress(false);
        onOpenUnsupportedAddress();
        return;
      }

      if (Number(newRappiData.rappi_store_id) !== Number(rappiTurbo?.store?.id)) {
        dispatch(setAuthDataChangedStore(newRappiData));
        dispatch(getStoreData(false, newRappiData.rappi_store_id));
        dispatch(cleanBag());
        setLoadingAddress(false);
        onOpenChangedStore();
        return;
      }
    } catch (err: any) {
      setLoadingAddress(false);
      if ([401, 404].includes(err.response?.status)) {
        dispatch(cleanBag());
        dispatch(setInvalidSession(err.response?.data));
        dispatch(push(invalidSession));
      } else {
        onOpenUnsupportedAddress();
      }
      return;
    }

    const addressToSave = {
      ...addressDetails,
      latitude: fixedPinRef.current?.getPosition().lat(),
      longitude: fixedPinRef.current?.getPosition().lng(),
      zip_code: onlyNumbers(addressDetails?.zip_code),
    };

    try {
      const savedAddress = await saveClientAddress(rappiTurbo?.sessionId, addressToSave);
      dispatch(updateClientAddressSuccess(savedAddress));
      setLoadingAddress(false);
      dispatch(push(bag, { openConfirmDataModal: true }));
    } catch (err: any) {
      setLoadingAddress(false);
      if ([401, 404].includes(err.response?.status)) {
        dispatch(cleanBag());
        dispatch(setInvalidSession(err.response?.data));
        dispatch(push(invalidSession));
      } else {
        onOpenUnsupportedAddress();
      }
    }
  };

  const onClickSelectAnotherAddress = () => {
    dispatch(push(searchAutoCompleteAddress));
  };

  const onClickAssembleBag = () => {
    dispatch(push(rappiStoreMenu));
  };

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      hoverPinRef.current = new google.maps.Marker({
        position: mapRef.current.getCenter(),
        map: mapRef.current,
        icon: {
          url: '/images/pin-hover.png',
          size: new google.maps.Size(32, 58, 'px', 'px'),
        },
        visible: false,
      });

      fixedPinRef.current = new google.maps.Marker({
        position: mapRef.current.getCenter(),
        map: mapRef.current,
        icon: {
          url: '/images/pin-fixed.png',
          size: new google.maps.Size(32, 42, 'px', 'px'),
        },
        visible: true,
      });

      circleRef.current = new google.maps.Circle({
        strokeColor: theme.colors.red[500],
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: theme.colors.red[300],
        fillOpacity: 0.15,
        clickable: false,
        draggable: false,
        editable: false,
        visible: false,
        map: mapRef.current,
        center: circleCenter.current,
        radius: 250,
      });

      mapRef.current.addListener('dragstart', () => {
        hoverPinRef.current.setVisible(true);
        fixedPinRef.current.setVisible(false);
      });

      mapRef.current.addListener('dragend', () => {
        hoverPinRef.current.setVisible(false);
        fixedPinRef.current.setVisible(true);
        fixedPinRef.current.setPosition(mapRef.current.getCenter());
      });

      mapRef.current.addListener('bounds_changed', () => {
        hoverPinRef.current.setPosition(mapRef.current.getCenter());
      });
    },
    [theme.colors.red],
  );

  const onMapDragEnd = useCallback(() => {
    const mapCenter = mapRef.current.getCenter();

    const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(circleCenter.current.lat, circleCenter.current.lng),
      mapCenter,
    );
    if (distanceInMeters > 250) {
      toast.error(translate.getText('error_selected_address_gps'));
      mapRef.current.panTo(circleCenter.current);
      setTimeout(() => {
        fixedPinRef.current.setPosition(circleCenter.current);
        circleRef.current.setVisible(true);
      }, 750);

      return;
    }
  }, []);

  const renderMap = useCallback(() => {
    const options = {
      zoom: 18,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
      },
      center: {
        lat: addressDetails.latitude,
        lng: addressDetails.longitude,
      },
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      minZoom: 16,
      styles: mapStyleConfig,
      gestureHandling: 'greedy',
    } as google.maps.MapOptions;

    return (
      <>
        {/* @ts-ignore */}
        <GoogleMap
          options={options}
          onLoad={onLoad}
          onDragEnd={onMapDragEnd}
          mapContainerStyle={{ width: '100%', height: 'calc(100vh - 7rem)' }}
        />
      </>
    );
  }, [onMapDragEnd, addressDetails, onLoad]);

  return (
    <Box as="main" flexDir="column" w="100%" h="calc(100vh - 8rem)" pos="relative">
      <HeaderWithBackButton headerTitle="Você está aqui" px="3rem" />
      {addressDetails && Object.entries(addressDetails).some(([_, value]) => !!value) && (
        <Box
          p={4}
          bg="white"
          borderRadius="1rem"
          mb={4}
          pos="absolute"
          top="8rem"
          zIndex={500}
          ml="6rem"
          mr="2rem"
          width="calc(100% - 8rem)"
        >
          <Text fontSize="md">
            <Text as="span" fontWeight="700">
              {addressDetails.street}
              {addressDetails.number ? `, ${addressDetails.number}` : ''}
            </Text>
            <br /> {addressDetails.neighborhood ? `${addressDetails.neighborhood} - ` : ''}
            {addressDetails.city}, {addressDetails.state}
          </Text>
        </Box>
      )}
      {isLoaded ? (
        renderMap()
      ) : (
        <Box d="flex" justifyContent="center" alignItems="center" h="calc(100vh - 7rem)">
          <Spinner size="lg" />
        </Box>
      )}
      {loadError && (
        <Box d="flex" justifyContent="center" alignItems="center" h="calc(100vh - 7rem)">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Map loading error.</AlertTitle>
          </Alert>
        </Box>
      )}

      <Box
        width="min(100%, 900px)"
        pos="fixed"
        bottom="-2rem"
        left={['0', '', '', '50%']}
        transform={['', '', '', 'translateX(-50%)']}
        px={['2rem', '2rem', '2rem', '2rem', '12rem']}
        py="2rem"
        zIndex={600}
      >
        <BottomBar bg="transparent" position="relative" boxShadow="none" pb={8}>
          <Button
            mt="3rem"
            px="3rem"
            borderRadius="50px"
            bg="green.300"
            type="submit"
            w="100%"
            py={8}
            onClick={onSubmit}
            isLoading={loadingAddress}
            isDisabled={!isLoaded || !!loadError}
          >
            <Text color="white" fontSize="lg">
              {translate.getText('save_address_button')}
            </Text>
          </Button>
        </BottomBar>
      </Box>

      <UnsupportedAddressModal
        headingText={translate.getText('not_deliver_at_address')}
        buttonText={translate.getText('select_another_address_button')}
        isOpen={isOpenUnsupportedAddress}
        onClose={onClickSelectAnotherAddress}
        onClickAction={onClickSelectAnotherAddress}
      />

      <UnsupportedAddressModal
        headingText={translate.getText('changed_deliver_store')}
        buttonText={translate.getText('assemble_bag_button')}
        isOpen={isOpenChangedStore}
        onClose={onClickAssembleBag}
        onClickAction={onClickAssembleBag}
      />
    </Box>
  );
};

export default ConfirmAddress;
