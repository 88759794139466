import { routerMiddleware, RouterState } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import history from '~/routes/history';

import createComposedStore from './createComposedStore';
import rootReducer from './ducks';
import { BagStateType } from './ducks/bag';
import { RappiTurboStateType } from './ducks/rappiTurbo';
import { SimulatePaymentType } from './ducks/simulatePayment';
import { TrackingType } from './ducks/tracking';
import persistReducers from './persistReducers';
import rootSaga from './sagas';
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];

const persistConfig = {
  key: 'kuppi_rappi_turbo',
  storage: localStorage,
  whitelist: ['rappiTurbo', 'bag', 'simulatePayment', 'tracking'],
};
const store = createComposedStore(
  persistReducers(rootReducer(history), persistConfig),
  middlewares,
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type RootState = {
  router: RouterState<object>;
  rappiTurbo: RappiTurboStateType;
  bag: BagStateType;
  simulatePayment: SimulatePaymentType;
  tracking: TrackingType;
};

export { store, persistor };
