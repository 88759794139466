import { Box, Heading, IconButton, Image, Stack, Text } from '@chakra-ui/core';
import { Button } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import debounce from 'lodash/debounce';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import BottomBar from '~/components/BottomBar';
import ListItem from '~/components/ListItem';
import ProductItem from '~/components/ProductItem';
import useTranslate from '~/hooks/useTranslate';
import useWindowConstraints from '~/hooks/useWindowConstraints';
import { bag, invalidSession, productDetail, rappiStoreMenuSearch } from '~/routes/routeMap';
import { RootState } from '~/store';
import BagActions from '~/store/ducks/bag';
import RappiTurboActions from '~/store/ducks/rappiTurbo';
import { ScrollSmoothLeft, ScrollSmoothTop } from '~/styles/animations/ScrollSmooth';
import { formatCurrency } from '~/utils/currency';
import RealTimeDimensions from '~/utils/realTimeDimensions';

import CategoryButton from './CategoryButton';
import { AdvertiserHeading, CustomCategoryBox, HorizontalScroll } from './styles';
import currentPosition from './utils/currentCategoryPosition';
const { clearRappiTurboData } = RappiTurboActions;
const { cleanBag } = BagActions;

const RappiStoreMenu: React.FC = () => {
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const { itemsInBag, priceWithoutDiscount } = globalState?.bag;
  const rappiTurbo = globalState?.rappiTurbo;
  const rappiStore = rappiTurbo?.store;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const [windowScrollY, setWindowScrollY] = useState(0);
  const { scrollY } = useWindowConstraints();
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;
  const [visibleCategoryIndex, setVisibleCategoryIndex] = useState<number | null>(null);
  const horizontalBoxUseRef = useRef();
  const [categoriesRefs, setCategoriesRefs] = useState<React.MutableRefObject<HTMLDivElement>[]>(
    [],
  );
  const [categoriesHorizontalRefs, setCategoriesHorizontalRefs] = useState<
    React.MutableRefObject<HTMLDivElement>[]
  >([]);

  useEffect(() => {
    if (!rappiTurbo?.sessionId) {
      dispatch(clearRappiTurboData());
      dispatch(cleanBag());
      dispatch(push(invalidSession));
    }
  }, [rappiTurbo?.sessionId]);

  useEffect(() => {
    setCategoriesRefs((prevRefs) =>
      Array(rappiStore?.corridors?.length)
        .fill(undefined)
        .map((_, index) => prevRefs[index] || createRef()),
    );

    setCategoriesHorizontalRefs((prevRefs) =>
      Array(rappiStore?.corridors?.length)
        .fill(undefined)
        .map((_, index) => prevRefs[index] || createRef()),
    );
  }, [rappiStore?.corridors]);

  const handleScrollIntoCategory = useCallback(
    (category_index: number) => {
      ScrollSmoothTop({ ref: categoriesRefs[category_index] });
    },
    [categoriesRefs],
  );

  const handleHorizontalScrollCategory = useCallback(
    (category_index: number | null) => {
      setVisibleCategoryIndex(category_index);
      if (category_index === null) {
        ScrollSmoothLeft({
          ref: categoriesHorizontalRefs[0],
          includedBox: horizontalBoxUseRef,
        });
      } else {
        ScrollSmoothLeft({
          ref: categoriesHorizontalRefs[category_index],
          includedBox: horizontalBoxUseRef,
        });
      }
    },
    [categoriesHorizontalRefs],
  );

  useEffect(
    debounce(() => {
      categoriesRefs.forEach((category, index, array) => {
        if (!category?.current) return;

        const nextCategory = array[index + 1];
        const currentCategoryPosition = currentPosition(category);
        const firstCategory = currentPosition(array[0]);
        const nextCategoryPosition = nextCategory && currentPosition(nextCategory);
        const windowHeight = document.body.scrollHeight - window.innerHeight;

        if (scrollY < firstCategory) {
          handleHorizontalScrollCategory(null);
        } else if (windowHeight.toFixed(0) === scrollY.toFixed(0)) {
          handleHorizontalScrollCategory(array.length - 1);
        } else if (scrollY >= currentCategoryPosition && scrollY < nextCategoryPosition) {
          handleHorizontalScrollCategory(index);
        } else if (index === array.length - 1 && scrollY >= currentCategoryPosition) {
          handleHorizontalScrollCategory(index);
        }
      });
    }, 500),
    [scrollY, categoriesRefs, handleHorizontalScrollCategory],
  );

  const handleOnClickItem = (product: any) => {
    const productDetailRoute = productDetail.replace(':product_id', product.product_id);
    dispatch(push(productDetailRoute, { product }));
  };

  return (
    <>
      {/* Page title */}
      {/* @ts-ignore */}
      <Helmet>
        <title>{rappiStore?.name ? rappiStore?.name : 'Rappi Turbo'}</title>
      </Helmet>

      {/* Cover */}
      <Box d="flex" alignItems="flex-start" justifyContent="flex-start" pb={150}>
        <Image
          src={rappiStore?.background}
          position="absolute"
          top="0"
          left="0"
          h="16rem"
          w="100%"
          objectFit="cover"
        />
      </Box>

      {/* Page Content */}
      <Box as="main" flexDir="column" w="100%" position="relative" pb={70} zIndex={1}>
        {/* Header */}
        <Stack as="header" spacing={2} mt="1rem" py={5} px={5} pb={6} position="relative">
          {/* Advertiser Name and Logo */}
          <Box d="flex" alignItems="center">
            <Image
              src={rappiStore?.logo}
              backgroundColor="white"
              h={50}
              w={50}
              borderRadius="50%"
              objectFit="cover"
            />
            <Box d="flex" alignItems="center" ml={5}>
              <AdvertiserHeading as="h1" fontSize="2.6rem" fontWeight="700">
                {`${rappiStore?.name} ${translate.getText('store_name')}`}
              </AdvertiserHeading>
            </Box>
            <Box ml="auto" mr={1} d="flex" alignItems="flex-end">
              <Link
                to={{
                  pathname: rappiStoreMenuSearch,
                }}
                style={{ display: 'flex', flex: '1' }}
              >
                <IconButton
                  aria-label="Pesquisar..."
                  icon="search"
                  variant="ghost"
                  size="lg"
                  color="black"
                  w={'48px'}
                  h={'48px'}
                />
              </Link>
            </Box>
          </Box>
          <Text
            mt={3}
            w="100%"
            as="span"
            fontSize="sm"
            color="gray.400"
            fontWeight="400"
            textAlign="center"
          >
            {rappiStore?.address}
          </Text>
          {/* Closed Store */}
          {!rappiStore?.open && (
            <Box
              bg="#FF6C6C1A"
              border="1px"
              borderColor="#0000001A"
              borderRadius={20}
              w="200px"
              p={2}
              alignSelf="center"
              textAlign="center"
              mt={3}
            >
              <Text as="span" fontSize="sm" color="#C23E3E" fontWeight="700" alignSelf="center">
                {translate.getText('closed_delivery')}
              </Text>
            </Box>
          )}
          {/* Delivery Info */}
          <Box
            border="1px"
            borderColor="#0000001A"
            borderRadius={20}
            w="300px"
            p={2}
            d="flex"
            mt={3}
            alignSelf="center"
          >
            <Box w="150px" p={2} d="flex" flexDir="column">
              <Box d="flex" flexDir="row" justifyContent="center">
                <Text as="span" fontSize="sm" color="gray.400" fontWeight="400" alignSelf="center">
                  {translate.getText('delivery_time')}
                </Text>
                <Image src={'/images/turbo_icon.png'} h={8} w={8} objectFit="contain" ml={2} />
              </Box>
              <Text as="span" fontSize="sm" fontWeight="700" alignSelf="center" mt={2}>
                {/* {rappiStore?.eta} */}
                10 min
              </Text>
            </Box>
            <Box w="150px" p={2} d="flex" flexDir="column">
              <Box d="flex" flexDir="row" justifyContent="center">
                <Text as="span" fontSize="sm" color="gray.400" fontWeight="400" alignSelf="center">
                  {translate.getText('estimated_delivery_fee')}
                </Text>
                <Image src={'/images/delivery_icon.png'} h={8} w={8} objectFit="contain" ml={2} />
              </Box>
              <Text as="span" fontSize="sm" fontWeight="700" alignSelf="center" mt={2}>
                {rappiTurbo?.client?.pro ? (
                  <Box d="flex" flexDir="row" justifyContent="center">
                    <Image src={'/images/pro_icon.png'} h="15px" objectFit="contain" mr={3} />
                    <Text
                      as="span"
                      fontSize="sm"
                      color={'#1662E5'}
                      fontWeight="700"
                      alignSelf="center"
                    >
                      {translate.getText('pro_free')}
                    </Text>
                  </Box>
                ) : (
                  formatCurrency(8.5, rappiTurbo?.client?.language)
                )}
              </Text>
            </Box>
          </Box>
          <Image
            mt={5}
            alignSelf={'center'}
            src={translate.getText('selled_delivery_rappi_img')}
            h="100%"
            w="50%"
            objectFit="cover"
          />
        </Stack>

        {/* Categories Bar */}
        <CustomCategoryBox stick={windowScrollY > 300} isMobile={isMobile}>
          {/* Categories Tabs */}
          <HorizontalScroll
            isInline
            borderBottom="1px solid"
            borderColor="gray.50"
            position="relative"
            top={0}
            ref={horizontalBoxUseRef}
          >
            {rappiStore?.corridors &&
              rappiStore?.corridors?.map((category: any, categoryIdx: number) => (
                <Box
                  as="div"
                  ref={categoriesHorizontalRefs[categoryIdx]}
                  key={category?.id}
                  p="0"
                  m="0"
                >
                  <CategoryButton
                    onClick={() => handleScrollIntoCategory(categoryIdx)}
                    currentCategory={visibleCategoryIndex === categoryIdx}
                  >
                    {category?.name}
                  </CategoryButton>
                </Box>
              ))}
          </HorizontalScroll>
        </CustomCategoryBox>

        {/* Empty Categories */}
        {rappiStore?.corridors && rappiStore?.corridors?.length === 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            color="gray.400"
            flexDirection="column"
          >
            <Heading as="h1" fontSize="lg" fontWeight="700" px="3rem">
              {`Oops! ${translate.getText('empty_catalog')} :(`}
            </Heading>
            <Text
              as="span"
              fontSize="sm"
              fontWeight="400"
              color="gray.400"
              mt={5}
              pl={10}
              pr={10}
              width="100%"
              textAlign="center"
            >
              {translate.getText('not_created_catalog')}
            </Text>
          </Box>
        )}

        {/* Categories */}
        {rappiStore?.corridors &&
          rappiStore?.corridors?.map((category: any, categoryIdx: number) => (
            <Box
              key={category?.id}
              as="section"
              bg="white"
              id="categories"
              mb={categoryIdx === rappiStore?.corridors?.length - 1 ? 10 : 0}
            >
              <Heading
                as="h1"
                fontSize="2xl"
                fontWeight="700"
                py={6}
                px="2rem"
                ref={categoriesRefs[categoryIdx]}
              >
                {category?.name}
              </Heading>
              <Stack spacing={0}>
                {category?.products?.map((product: any) => (
                  <ListItem
                    borderTop="0px solid"
                    borderBottom="1px solid"
                    key={product?.product_id}
                    onClick={() => handleOnClickItem(product)}
                  >
                    <ProductItem product={product} cursor="pointer" />
                  </ListItem>
                ))}
              </Stack>
            </Box>
          ))}

        {itemsInBag?.length > 0 && (
          <BottomBar py={5} px={2}>
            <Box d="flex" flexDir="row">
              <Box d="flex" flexDir="column">
                <Text as="span" fontSize="1.3rem" fontWeight="400">
                  {`${itemsInBag?.length} ${translate.getText('bag_size')}${
                    itemsInBag?.length > 1 ? 's' : ''
                  }`}
                </Text>
                <Text as="span" fontSize="2xl" fontWeight="700">
                  {formatCurrency(priceWithoutDiscount, rappiTurbo?.client?.language)}
                </Text>
              </Box>
              <Button
                ml="auto"
                px="3rem"
                borderRadius="50px"
                bg="green.300"
                variantColor="green.300"
                type="button"
                py={10}
                onClick={() => dispatch(push(bag))}
              >
                <Text color="white" fontSize="lg">
                  {translate.getText('go_to_bag_button')}
                </Text>
              </Button>
            </Box>
          </BottomBar>
        )}
      </Box>
    </>
  );
};

export default RappiStoreMenu;
