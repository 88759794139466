import * as yup from 'yup';

export default function confirmAddressBagSchema(translate: any) {
  return yup.object().shape({
    number: yup
      .string()
      .trim()
      .max(10, translate.getText('validation_address_number_invalid'))
      .required(translate.getText('validation_address_number_empty')),
    complement: yup
      .string()
      .trim()
      .max(60, translate.getText('validation_address_complement_invalid')),
  });
}
