import { Box, Icon, Stack, Text } from '@chakra-ui/core';
import { ListItem } from '@chakra-ui/core';
import React from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { FiMoreVertical } from 'react-icons/fi';
import { MdInfoOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';

import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import { formatCurrency } from '~/utils/currency';

import { TextWithMaxChar } from './style';

export interface OrderItemsProps {
  screen: string;
  handleOnEditingItem: any;
  handleOnGoToEditItem?: any;
  hasUnfilledRequiredToppings?: any;
}

const OrderItems: React.FC<OrderItemsProps> = ({
  screen,
  handleOnEditingItem,
  handleOnGoToEditItem,
  hasUnfilledRequiredToppings,
}) => {
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const { itemsInBag } = globalState?.bag;
  const translate = useTranslate(rappiTurbo?.client?.language);

  return (
    <Stack spacing={0} mb={10}>
      {itemsInBag.map((item, index) => (
        <div key={`itemInBag-${index}`}>
          <ListItem
            key={`${item.product_id}-${index}`}
            mt={10}
            onClick={() => handleOnEditingItem(index)}
            cursor={screen === 'bag' ? 'pointer' : 'default'}
            as="div"
          >
            <Box d="flex" alignItems="flex-start" justifyContent="space-between" w="100%" mb="2px">
              <Box d="flex" alignItems="flex-start" flexDir="column">
                <TextWithMaxChar
                  fontSize="1.8rem"
                  fontWeight={screen === 'bag' ? 'bold' : 'normal'}
                >
                  {item.productQuantity}x {item.name}
                </TextWithMaxChar>
              </Box>
              <Box d="flex" alignItems="flex-start" justifyContent="center">
                <Box d="flex" flexDir="column" alignItems="flex-end">
                  <Text as="span" textAlign="center" fontSize="1.9rem" fontWeight="700">
                    {formatCurrency(
                      item.priceWithToppings * item.productQuantity,
                      rappiTurbo?.client?.language,
                    )}
                  </Text>
                </Box>
                {screen === 'bag' && (
                  <Box size="xs" w="auto" h="auto" mt={1} pl={2} pr={0} py={0}>
                    <Icon as={FiMoreVertical} size="2rem" />
                  </Box>
                )}
              </Box>
            </Box>

            {screen === 'bag' && (
              <Box d="flex" alignItems="flex-start" flexDir="column">
                {item.selectedToppings?.map((selectedTopping: any, tcIdx: number) => (
                  <React.Fragment key={`${selectedTopping.id}-${tcIdx}`}>
                    {selectedTopping.toppings.map((topping: any, tIdx: number) => (
                      <Box
                        w="100%"
                        key={`${topping.id}-${tIdx}`}
                        d="flex"
                        justifyContent="space-between"
                      >
                        <Box d="flex" justifyContent="center" alignItems="center">
                          <Box
                            fontSize="1rem"
                            fontWeight="bold"
                            background="#919AAA20"
                            h="17px"
                            w="20px"
                            d="flex"
                            justifyContent="center"
                            alignItems="center"
                            lineHeight="14px"
                            borderRadius="2px"
                          >
                            {topping.quantity}
                          </Box>
                          <Text key={topping.id} fontSize="sm" ml="5px">
                            {topping.description}
                          </Text>
                        </Box>
                      </Box>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            )}
          </ListItem>

          {screen === 'bag' && hasUnfilledRequiredToppings(item) && (
            <Box
              cursor="pointer"
              key={`${item.product_id}-${index}-info`}
              fontSize="1rem"
              fontWeight="bold"
              background="#FFBA42"
              h="auto"
              w="auto"
              d="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="10px"
              mt={5}
              pt="5px"
              pb="5px"
              onClick={() => handleOnGoToEditItem(item)}
            >
              <Icon ml="8px" mr="8px" color="white" as={MdInfoOutline} size="2rem" />
              <Text fontSize="sm" color="white">
                {translate.getText('required_complements_info')}
              </Text>
              <Icon ml="5px" mr="5px" color="white" as={BiChevronRight} size="2.6rem" />
            </Box>
          )}
        </div>
      ))}
    </Stack>
  );
};

export default OrderItems;
