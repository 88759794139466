import { ColorModeProvider, CSSReset, ThemeProvider as ChakraThemeProvider } from '@chakra-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import history from '~/routes/history';
import { persistor, store } from '~/store';
import GlobalStyles from '~/styles/global';
import theme from '~/styles/theme';

const AppContainer: React.FC = ({ children }) => {
  return (
    <ChakraThemeProvider theme={theme}>
      <ColorModeProvider value="light">
        <EmotionThemeProvider theme={theme}>
          <Provider store={store}>
            {/* @ts-ignore */}
            <PersistGate persistor={persistor}>
              {/* @ts-ignore */}
              <ConnectedRouter history={history}>{children}</ConnectedRouter>
              <CSSReset />
              <GlobalStyles />
              <ToastContainer autoClose={5000} position="bottom-center" />
            </PersistGate>
          </Provider>
        </EmotionThemeProvider>
      </ColorModeProvider>
    </ChakraThemeProvider>
  );
};

export default AppContainer;
