import { Box, Stack, StackProps, Text } from '@chakra-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import LazyImage from '~/components/ProductItem/LazyImage';
import { LineClampedText } from '~/components/ProductItem/styles';
import { RootState } from '~/store';
import { formatCurrency } from '~/utils/currency';

export interface ProductItemProps extends StackProps {
  product: any;
}

const ProductItem: React.FC<ProductItemProps> = ({ product, ...rest }: ProductItemProps): any => {
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;

  return (
    <Stack
      {...rest}
      isInline
      px="2.5rem"
      pt="1rem"
      spacing={4}
      d="flex"
      w="100%"
      minH={product?.file ? '10rem' : 'auto'}
      justifyContent="space-between"
    >
      <Box d="flex" w="100%" flexDir="column">
        <Box d="flex" w="100%" flexDir="row" justifyContent="space-between">
          <Box
            d="flex"
            flexDir="column"
            alignItems="flex-start"
            w={product?.file?.url ? '75%' : '100%'}
          >
            <LineClampedText
              as="h4"
              mb={8}
              fontWeight="400"
              fontSize="1.7rem"
              title={product?.name}
            >
              {product?.name}
            </LineClampedText>
            <LineClampedText
              as="p"
              color="gray.500"
              fontWeight="400"
              mb={4}
              mr={17}
              fontSize="sm"
              title={product?.description}
            >
              {product?.description}
            </LineClampedText>
            {product?.price > 0 && (
              <Box mt={4}>
                <Text as="span" mr={4} fontSize="1.7rem" fontWeight="700">
                  {formatCurrency(product?.price, rappiTurbo?.client?.language)}
                </Text>
              </Box>
            )}
          </Box>
          {product?.image && (
            <LazyImage
              src={`https://${rappiTurbo?.endpoint
                .replace('microservices', 'images')
                .replace('services', 'images')}/products/${product?.image}?d=300x300&e=webp&q=50`}
              flex="1"
              ml={3}
              w="10rem"
              h="10rem"
              borderRadius="1rem"
              objectFit="cover"
            />
          )}
        </Box>
      </Box>
    </Stack>
  );
};

const arePropsEqual = (prevProps: ProductItemProps, nextProps: ProductItemProps) =>
  prevProps.product?.id === nextProps.product?.id &&
  prevProps.product?.isAvailable === nextProps.product?.isAvailable;

export default React.memo(ProductItem, arePropsEqual);
