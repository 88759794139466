import { en } from '~/i18n/en';
import { es } from '~/i18n/es';
import { pt_BR } from '~/i18n/pt_BR';

type UseTranslate = {
  getText: (type: string) => string;
};

export default function useTranslate(language: string): UseTranslate {
  let selectedLanguageTexts = pt_BR;
  switch (language) {
    case 'es':
      selectedLanguageTexts = es;
      break;
    case 'en':
      selectedLanguageTexts = en;
      break;
    default:
      selectedLanguageTexts = pt_BR;
      break;
  }

  return {
    getText: (type: string) => {
      return selectedLanguageTexts[type] || '-';
    },
  };
}
