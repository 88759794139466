import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import Layout from '~/components/Layout';
import { rappiStoreMenu } from '~/routes/routeMap';
import { RootState } from '~/store';

export interface RouteWrapperProps extends RouteProps {
  isPrivate?: boolean;
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({
  isPrivate = false,
  path,
  component: Component,
  ...rest
}) => {
  const globalState = useSelector((state: RootState) => state);
  // const isLoggedin = globalState?.auth?.isLoggedin;
  const isLoggedin = true;

  if (isPrivate && !isLoggedin) {
    return <Redirect to={rappiStoreMenu} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;
