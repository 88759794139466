import { BoxProps } from '@chakra-ui/core';
import React from 'react';

import { BackButton } from './styles';

const GoBackButton: React.FC<BoxProps> = ({ onClick }) => {
  return (
    <BackButton
      onClick={onClick}
      bg="gray.100"
      aria-label="Fechar"
      icon="arrow-back"
      rounded="50%"
      size="lg"
    />
  );
};

export default GoBackButton;
