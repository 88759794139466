import { Box, Image, InputGroup, Spinner, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Input from '~/components/Form/Input';
import GoToButton from '~/components/GoToButton';
import useConstant from '~/hooks/useConstant';
import useTranslate from '~/hooks/useTranslate';
import { AddressComponent, GetAddressList, Prediction } from '~/models/AutoComplete';
import AutoCompleteItem from '~/pages/Addresses/SearchAutocompleteAddress/AutoCompleteItem';
import { bag, confirmAddress } from '~/routes/routeMap';
import { getAddressDetails, getAddressList, getLatLngByAddressFields } from '~/services/address';
import { RootState } from '~/store';
import { AddressFields, getFieldFromAddressComponents } from '~/utils/address';
import { debounce } from '~/utils/debounce';
import { onlyNumbers } from '~/utils/masks';

import ConfirmPredictionForm from '../ConfirmPredictionForm';

export interface ConfirmAddressFields {
  number?: string;
  complement?: string;
  neighborhood?: string;
  zip_code?: string;
}

const SearchAutocompleteAddress: React.FC = () => {
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const translate = useTranslate(rappiTurbo?.client?.language);

  const [autocompletedAddress, setAutocompletedAddress] = useState<GetAddressList>();
  const [selectedPrediction, setSelectedPrediction] = useState<Prediction>(null);
  const [selectedAddressLocation, setSelectedAddressLocation] =
    useState<google.maps.LatLngLiteral>(null);
  const [selectedAddressComponents, setSelectedAddressComponents] = useState<AddressComponent[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [loadingSelection, setLoadingSelection] = useState(false);

  const onChange = async (value: string) => {
    setLoading(true);
    if (value === '') {
      setLoading(false);
      setAutocompletedAddress(null);
    } else {
      const addressList = await getAddressList(value);
      setAutocompletedAddress(addressList);
    }
    setLoading(false);
  };

  const debouncedOnChange = useConstant(() => debounce(onChange));

  const onSubmitSelectedPrediction = useCallback(
    async (data: ConfirmAddressFields) => {
      try {
        setLoadingSelection(true);
        const street = getFieldFromAddressComponents(
          AddressFields.STREET,
          selectedAddressComponents,
        );

        const city = getFieldFromAddressComponents(AddressFields.CITY, selectedAddressComponents);
        const uf = getFieldFromAddressComponents(AddressFields.STATE, selectedAddressComponents);

        if (selectedPrediction.types.includes('point_of_interest')) {
          const zipcode = getFieldFromAddressComponents(
            AddressFields.ZIPCODE,
            selectedAddressComponents,
          );
          const neighborhood = getFieldFromAddressComponents(
            AddressFields.NEIGHBORHOOD,
            selectedAddressComponents,
          );
          const number = getFieldFromAddressComponents(
            AddressFields.NUMBER,
            selectedAddressComponents,
          );

          dispatch(
            push(confirmAddress, {
              addressDetails: {
                id: rappiTurbo?.client?.address?.id,
                street: selectedPrediction.structured_formatting.main_text,
                number:
                  data?.number?.trim() !== ''
                    ? data?.number
                    : number?.trim() !== ''
                    ? number
                    : 'S/N',
                complement: data?.complement,
                neighborhood: neighborhood.trim() !== '' ? neighborhood : 'N/A',
                city,
                state: uf,
                zip_code: zipcode.trim() !== '' ? onlyNumbers(zipcode) : 'N/A',
                latitude: selectedAddressLocation.lat,
                longitude: selectedAddressLocation.lng,
              },
            }),
          );
          return;
        }

        const foundLocation = await getLatLngByAddressFields({
          id: rappiTurbo?.client?.address?.id,
          street,
          number: data?.number,
          neighborhood: data.neighborhood,
          city,
          state: uf,
          zip_code: onlyNumbers(data.zip_code),
        });

        dispatch(
          push(confirmAddress, {
            addressDetails: {
              id: rappiTurbo?.client?.address?.id,
              street,
              number: data?.number?.trim() === '' ? 'S/N' : data?.number,
              neighborhood: data.neighborhood,
              complement: data.complement,
              city,
              state: uf,
              zip_code: onlyNumbers(data.zip_code),
              latitude: foundLocation.latitude,
              longitude: foundLocation.longitude,
            },
          }),
        );
      } catch (err: any) {
        const message = err.response?.data?.error?.message;
        if (message) {
          toast.error(message);
        } else {
          toast.error(translate.getText('error_searching_address'));
        }
      } finally {
        setLoadingSelection(false);
      }
    },
    [dispatch, selectedAddressComponents, selectedAddressLocation, selectedPrediction],
  );

  const handleOnSelectPrediction = useCallback(async (item: Prediction) => {
    try {
      setLoadingSelection(true);
      const details = await getAddressDetails(item.place_id);
      const addressComponents = details.result.address_components;
      const addressLocation = details.result.geometry.location;
      setSelectedPrediction(item);
      setSelectedAddressComponents(addressComponents);
      setSelectedAddressLocation(addressLocation);
    } catch (err: any) {
      toast.error(translate.getText('error_selected_address'));
    } finally {
      setLoadingSelection(false);
    }
  }, []);

  const onCloseBottomBar = useCallback(() => {
    setSelectedPrediction(null);
    setSelectedAddressComponents([]);
    setSelectedAddressLocation(null);
  }, []);

  return (
    <Box
      px="3rem"
      pt="3rem"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDir="column"
    >
      <Box pb={8} display="flex" alignItems="center" justifyContent="flex-start" w="100%">
        <GoToButton to={bag} />
        <Text
          as="span"
          fontSize="lg"
          fontWeight="bold"
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="100%"
          pr="1rem"
        >
          {translate.getText('address_title')}
        </Text>
      </Box>
      <Box w="100%" my="10px">
        <InputGroup>
          <Input
            p="20px 20px 20px 20px"
            placeholder={translate.getText('address_with_number')}
            name="address-kuppi-search"
            autoFocus
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              debouncedOnChange(e.target.value.toLowerCase());
            }}
          />
        </InputGroup>
        <Box w="100%" d="flex" justifyContent="flex-end" mt="1rem">
          <Image src="/images/powered-by-google.png" w="109.3px" h="15px" />
        </Box>
      </Box>

      {loading ? (
        <Box py="5rem">
          <Spinner w="3.5rem" h="3.5rem" color="green.600" />
        </Box>
      ) : (
        <Stack spacing={3} w="100%" mt=".5rem">
          {autocompletedAddress?.predictions?.length > 0 &&
            autocompletedAddress?.predictions?.map((addrs, index) => (
              <AutoCompleteItem
                key={index}
                item={addrs}
                onClick={() => handleOnSelectPrediction(addrs)}
              />
            ))}
          {autocompletedAddress && autocompletedAddress?.predictions?.length == 0 && (
            <Text as="span" fontSize="lg" fontWeight="normal" textAlign="center" w="100%" mt={10}>
              {translate.getText('address_not_found')}
            </Text>
          )}
        </Stack>
      )}

      {selectedPrediction && (
        <ConfirmPredictionForm
          onSubmitSelectedPrediction={onSubmitSelectedPrediction}
          onCloseBottomBar={onCloseBottomBar}
          loading={loadingSelection}
          selectedPrediction={{
            mainText: selectedPrediction?.structured_formatting?.main_text,
            secondaryText: selectedPrediction.structured_formatting?.secondary_text,
          }}
          selectedAddressComponents={selectedAddressComponents}
        />
      )}
    </Box>
  );
};

export default SearchAutocompleteAddress;
