import { Box, Heading, Image, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import GoBackButton from '~/components/GoBackButton';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import NumberCounter from '~/components/NumberCounter';
import OrderItems from '~/components/OrderItems';
import { TextWithMaxChar } from '~/components/OrderItems/style';
import OrderTotals from '~/components/OrderTotals';
import useTranslate from '~/hooks/useTranslate';
import { ConfirmAddressInputs } from '~/models/Address';
import CheckoutData, { CheckoutProduct, CheckoutProductComplement } from '~/models/CheckoutData';
import { ConfirmUserInputs } from '~/models/Client';
import ConfirmAddressForm from '~/pages/Bag/ConfirmAddressForm';
import ConfirmUserDataForm from '~/pages/Bag/ConfirmUserDataForm';
import { orderReview, productDetail, rappiStoreMenu } from '~/routes/routeMap';
import { RootState } from '~/store';
import BagActions from '~/store/ducks/bag';
import RappiTurboActions from '~/store/ducks/rappiTurbo';
import { formatCurrency } from '~/utils/currency';

const { initializateCheckout, updateClientAddress, clearSuccess } = RappiTurboActions;
const { edditingToBag, removingFromTheBag, cleanBag, setCheckoutProducts } = BagActions;

interface UseLocationProps {
  openConfirmDataModal?: boolean;
}

const Bag: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isOpen: isOpenUserData,
    onOpen: onOpenUserData,
    onClose: onCloseUserData,
  } = useDisclosure();
  const {
    isOpen: isOpenAddressData,
    onOpen: onOpenAddressData,
    onClose: onCloseAddressData,
  } = useDisclosure();
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const rappiStore = rappiTurbo?.store;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const { itemsInBag, priceWithoutDiscount } = globalState?.bag;
  const location = useLocation<UseLocationProps>();
  const openConfirmDataModal = location?.state?.openConfirmDataModal;

  const [isEditingItem, setIsEditingItem] = useState<boolean>(false);
  const [itemInEditing, setItemInEditing] = useState<any>();
  const [itemInEditingCount, setItemInEditingCount] = useState<number>(null);
  const [totalBag, setTotalBag] = useState<number>(null);
  const [canContinueWithOrder, setCanContinueWithOrder] = useState<boolean>(true);

  useEffect(() => {
    if (itemsInBag.length === 0) {
      dispatch(push(rappiStoreMenu));
    }
  }, [itemsInBag, dispatch]);

  useEffect(() => {
    setTotalBag(priceWithoutDiscount);
  }, [itemsInBag, priceWithoutDiscount]);

  useEffect(() => {
    if (rappiTurbo?.updateAddressSuccess) {
      onCloseAddressData();
      onOpenUserData();
      dispatch(clearSuccess());
    }
  }, [rappiTurbo?.updateAddressSuccess]);

  useEffect(() => {
    if (rappiTurbo?.initializateCheckoutSuccess) {
      onCloseUserData();
      dispatch(push(orderReview));
      dispatch(clearSuccess());
    }
  }, [rappiTurbo?.initializateCheckoutSuccess]);

  useEffect(() => {
    if (openConfirmDataModal) {
      onCloseAddressData();
      onOpenUserData();
    }
  }, [openConfirmDataModal]);

  const clearBag = useCallback(() => {
    dispatch(cleanBag());
  }, [dispatch]);

  const handleOnEditingItem = useCallback(
    (index: number) => {
      setIsEditingItem((prevState) => !prevState);
      setItemInEditing(itemsInBag[index]);
      setItemInEditingCount(itemsInBag[index].productQuantity);
    },
    [itemsInBag],
  );

  const handleOnGoToEditItem = (itemInBag: any) => {
    const productDetailRoute = productDetail.replace(':product_id', itemInBag.product_id);
    dispatch(
      push(productDetailRoute, {
        product: itemInBag,
        mode: 'edditing',
      }),
    );
  };

  const handleCleanEditingItem = useCallback(() => {
    setIsEditingItem(false);
    setItemInEditing(null);
    setItemInEditingCount(null);
    onCloseAddressData();
    onCloseUserData();
  }, [onCloseAddressData, onCloseUserData]);

  const currentCountProduct = (count: number) => {
    setItemInEditingCount(count);
  };

  const handleEditingItemQuantity = useCallback(
    (item: any) => {
      const currentItem = { ...item };
      currentItem.productQuantity = itemInEditingCount;
      dispatch(edditingToBag(currentItem));
      setIsEditingItem((prevState) => !prevState);
    },
    [dispatch, itemInEditingCount],
  );

  const handleRemovingItemQuantity = useCallback(
    (productBagId: number) => {
      dispatch(removingFromTheBag(productBagId));
      setIsEditingItem((prevState) => !prevState);
    },
    [dispatch],
  );

  const handleOnClickBackConfirmUserData = useCallback(() => {
    onCloseUserData();
    if (!isOpenAddressData) {
      onOpenAddressData();
      return;
    }
  }, [onOpenAddressData, isOpenAddressData, onCloseUserData]);

  const hasUnfilledRequiredToppings = (item: any) => {
    const isRequiredToppingsFullfiled = item?.selectedToppings
      ?.filter((tc: any) => tc.min_toppings_for_categories > 0)
      .every((tc: any) => {
        return tc.toppings.length === 0 ? false : true;
      });

    if (canContinueWithOrder && isRequiredToppingsFullfiled !== canContinueWithOrder) {
      setCanContinueWithOrder(isRequiredToppingsFullfiled);
    }
    return !isRequiredToppingsFullfiled;
  };

  const onSubmitConfirmUserData = useCallback(
    (userConfirmationData: ConfirmUserInputs) => {
      const products: CheckoutProduct[] = itemsInBag.map((item: any) => {
        let toppings: CheckoutProductComplement[] = [];

        item?.selectedToppings?.forEach((toppingCategory: any) => {
          toppingCategory?.toppings?.map((topping: any) => {
            if (topping?.quantity > 0) {
              toppings.push({
                id: Number(topping?.id),
                units: topping?.quantity,
              });
            }
          });
        });

        return {
          id: Number(item.product_id),
          units: item.productQuantity,
          toppings: toppings,
        };
      });

      dispatch(setCheckoutProducts(products));

      const checkoutData: CheckoutData = {
        client: userConfirmationData,
        products: products,
      };

      dispatch(initializateCheckout(checkoutData));
    },
    [dispatch, itemsInBag],
  );

  const handleOnClickConfirmAddressData = useCallback(() => {
    if (!isOpenAddressData) {
      onOpenAddressData();
      return;
    }
  }, [onOpenAddressData, isOpenAddressData]);

  const onSubmitConfirmAddressData = useCallback(
    (addressConfirmationData: ConfirmAddressInputs) => {
      dispatch(updateClientAddress(addressConfirmationData));
    },
    [dispatch],
  );

  if (itemsInBag.length === 0) {
    return (
      <Box h="100%" w="100%" d="flex" alignItems="center" justifyContent="center">
        <Spinner size="xl" color="#01F43A" />
      </Box>
    );
  }

  return (
    <Box as="main" flexDir="column" w="100%" px="3rem" pb={40} bg="white">
      <Box
        d="flex"
        flexDir="column"
        w="100%"
        alignContent={'center'}
        borderBottom={'1px solid #ECEFF3'}
        pb="14px"
      >
        <Image
          mt="16px"
          alignSelf="center"
          src={translate.getText('selled_delivery_rappi_img')}
          w="60%"
          objectFit="cover"
        />
      </Box>
      <HeaderWithBackButton
        justifyContent="center"
        alignItems="center"
        fontSize={'xl'}
        headerTitle={
          <Box d="flex" w="100%" ml="auto" alignItems="center" justifyContent="space-between">
            <Box />
            <Text>{translate.getText('bag_title')}</Text>
            <Button p="0" m="0" background="transparent" onClick={clearBag}>
              <Text fontWeight="300" fontSize="1em" color="red.300">
                {translate.getText('bag_clean')}
              </Text>
            </Button>
          </Box>
        }
        to={rappiStoreMenu}
      />
      <Box d="flex" w="100%" mb={3} ml="50px">
        <Image
          src={rappiStore?.logo}
          w="6rem"
          h="6rem"
          mr={2}
          backgroundColor="white"
          borderRadius="50%"
          objectFit="cover"
          border="1px solid #EFEFEF"
          onClick={() => dispatch(push(rappiStoreMenu))}
        />
        <Stack spacing={1} onClick={() => dispatch(push(rappiStoreMenu))} w="auto" mt={3}>
          <Heading as="h2" fontSize="lg">
            {`${rappiStore?.name} ${translate.getText('store_name')}`}
          </Heading>
          <Text fontSize="sm" cursor="pointer" color="#2D6E3D">
            {translate.getText('bag_add_more_items')}
          </Text>
        </Stack>
      </Box>

      <OrderItems
        screen="bag"
        handleOnEditingItem={handleOnEditingItem}
        handleOnGoToEditItem={handleOnGoToEditItem}
        hasUnfilledRequiredToppings={hasUnfilledRequiredToppings}
      />

      <OrderTotals screen="bag" totalAmount={totalBag} />

      <BottomBar
        py={5}
        px={2}
        isOverlayVisible={isEditingItem || isOpenAddressData || isOpenUserData}
        onClose={handleCleanEditingItem}
      >
        {isEditingItem ? (
          <Stack spacing={8} textAlign="center" py={4}>
            <Box d="flex" flexDir="row" pb="20px" pl={3} borderBottom={'1px solid #ECEFF3'} mb={5}>
              <GoBackButton onClick={handleCleanEditingItem} />
              <TextWithMaxChar fontWeight="700" fontSize="lg" ml={10} mt={2} maxW="23ch">
                {itemInEditing?.name}
              </TextWithMaxChar>
            </Box>
            <Button
              alignSelf={'center'}
              px="3rem"
              borderRadius="50px"
              type="button"
              w="95%"
              py={8}
              fontSize="md"
              color="black"
              variant="ghost"
              boxShadow="5px 5px 16px rgba(0, 0, 0, 0.15)"
              mt={2}
              onClick={() => {
                const productDetailRoute = productDetail.replace(
                  ':product_id',
                  itemInEditing.product_id,
                );
                dispatch(
                  push(productDetailRoute, {
                    product: itemInEditing,
                    mode: 'edditing',
                  }),
                );
              }}
            >
              {translate.getText('see_details')}
            </Button>
            <Stack isInline align="center" ml={5} spacing={4} w="95%">
              <NumberCounter
                onChangeCount={currentCountProduct}
                canReduceUpTo={0}
                py={8}
                flex="2"
                initialCount={itemInEditing?.productQuantity}
              />
              <Button
                bg="green.300"
                d="flex"
                flexDir="column"
                alignItems="center"
                color="white"
                px="3rem"
                w="55%"
                borderRadius="50px"
                fontSize="lg"
                justifyContent="space-between"
                onClick={
                  itemInEditingCount === 0
                    ? () => handleRemovingItemQuantity(itemInEditing.bagId)
                    : () => handleEditingItemQuantity(itemInEditing)
                }
              >
                <span>
                  {itemInEditingCount === 0
                    ? translate.getText('delete_item')
                    : translate.getText('update_item')}
                </span>{' '}
                <span>
                  {formatCurrency(
                    itemInEditing.priceWithToppings * itemInEditingCount,
                    rappiTurbo?.client?.language,
                  )}
                </span>
              </Button>
            </Stack>
          </Stack>
        ) : isOpenUserData ? (
          <ConfirmUserDataForm
            isVisible={isOpenUserData}
            onClickBack={handleOnClickBackConfirmUserData}
            onFormSubmit={onSubmitConfirmUserData}
          />
        ) : (
          <ConfirmAddressForm
            isVisible={isOpenAddressData}
            canContinueWithOrder={canContinueWithOrder}
            onClickBack={onCloseAddressData}
            onClickConfirm={handleOnClickConfirmAddressData}
            onFormSubmit={onSubmitConfirmAddressData}
          />
        )}
      </BottomBar>
    </Box>
  );
};

export default Bag;
