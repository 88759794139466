import { Badge, Box, BoxProps, Heading, IconButton, Stack, Text } from '@chakra-ui/core';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CircleToggle from '~/components/CircleToggle';
import ListItem from '~/components/ListItem';
import useTranslate from '~/hooks/useTranslate';
import SelectedTopping from '~/models/SelectedTopping';
import { RootState } from '~/store';
import { ScrollSmoothTop } from '~/styles/animations/ScrollSmooth';
import { formatCurrency } from '~/utils/currency';
import numToWords from '~/utils/numToWords';

export interface ToppingsMenuProps extends BoxProps {
  toppingCategories?: any[];
  selectedToppings?: any[];
  onChangeToppingOptions?: (toppingCategories: SelectedTopping[]) => void;
}

const ToppingsMenu: React.FC<ToppingsMenuProps> = ({
  toppingCategories,
  selectedToppings,
  onChangeToppingOptions,
  ...rest
}) => {
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const [toppingOptions, setToppingOptions] = useState<SelectedTopping[]>(
    toppingCategories?.map((toppingCategory: SelectedTopping) => {
      const selectedToppingCategory: any = selectedToppings?.find(
        (stc: any) => stc.id === toppingCategory.id,
      );

      if (!selectedToppingCategory) {
        return {
          id: toppingCategory.id,
          description: toppingCategory.description,
          min_toppings_for_categories: toppingCategory.min_toppings_for_categories,
          max_toppings_for_categories: toppingCategory.max_toppings_for_categories,
          is_required: toppingCategory.min_toppings_for_categories > 0,
          toppings: toppingCategory.toppings.map((topping: any) => ({
            id: topping.id,
            description: topping.description,
            price: topping.price,
            quantity: 0,
          })),
        };
      }

      return {
        id: toppingCategory.id,
        description: toppingCategory.description,
        min_toppings_for_categories: toppingCategory.min_toppings_for_categories,
        max_toppings_for_categories: toppingCategory.max_toppings_for_categories,
        is_required: toppingCategory.min_toppings_for_categories > 0,
        toppings: toppingCategory.toppings.map((topping: any) => {
          const selectedTopping = selectedToppingCategory.toppings.find(
            (selectedTopping: any) => selectedTopping.id === topping.id,
          );

          if (!selectedTopping) {
            return {
              id: topping.id,
              description: topping.description,
              price: topping.price,
              quantity: 0,
            };
          }

          return {
            id: topping.id,
            description: topping.description,
            price: topping.price,
            quantity: selectedTopping.quantity,
          };
        }),
      };
    }),
  );

  const [toppingCategoryRefs, setToppingCategoryRefs] = useState<
    React.MutableRefObject<HTMLDivElement>[]
  >([]);

  useEffect(() => {
    setToppingCategoryRefs((prevRefs) =>
      Array(toppingOptions?.length)
        .fill(undefined)
        .map((_, index) => prevRefs[index] || createRef()),
    );
  }, [toppingOptions]);

  useEffect(() => {
    setToppingOptions(() => {
      return toppingCategories?.map((toppingCategory: SelectedTopping) => {
        const selectedToppingCategory = selectedToppings?.find(
          (stc: any) => stc.id === toppingCategory.id,
        );

        if (!selectedToppingCategory) {
          return {
            id: toppingCategory.id,
            description: toppingCategory.description,
            min_toppings_for_categories: toppingCategory.min_toppings_for_categories,
            max_toppings_for_categories: toppingCategory.max_toppings_for_categories,
            is_required: toppingCategory.min_toppings_for_categories > 0,
            toppings: toppingCategory.toppings.map((topping: any) => ({
              id: topping.id,
              description: topping.description,
              price: topping.price,
              quantity: 0,
            })),
          };
        }

        return {
          id: toppingCategory.id,
          description: toppingCategory.description,
          min_toppings_for_categories: toppingCategory.min_toppings_for_categories,
          max_toppings_for_categories: toppingCategory.max_toppings_for_categories,
          is_required: toppingCategory.min_toppings_for_categories > 0,
          toppings: toppingCategory.toppings.map((topping: any) => {
            const selectedTopping = selectedToppingCategory.toppings.find(
              (selectedTopping: any) => selectedTopping.id === topping.id,
            );

            if (!selectedTopping) {
              return {
                id: topping.id,
                description: topping.description,
                price: topping.price,
                quantity: 0,
              };
            }

            return {
              id: topping.id,
              description: topping.description,
              price: topping.price,
              quantity: selectedTopping.quantity,
            };
          }),
        };
      });
    });
  }, [toppingCategories]);

  useEffect(() => {
    onChangeToppingOptions && onChangeToppingOptions(toppingOptions);
  }, [onChangeToppingOptions, JSON.stringify(toppingOptions)]);

  function handleScrollIntoToppingCategories(topping_category_index: number, toppingsSum: number) {
    // Scroll to the next complement with a delay
    setTimeout(() => {
      if (
        toppingOptions[topping_category_index].is_required &&
        toppingOptions[topping_category_index].max_toppings_for_categories === toppingsSum &&
        topping_category_index >= 0 &&
        topping_category_index + 1 <= toppingOptions?.length - 1
      ) {
        ScrollSmoothTop({
          ref: toppingCategoryRefs[topping_category_index + 1],
          complementsScroll: true,
        });
      }
    }, 400);
  }

  const markItemAsSelected = (
    topping_category_id: number,
    topping_id: number,
    toppingCategoryRefPosition?: number,
  ) => {
    setToppingOptions((prevState) => {
      const toppingCategory = prevState.find((tc) => tc.id === topping_category_id);
      const topping = toppingCategory.toppings.find((t) => t.id === topping_id);

      toppingCategory.toppings?.forEach((t) => {
        if (t.id === topping.id) {
          t.quantity = 1;
        } else {
          t.quantity = 0;
        }
      });
      const newState = prevState.map((prevToppingCategory) => {
        if (prevToppingCategory.id === topping_category_id) {
          return toppingCategory;
        }
        return prevToppingCategory;
      });
      return newState;
    });
    handleScrollIntoToppingCategories(toppingCategoryRefPosition, 1);
  };

  const onChangeQuantity = useCallback(
    (
      topping_category_id: number,
      topping_id: number,
      shouldIncrease: boolean,
      toppingCategoryRefPosition?: number,
    ) => {
      const toppingCategory = toppingOptions.find((tc) => tc.id === topping_category_id);
      const topping = toppingCategory.toppings?.find((t) => t.id === topping_id);
      let toppingsSum = 0;
      toppingCategory.toppings?.forEach((t) => {
        toppingsSum += t.quantity;
      });
      if (shouldIncrease && toppingsSum < toppingCategory.max_toppings_for_categories) {
        topping.quantity += 1;
        toppingsSum += 1;
      } else if (!shouldIncrease && toppingsSum !== 0) {
        topping.quantity -= 1;
        toppingsSum -= 1;
      }

      const newState = toppingOptions?.map((prevToppingCategory) => {
        if (prevToppingCategory.id === topping_category_id) {
          return toppingCategory;
        }
        return prevToppingCategory;
      });

      setToppingOptions(newState);
      handleScrollIntoToppingCategories(toppingCategoryRefPosition, toppingsSum);
    },
    [toppingOptions],
  );

  const getCurrentQuantity = useCallback(
    (topping_category_id: number) => {
      const toppingCategory = toppingOptions.find((tc) => tc.id === topping_category_id);
      let toppingsSum = 0;
      toppingCategory.toppings?.forEach((t) => {
        toppingsSum += t.quantity;
      });
      return Number(toppingsSum);
    },
    [toppingOptions],
  );

  const getIsRequiredMaxToppingMoreThanOneText = (
    toppingOptionId: number,
    maxToppingCategory: number,
  ) => {
    const text = translate.getText('topping_required_max_more_than_one');
    return text
      .replace('@currQty@', `${getCurrentQuantity(toppingOptionId)}`)
      .replace('@maxQty@', `${maxToppingCategory}`);
  };

  const getIsRequiredMaxToppingMoreThanOneMinObsText = (minToppingCategory: number) => {
    let text = translate.getText('topping_required_max_more_than_one_min_obs_one');
    switch (minToppingCategory) {
      case 1:
        text = translate.getText('topping_required_max_more_than_one_min_obs_one');
        break;
      case 2:
        text = translate.getText('topping_required_max_more_than_one_min_obs_two');
      default:
        text = translate.getText('topping_required_max_more_than_one_min_obs_more');
        break;
    }

    const one =
      rappiTurbo?.client?.language === 'es'
        ? 'uno'
        : rappiTurbo?.client?.language === 'en'
        ? 'one'
        : 'uma';
    const two =
      rappiTurbo?.client?.language === 'es'
        ? 'dos'
        : rappiTurbo?.client?.language === 'en'
        ? 'two'
        : 'duas';
    const replaceText =
      minToppingCategory === 1
        ? one
        : minToppingCategory === 2
        ? two
        : numToWords(minToppingCategory, rappiTurbo?.client?.language);

    return text.replace('@option@', replaceText);
  };

  const getIsntRequiredMaxToppingMoreThanOneText = (maxToppingCategory: number) => {
    const text = translate.getText('topping_not_required_max_more_than_one');
    return text.replace('@maxQty@', `${maxToppingCategory}`);
  };

  return (
    <Box {...rest}>
      {toppingOptions.map((toppingOption, idx) => (
        <Stack spacing={0} key={toppingOption.id} ref={toppingCategoryRefs[idx]}>
          <ListItem justifyContent="space-between" flexDir="row" alignItems="center">
            <Heading as="h4" fontSize="md" fontWeight="700" d="flex" flexDir="column">
              {toppingOption.description}
              {toppingOption.is_required && toppingOption.max_toppings_for_categories > 1 && (
                <>
                  <Text as="span" fontSize="sm" fontWeight="300" mt="5px">
                    {getIsRequiredMaxToppingMoreThanOneText(
                      toppingOption.id,
                      toppingOption.max_toppings_for_categories,
                    )}
                  </Text>

                  {getCurrentQuantity(toppingOption.id) <
                    toppingOption.min_toppings_for_categories && (
                    <Text as="span" fontSize="sm" fontWeight="300" mt="5px" color="#FF6C6C">
                      {getIsRequiredMaxToppingMoreThanOneMinObsText(
                        toppingOption.min_toppings_for_categories,
                      )}
                    </Text>
                  )}
                </>
              )}
              {!toppingOption.is_required && toppingOption.max_toppings_for_categories > 1 && (
                <Text as="span" fontSize="sm" fontWeight="300" mt="5px">
                  {getIsntRequiredMaxToppingMoreThanOneText(
                    toppingOption.max_toppings_for_categories,
                  )}
                </Text>
              )}
              {toppingOption.is_required && toppingOption.max_toppings_for_categories === 1 && (
                <Text as="span" fontSize="sm" fontWeight="300" mt="5px">
                  {translate.getText('topping_required_max_one')}
                </Text>
              )}
              {!toppingOption.is_required && toppingOption.max_toppings_for_categories === 1 && (
                <Text as="span" fontSize="sm" fontWeight="300" mt="5px">
                  {translate.getText('topping_not_required_max_one')}
                </Text>
              )}
            </Heading>
            {toppingOption.is_required && (
              <Badge
                variant="subtle"
                bg="#FF8742"
                color="white"
                px={2}
                py={1}
                fontSize="1.1rem"
                fontWeight="700"
              >
                {translate.getText('required')}
              </Badge>
            )}
          </ListItem>
          {toppingOption.toppings?.map((topping) =>
            toppingOption.max_toppings_for_categories > 1 ? (
              <ListItem
                d="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDir="row"
                key={topping.id}
              >
                <Box d="flex" flexDir="column">
                  <Text as="span" fontSize="md" mb={2}>
                    {topping.description}
                  </Text>

                  {topping.price > 0 && (
                    <Text as="span" fontSize="sm" color="gray.300">
                      + {formatCurrency(topping.price, rappiTurbo?.client?.language)}
                    </Text>
                  )}
                </Box>

                <Stack spacing={4} isInline alignItems="center">
                  {topping.quantity > 0 && (
                    <>
                      <IconButton
                        aria-label="Remover item"
                        icon="minus"
                        stroke="gray.800"
                        size="md"
                        background="transparent"
                        mr="20px"
                        onClick={() => onChangeQuantity(toppingOption.id, topping.id, false, idx)}
                      />
                      <Text fontSize="md">{topping.quantity}</Text>
                    </>
                  )}
                  <IconButton
                    aria-label="Adicionar item"
                    icon="plus-square"
                    stroke="gray.800"
                    size="md"
                    background="transparent"
                    ml="20px"
                    onClick={() => onChangeQuantity(toppingOption.id, topping.id, true, idx)}
                  />
                </Stack>
              </ListItem>
            ) : (
              <ListItem
                key={topping.id}
                d="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDir="row"
                cursor="pointer"
                onClick={() => markItemAsSelected(toppingOption.id, topping.id, idx)}
              >
                <Box d="flex" flexDir="column">
                  <Text as="span" fontSize="md" mb={2}>
                    {topping.description}
                  </Text>
                  {Boolean(topping.price) && (
                    <Text as="span" fontSize="sm" color="gray.300">
                      + {formatCurrency(topping.price, rappiTurbo?.client?.language)}
                    </Text>
                  )}
                </Box>
                <Box d="flex" alignItems="center" justifyContent="center">
                  <CircleToggle isActive={topping.quantity === 1} />
                </Box>
              </ListItem>
            ),
          )}
        </Stack>
      ))}
    </Box>
  );
};

export default ToppingsMenu;
