import { IconButton } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const BackButton = styled(IconButton)`
  height: 3.5rem;
  min-width: 3.5rem;
  svg {
    width: 3rem;
    height: 3rem;
  }
`;
