import { Box, BoxProps } from '@chakra-ui/core';
import { keyframes } from '@emotion/core';
import React from 'react';

const slideRight = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;
export interface ListItemProps extends BoxProps {
  isDisabled?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  children,
  py = 6,
  borderTop = '1px solid',
  borderBottom = '0px solid',
  borderColor = 'gray.100',
  d = 'flex',
  flexDir = 'column',
  isDisabled = false,
  animation = slideRight,
  ...rest
}) => {
  return (
    <Box
      opacity={isDisabled && 0.5}
      py={py}
      borderTop={borderTop}
      borderBottom={borderBottom}
      borderColor={borderColor}
      d={d}
      flexDir={flexDir}
      animation={`${animation} 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ListItem;
