import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  SIMULATE_CONFIRM_PAYMENT = 'SIMULATE_CONFIRM_PAYMENT',
  SIMULATE_CONFIRM_PAYMENT_END = 'SIMULATE_CONFIRM_PAYMENT_END',
}

export interface OnSimulateConfirmPayment extends Action<TypesNames.SIMULATE_CONFIRM_PAYMENT> {}

export interface OnSimulateConfirmPaymentEnd
  extends Action<TypesNames.SIMULATE_CONFIRM_PAYMENT_END> {}

/* ============== ACTION CREATORS AND TYPES ============== */

export const { Types, Creators } = createActions<
  {
    [TypesNames.SIMULATE_CONFIRM_PAYMENT]: string;
    [TypesNames.SIMULATE_CONFIRM_PAYMENT_END]: string;
  },
  {
    simulateConfirmPayment: () => OnSimulateConfirmPayment;
    simulateConfirmPaymentEnd: () => OnSimulateConfirmPaymentEnd;
  }
>({
  simulateConfirmPayment: [],
  simulateConfirmPaymentEnd: [],
});

export const SimulatePaymentTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */

export interface SimulatePaymentType {
  simulateConfirmPaymentLoading: boolean;
}

export const INITIAL_STATE: SimulatePaymentType = {
  simulateConfirmPaymentLoading: false,
};

/* ============== REDUCERS ============== */

export const simulateConfirmPaymentReducer = (
  state = INITIAL_STATE,
  {}: OnSimulateConfirmPayment,
) =>
  produce(state, (draft) => {
    draft.simulateConfirmPaymentLoading = true;
  });

export const simulateConfirmPaymentEndReducer = (
  state = INITIAL_STATE,
  {}: OnSimulateConfirmPaymentEnd,
) =>
  produce(state, (draft) => {
    draft.simulateConfirmPaymentLoading = false;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.SIMULATE_CONFIRM_PAYMENT]: simulateConfirmPaymentReducer,
  [Types.SIMULATE_CONFIRM_PAYMENT_END]: simulateConfirmPaymentEndReducer,
});
