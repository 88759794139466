import { Stack } from '@chakra-ui/core';
import { Divider } from '@chakra-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import useTranslate from '~/hooks/useTranslate';
import BagPriceDescription from '~/pages/Bag/BagPriceDescription';
import { RootState } from '~/store';
import { formatCurrency } from '~/utils/currency';

import BagDeliveryFeeDescription from '../BagDeliveryFeeDescription';

export interface OrderTotalsProps {
  screen: string;
  totalAmount: number;
  deliveryFee?: number;
  serviceFee?: number;
}

const OrderTotals: React.FC<OrderTotalsProps> = ({
  screen,
  totalAmount,
  deliveryFee = 0,
  serviceFee = 0,
}) => {
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const translate = useTranslate(rappiTurbo?.client?.language);
  const orderTotal = totalAmount + serviceFee;
  const deliveryFeeCalc = rappiTurbo?.client?.pro ? 0 : deliveryFee;

  return (
    <Stack as="section" spacing={3} mb={8}>
      <Divider my={6} />
      {screen === 'resume' && (
        <>
          <BagPriceDescription
            mt={5}
            descriptionTitle={translate.getText('sub_total')}
            descriptionPrice={formatCurrency(totalAmount, rappiTurbo?.client?.language)}
            color="gray.300"
            fontSize="1.7rem"
          />
          <BagDeliveryFeeDescription
            descriptionTitle={translate.getText('delivery_fee')}
            descriptionPrice={deliveryFee}
            pro={rappiTurbo?.client?.pro}
            language={rappiTurbo?.client?.language}
          />
          {serviceFee > 0 && (
            <BagPriceDescription
              descriptionTitle={translate.getText('service_fee')}
              descriptionPrice={
                serviceFee > 0 ? formatCurrency(serviceFee, rappiTurbo?.client?.language) : 'Grátis'
              }
              color="gray.300"
              fontSize="1.7rem"
            />
          )}
        </>
      )}
      <BagPriceDescription
        my={6}
        descriptionTitle={translate.getText('total')}
        descriptionPrice={formatCurrency(
          orderTotal + deliveryFeeCalc,
          rappiTurbo?.client?.language,
        )}
        fontWeight="700"
        fontSize="2rem"
      />
    </Stack>
  );
};

export default OrderTotals;
