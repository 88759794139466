import { Box, Divider, Icon, Text } from '@chakra-ui/core';
import { useDisclosure } from '@chakra-ui/core';
import { Image } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import OrderItems from '~/components/OrderItems';
import OrderTotals from '~/components/OrderTotals';
import ProBadge from '~/components/ProBadge';
import useTranslate from '~/hooks/useTranslate';
import { bag, rappiStoreMenu } from '~/routes/routeMap';
import { RootState } from '~/store';
import { formatAddress } from '~/utils/address';

import ConfirmPaymentDataForm from './ConfirmPaymentDataForm';
import { CustomText, Subtitle } from './styles';

const OrderReview: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isOpen: isOpenPaymentData,
    onOpen: onOpenPaymentData,
    onClose: onClosePaymentData,
  } = useDisclosure();
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const client = rappiTurbo?.client;
  const translate = useTranslate(client?.language);
  const { itemsInBag, priceWithoutDiscount, deliveryFee, serviceFee } = globalState?.bag;

  // Verifify if the bag is empty, go to home page
  useEffect(() => {
    if (itemsInBag?.length === 0) {
      dispatch(push(rappiStoreMenu));
    }
  }, []);

  const handleOnClickConfirmResume = useCallback(() => {
    if (!isOpenPaymentData) {
      onOpenPaymentData();
      return;
    }
  }, [onOpenPaymentData, isOpenPaymentData]);

  return (
    <>
      <Box as="main" flexDir="column" w="100%" px="3rem" pb={40} bg="white">
        <Box
          d="flex"
          flexDir="column"
          w="100%"
          alignContent={'center'}
          borderBottom={'1px solid #ECEFF3'}
          pb="14px"
        >
          <Image
            mt="16px"
            alignSelf="center"
            src={translate.getText('selled_delivery_rappi_img')}
            w="60%"
            objectFit="cover"
          />
        </Box>
        <HeaderWithBackButton
          headerTitle={translate.getText('order_summary_title')}
          fontSize={'xl'}
          to={bag}
        />

        <Subtitle fontSize="1.7rem" fontWeight="400" color="gray.300" mb={3}>
          {translate.getText('delivery_to')}
        </Subtitle>
        <Button
          cursor="default"
          // onClick={handleOnClickChangeAddress}
          variant="ghost"
          px={0}
          py={3}
          flex="1"
          justifyContent="flex-start"
          w="100%"
        >
          <Box d="flex" alignItems="center" w="100%">
            <Box d="flex" alignItems="center" w="90%">
              <Icon name="menu-pin" size="2.4rem" mr={2} />
              <Box d="flex">
                <CustomText fontWeight="700" color="black">
                  {formatAddress(client?.address)}
                </CustomText>
              </Box>
            </Box>
            {/* <Box d="flex" ml="auto">
              <Icon name="arrow-back" size="2.4rem" transform="rotate(180deg)" />
            </Box> */}
          </Box>
        </Button>

        <Divider mt={6} />

        <Subtitle mt={8} fontSize="1.7rem" color="black">
          {translate.getText('your_order')}
        </Subtitle>

        <OrderItems screen="resume" handleOnEditingItem={() => {}} />

        <OrderTotals
          screen="resume"
          totalAmount={priceWithoutDiscount}
          deliveryFee={deliveryFee}
          serviceFee={serviceFee}
        />

        <BottomBar isOverlayVisible={isOpenPaymentData} onClose={onClosePaymentData}>
          {isOpenPaymentData && (
            <ConfirmPaymentDataForm
              isVisible={isOpenPaymentData}
              onClickBack={onClosePaymentData}
            />
          )}
          {!isOpenPaymentData && (
            <>
              {rappiTurbo?.client?.pro && deliveryFee > 0 && (
                <ProBadge
                  discountTitle={translate.getText('pro_discount')}
                  discountPrice={deliveryFee}
                  language={client?.language}
                />
              )}
              <Box d="flex" w="100%" py={4}>
                <Button
                  px="3rem"
                  borderRadius="50px"
                  bg="green.300"
                  type="button"
                  w="100%"
                  py={8}
                  onClick={handleOnClickConfirmResume}
                >
                  <Text color="white" fontSize="lg">
                    {translate.getText('continue_button')}
                  </Text>
                </Button>
              </Box>
            </>
          )}
        </BottomBar>
      </Box>
    </>
  );
};

export default OrderReview;
