import { Box, Skeleton, Stack } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { invalidSession } from '~/routes/routeMap';
import { RootState } from '~/store';
import BagActions from '~/store/ducks/bag';
import RappiTurboActions from '~/store/ducks/rappiTurbo';
import RealTimeDimensions from '~/utils/realTimeDimensions';

import { CustomCategoryBox, HorizontalScroll } from './styles';
const { getAuthData, clearRappiTurboData } = RappiTurboActions;
const { cleanBag } = BagActions;

interface URLParamTypes {
  session_id: string;
}

const Loading: React.FC = () => {
  const dispatch = useDispatch();
  const { session_id: sessionIdURL } = useParams<URLParamTypes>();
  const globalState = useSelector((state: RootState) => state);
  const rappiTurbo = globalState?.rappiTurbo;
  const [windowScrollY, setWindowScrollY] = useState(0);
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;
  const horizontalBoxUseRef = useRef();

  useEffect(() => {
    if (!rappiTurbo?.sessionId || !sessionIdURL || rappiTurbo?.sessionId !== sessionIdURL) {
      dispatch(clearRappiTurboData());
      dispatch(cleanBag());
    }

    const sessionId = sessionIdURL || rappiTurbo?.sessionId;
    if (!sessionId) {
      dispatch(push(invalidSession));
    } else if (sessionId?.trim() !== '') {
      dispatch(getAuthData(sessionId));
    }
  }, [sessionIdURL]);

  return (
    <>
      {/* Page title */}
      {/* @ts-ignore */}
      <Helmet>
        <title>{'Rappi Turbo'}</title>
      </Helmet>

      {/* Cover */}
      <Box d="flex" alignItems="flex-start" justifyContent="flex-start" pb={150}>
        <Skeleton position="absolute" top="0" left="0" w="100%" h="16rem" />
      </Box>

      {/* Page Content */}
      <Box as="main" flexDir="column" w="100%" position="relative" pb={70} zIndex={1}>
        {/* Header */}
        <Stack as="header" spacing={2} mt="1rem" py={5} px={5} pb={6} position="relative">
          {/* Advertiser Name and Logo */}
          <Box d="flex" alignItems="center">
            <Skeleton borderRadius="50%" w={60} h={60} />
            <Skeleton ml={5} w={240} h="40px" />
            <Skeleton ml="auto" w="30px" h="30px" />
          </Box>
          <Skeleton alignSelf="center" mt={5} mb={5} w={300} h="40px" />
          {/* Delivery Info */}
          <Skeleton w={280} h={70} textAlign="center" mt={5} mb={5} d="flex" alignSelf="center" />
          <Skeleton mt={5} alignSelf={'center'} w={240} h="40px" />
        </Stack>
        {/* Categories Tabs */}
        <CustomCategoryBox stick={windowScrollY > 300} isMobile={isMobile} mb={-5}>
          <HorizontalScroll
            isInline
            borderBottom="1px solid"
            borderColor="gray.50"
            position="relative"
            top={0}
            ref={horizontalBoxUseRef}
          >
            {Array.from(Array(3).keys()).map((item) => (
              <Skeleton key={item} h="40px" w="18rem" m={4} />
            ))}
          </HorizontalScroll>
        </CustomCategoryBox>
        {/* Categories */}
        <Box my={12} px="2rem">
          <Skeleton w="100%" h="4rem" mb={6}></Skeleton>
          <Skeleton w="100%" h="10rem" mb={3}></Skeleton>
          <Skeleton w="100%" h="10rem" mb={3}></Skeleton>
        </Box>
      </Box>
    </>
  );
};

export default Loading;
