import { isValid } from 'cpf';
import * as yup from 'yup';

export const cpfRegex = /\d{3}.\d{3}.\d{3}-\d{2}/;

export default function registerUserSchema(shouldValidateCPF: boolean = false, translate: any) {
  return yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .max(40, translate.getText('validation_user_first_name_invalid'))
      .required(translate.getText('validation_user_first_name_empty')),
    surname: yup
      .string()
      .trim()
      .max(60, translate.getText('validation_user_surname_name_invalid'))
      .required(translate.getText('validation_user_surname_name_empty')),
    email: yup
      .string()
      .trim()
      .email(translate.getText('validation_user_email_invalid'))
      .required(translate.getText('validation_user_email_empty')),
    identification: shouldValidateCPF
      ? yup
          .string()
          .trim()
          .required(translate.getText('validation_user_identification_empty'))
          .matches(cpfRegex, translate.getText('validation_user_identification_invalid'))
          .test({
            test: (value: string) => isValid(value),
            message: translate.getText('validation_user_identification_invalid'),
            name: 'is-cpf',
          })
      : yup.string().trim().required(translate.getText('validation_user_identification_empty')),
  });
}
