import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { CheckoutProduct } from '~/models/CheckoutData';
import { formatProductToBag, recalcBagAmounts } from '~/utils/bag';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  SET_ASSEMBLED_BAG = 'SET_ASSEMBLED_BAG',
  ADD_TO_BAG = 'ADD_TO_BAG',
  EDDITING_TO_BAG = 'EDDITING_TO_BAG',
  REMOVING_FROM_THE_BAG = 'REMOVING_FROM_THE_BAG',
  CLEAN_BAG = 'CLEAN_BAG',
  SET_SUMMARY_DATA = 'SET_SUMMARY_DATA',
  SET_CHECKOUT_PRODUCTS = 'SET_CHECKOUT_PRODUCTS',
}

export interface OnSetAssembledBag extends Action<TypesNames.SET_ASSEMBLED_BAG> {
  assembledItems: any[];
}

export interface OnAddToBag extends Action<TypesNames.ADD_TO_BAG> {
  product: any;
}

export interface OnEdditingToBag extends Action<TypesNames.EDDITING_TO_BAG> {
  product: any;
}

export interface OnRemovingFromTheBag extends Action<TypesNames.REMOVING_FROM_THE_BAG> {
  productBagId: number;
}

export interface OnCleanBag extends Action<TypesNames.CLEAN_BAG> {}

export interface OnSetSummaryData extends Action<TypesNames.SET_SUMMARY_DATA> {
  deliveryFee: number;
  serviceFee: number;
}

export interface OnSetCheckoutProducts extends Action<TypesNames.SET_CHECKOUT_PRODUCTS> {
  checkoutProducts: CheckoutProduct[];
}

/* ============== ACTION CREATORS AND TYPES ============== */

export const { Types, Creators } = createActions<
  {
    [TypesNames.SET_ASSEMBLED_BAG]: string;
    [TypesNames.ADD_TO_BAG]: string;
    [TypesNames.EDDITING_TO_BAG]: string;
    [TypesNames.REMOVING_FROM_THE_BAG]: string;
    [TypesNames.CLEAN_BAG]: string;
    [TypesNames.SET_SUMMARY_DATA]: string;
    [TypesNames.SET_CHECKOUT_PRODUCTS]: string;
  },
  {
    setAssembledBag: (assembledItems: any[]) => OnSetAssembledBag;
    addToBag: (product: any) => OnAddToBag;
    edditingToBag: (product: any) => OnEdditingToBag;
    removingFromTheBag: (productBagId: number) => OnRemovingFromTheBag;
    cleanBag: () => OnCleanBag;
    setSummaryData: (deliveryFee: number, serviceFee: number) => OnSetSummaryData;
    setCheckoutProducts: (checkoutProducts: CheckoutProduct[]) => OnSetCheckoutProducts;
  }
>({
  setAssembledBag: ['assembledItems'],
  addToBag: ['product'],
  edditingToBag: ['product'],
  removingFromTheBag: ['productBagId'],
  cleanBag: null,
  setSummaryData: ['deliveryFee', 'serviceFee'],
  setCheckoutProducts: ['checkoutProducts'],
});

export const BagTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */

export interface BagStateType {
  sizeBag?: number;
  itemsInBag?: any[];
  priceWithoutDiscount?: number;
  deliveryFee?: number;
  serviceFee?: number;
  checkoutProducts?: CheckoutProduct[];
}

export const INITIAL_STATE: BagStateType = {
  sizeBag: 0,
  itemsInBag: [],
  priceWithoutDiscount: null,
  deliveryFee: 0,
  serviceFee: 0,
  checkoutProducts: [],
};

/* ============== REDUCERS ============== */

export const setAssembledBagReducer = (
  state = INITIAL_STATE,
  { assembledItems }: OnSetAssembledBag,
) =>
  produce(state, (draft) => {
    draft.sizeBag = 0;
    draft.itemsInBag = [];
    draft.priceWithoutDiscount = null;
    draft.deliveryFee = 0;
    draft.serviceFee = 0;
    draft.checkoutProducts = [];

    assembledItems.forEach((assembledItem, idx) => {
      const productToBag = formatProductToBag({ bagId: idx, ...assembledItem });
      draft.itemsInBag.push(productToBag);
    });

    const calculedData = recalcBagAmounts(draft.itemsInBag);
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
  });

export const addToBagReducer = (state = INITIAL_STATE, { product }: OnAddToBag) =>
  produce(state, (draft) => {
    const productToBag = formatProductToBag(product);
    draft.itemsInBag.push(productToBag);

    const calculedData = recalcBagAmounts(draft.itemsInBag);
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
  });

export const edditingToBagReducer = (state = INITIAL_STATE, { product }: OnAddToBag) =>
  produce(state, (draft) => {
    const productToBag = formatProductToBag(product);
    const edditingItemIdx = draft.itemsInBag.findIndex((item) => item.bagId === productToBag.bagId);
    const edditedItemsInBag: any[] = [...draft.itemsInBag];
    edditedItemsInBag[edditingItemIdx] = productToBag;
    draft.itemsInBag = edditedItemsInBag;

    const calculedData = recalcBagAmounts(draft.itemsInBag);
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
  });

export const removingFromTheBagReducer = (
  state = INITIAL_STATE,
  { productBagId }: OnRemovingFromTheBag,
) =>
  produce(state, (draft) => {
    const itemsWithoutEdditedItem: any = draft.itemsInBag.filter((item) => {
      return item.bagId !== productBagId;
    });
    draft.itemsInBag = itemsWithoutEdditedItem;

    const calculedData = recalcBagAmounts(draft.itemsInBag);
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
  });

export const cleanBagReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.sizeBag = 0;
    draft.itemsInBag = [];
    draft.priceWithoutDiscount = null;
    draft.deliveryFee = 0;
    draft.serviceFee = 0;
    draft.checkoutProducts = [];
  });

export const setSummaryDataReducer = (
  state = INITIAL_STATE,
  { deliveryFee, serviceFee }: OnSetSummaryData,
) =>
  produce(state, (draft) => {
    draft.deliveryFee = deliveryFee / 100;
    draft.serviceFee = serviceFee / 100;
  });

export const setCheckoutProductsReducer = (
  state = INITIAL_STATE,
  { checkoutProducts }: OnSetCheckoutProducts,
) =>
  produce(state, (draft) => {
    draft.checkoutProducts = checkoutProducts;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.SET_ASSEMBLED_BAG]: setAssembledBagReducer,
  [Types.ADD_TO_BAG]: addToBagReducer,
  [Types.EDDITING_TO_BAG]: edditingToBagReducer,
  [Types.REMOVING_FROM_THE_BAG]: removingFromTheBagReducer,
  [Types.CLEAN_BAG]: cleanBagReducer,
  [Types.SET_SUMMARY_DATA]: setSummaryDataReducer,
  [Types.SET_CHECKOUT_PRODUCTS]: setCheckoutProductsReducer,
});
