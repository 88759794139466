// @ts-nocheck
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { apiRappiTurbo } from '~/services/api';

import { Creators as TrackingCreators, TrackingTypes } from '../ducks/tracking';

const { setGetTrackingDataLoading, setTrackingData } = TrackingCreators;

export function* getTrackingData({ sessionId }) {
  try {
    const response: AxiosResponse = yield call(
      apiRappiTurbo.get,
      `/rappi/${sessionId}/order/tracking`,
    );
    const rappiTrackingResponse = response.data;

    yield put(setTrackingData(rappiTrackingResponse));
  } catch (err: any) {
    yield put(setGetTrackingDataLoading(false));
    yield put(setTrackingData(null));
  }
}

export default all([takeLatest(TrackingTypes.GET_TRACKING_DATA, getTrackingData)]);
